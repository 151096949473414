import React, { Component } from 'react';
import Swiper from 'swiper/js/swiper.min';
import 'swiper/css/swiper.min.css';
import './home.css';
import Header from "../handf/Header";
import Footer from "../handf/FooterHome";
//import Search from "./Search";
import {get, post,} from "../../ajax/tools";
import PopUp from "../round/PopUp";
import wx from "weixin-js-sdk";
// import axios from 'axios'
// import {API_URL} from "../../ajax/config";
// axios.defaults.baseURL=API_URL;



class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            BannerImageArr:[],
            HomeGameArr:[],
            HomeNewArr:[],
            HomeDownArr:[],
        };
    }
    componentDidMount=function() {
        sessionStorage.removeItem('ls');
        let compare = function (prop) {
            return function (obj1, obj2) {
                let val1 = obj1[prop];
                let val2 = obj2[prop];
                if (val1 < val2){
                    return 1;
                } else if (val1 >val2){
                    return -1;
                } else {
                    return 0;
                }
            }
        };
        get({url:"a_index/show_img"}).then(res => {
            if(res.errno === '200' && res.data.length !== 0){
                const BannerImageArr=[];
                for(let i=0;i<res.data.length;i++){
                    const PhotoType = res.data[i].type;
                    if(PhotoType === 1){
                        const BannerImageList = res.data[i];
                        BannerImageArr.push(BannerImageList);
                    }
                }
                this.setState({
                    BannerImageArr:BannerImageArr.sort(compare('rank')),
                });
            }else {
                this.setState({
                    BannerImageArr:[],
                });
            }
        });
        get({url:"match/get_match_index_info"}).then(res => {
            if(res.errno === '200'){
                let HomeGameBrr = [];
                for(let i=0; i<6; i++){
                    let HomeGameBrrList = res.data[i];
                    HomeGameBrr.push(HomeGameBrrList);
                }
                this.setState({
                    HomeGameArr:HomeGameBrr,
                });
            }else {
                this.setState({
                    HomeGameArr:[],
                });
            }
        });
        get({url:"index/news_show"}).then(res => {
            if(res.errno === '200'){
                let HomeNewBrr = [];
                let Num = res.data.length > 5 ? 5 : res.data.length;
                for(let i=0;i<Num;i++){
                    let HomeNewArrList = res.data[i];
                    HomeNewBrr.push(HomeNewArrList);
                }
                this.setState({
                    HomeNewArr:HomeNewBrr,
                });
            }else {
                this.setState({
                    HomeNewArr:[],
                });
            }
        });
        get({url:"news/about_download"}).then(res => {
            if(res.errno === '200'){
                let HomeExamBrr = [];
                let Num = res.data.length > 5 ? 5 : res.data.length;
                for(let i=0;i<Num;i++){
                    let HomeExamArrList = res.data[i];
                    HomeExamBrr.push(HomeExamArrList);
                }
                this.setState({
                    HomeDownArr:HomeExamBrr,
                });
            }else {
                this.setState({
                    HomeDownArr:[],
                });
            }
        });

        post({
            url:"/reuse/wx_parameter",
            data:{
                url_sign:window.location.href,
            },
        }).then(res => {
            if (res.errno === '200') {
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.data.appId, // 必填，公众号的唯一标识
                    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                    signature: res.data.signature,// 必填，签名
                    jsApiList: [
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'onMenuShareQQ',
                        'onMenuShareWeibo',
                    ] // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {
                    let shareConfig = {
                        title: '全民健身线上运动会',
                        desc: '2022中国模拟飞行全民打榜赛',
                        link: window.location.href,
                        imgUrl: 'http://oss.asfcyy.com/richTextImage/a8d7f2a2c78e11ec9dc800163e3521a2.jpg',
                        success: function () {
                            // alert('分享成功')
                        },
                        cancel: function () {
                            // alert('分享已取消');
                        }
                    };
                    wx.updateAppMessageShareData(shareConfig);
                    wx.updateTimelineShareData(shareConfig);
                    wx.onMenuShareTimeline(shareConfig);
                    // 分享给朋友
                    wx.onMenuShareAppMessage(shareConfig);
                    // 分享到QQ
                    wx.onMenuShareQQ(shareConfig);
                    // 分享到腾讯微博
                    wx.onMenuShareWeibo(shareConfig);
                    // 分享到QQ空间
                    wx.onMenuShareQZone(shareConfig);
                });
            }
        });
    };

    componentDidUpdate = () =>{
        //点击轮播的事件
        function BannerListClick(item,index,){
            console.log(item)
            if (item.jump_url !== ''){
                if (item.jump_url === '/approve_s'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            let User = res.data[0];
                            if(User.type === 0){
                                alert('请使用机构账户登录。');
                                // this.setState({
                                //     PopUp:'提示弹窗',
                                //     MessageTip:'请使用机构账户登录！',
                                // });
                            }else {
                                window.location.href = item.jump_url;
                                // const {history} = this.props;
                                // history.push(item.jump_url);
                            }
                        }else {
                            window.location.href = '/login';
                            // const {history} = this.props;
                            // history.push('/login');
                        }
                    });
                }else if(item.jump_url === '/game'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            let User = res.data[0];
                            if(User.type === 0){
                                get({url:'match/get_match_details/'+item.rank}).then((res) => {
                                    if(res.errno === '200'){
                                        if (res.data[0].enroll_type === 1){
                                            alert('本赛事不接受个人报名  请认真阅读本赛事通知及规则。');
                                            // this.setState({
                                            //     PopUp:'提示弹窗',
                                            //     MessageTip:'本赛事不接受个人报名  请认真阅读本赛事通知及规则。',
                                            // });
                                        }else {
                                            sessionStorage.setItem('gameId',item.rank);
                                            window.location.href = '/game';
                                            // const {history} = this.props;
                                            // history.push('/game');
                                        }
                                    }else {
                                        alert(res.errmsg);
                                        // this.setState({
                                        //     PopUp:'提示弹窗',
                                        //     MessageTip:res.errmsg,
                                        // });
                                    }
                                });
                            }else if (User.type === 1) {
                                get({url:'match/get_match_details/'+item.rank}).then((res) => {
                                    if(res.errno === '200'){
                                        if (res.data[0].enroll_type === 0){
                                            alert('本赛事不接受团体报名  请认真阅读本赛事通知及规则。');
                                            // this.setState({
                                            //     PopUp:'提示弹窗',
                                            //     MessageTip:'本赛事不接受团体报名  请认真阅读本赛事通知及规则。',
                                            // });
                                        }else {
                                            sessionStorage.setItem('gameId',item.rank);
                                            window.location.href = '/game';
                                            // const {history} = this.props;
                                            // history.push('/game');
                                        }
                                    }else {
                                        alert(res.errmsg);
                                        // this.setState({
                                        //     PopUp:'提示弹窗',
                                        //     MessageTip:res.errmsg,
                                        // });
                                    }
                                });
                            }
                        }else {
                            alert(res.errmsg);
                            window.location.href = '/login';
                            // const {history} = this.props;
                            // history.push('/login');
                        }
                    });
                }else if(item.jump_url === '/internetAsk'){
                    get({url:'user/basic_info'}).then((res) => {
                        if(res.errno === '200'){
                            let User = res.data[0];
                            if(User.type === 0){
                                post({
                                    url:'match/query_match_enroll_per ',
                                    data:{
                                        match_id:25,
                                    },
                                }).then((res) => {
                                    if(res.errno === '200'){
                                        let data = res.data[0];
                                        post({
                                            url:'match/net_match_write_show',
                                            data:{
                                                tei_id: data.id,
                                                type: 2,
                                            },
                                        }).then((res) => {
                                            if (res.errno === '200') {
                                                window.open('/internetAsk/'+data.id);
                                            }else{
                                                // this.setState({
                                                //     PopUp:'提示弹窗',
                                                //     MessageTip:res.errmsg,
                                                // });
                                            }
                                        });
                                    }
                                });
                            }else {
                                alert('请使用个人账户登录。');
                                // this.setState({
                                //     PopUp:'提示弹窗',
                                //     MessageTip:'请使用个人账户登录！',
                                // });
                            }
                        }else {
                            window.location.href = '/login';
                            // const {history} = this.props;
                            // history.push('/login');
                        }
                    });
                }else if(item.jump_url.indexOf ("http") !== -1){
                    window.open(item.jump_url);
                }else{
                    window.location.href = item.jump_url;
                    // const {history} = this.props;
                    // history.push(item.jump_url);
                }
            }
        }

        //Swiper的Js
        this.swiper = new Swiper('.swiper-container', {
            preloadImages:true,//强制加载所有图片后才初始化。
            loop:true,//循环
            slidesPerView: 3,//展示几个
            autoplay:{
                delay:4000,// 自动切换间隔时间
                disableOnInteraction: false,//操作Swiper是否停止autoplay
            },
            centeredSlides: true,//设置了Active Slide居中
            centeredSlidesBounds: true,//第一个和最后一个Slide 始终贴合边缘
            speed: 1000,//切换速度，即slider自动滑动开始到结束的时间
            width : 1100, //你的slide宽度
            height: 266,//你的slide高度
            pagination : {
                el:'.swiper-pagination',//需要分页器
                clickable :true,//点击分页器的指示点分页器会控制Swiper切换
                type : 'bullets',//分页器样式自定义
                bulletClass : 'swiper-pagination-bullet',//需设置.my-bullet样式
                bulletActiveClass: 'swiper-news-bullet-active',//定义pagination 分页器内当前活动块的指示小点的类名
            },
            preventClicks : false,
            preventClicksPropagation: false,
            autoplayDisableOnInteraction : false,
        });
        let slides = document.querySelectorAll('.swiper-slide');
        slides.forEach(function(slide) {
            slide.addEventListener('click', function () {
                let sb = slide.getAttribute('jump');
                let sbb = slide.getAttribute('rank');
                let item = {jump_url : sb,rank : sbb,}
                let index = slide.getAttribute('index');
                let ls = sessionStorage.getItem('ls');
                if (ls){
                    if (ls !== sb){
                        // console.log(1)
                        sessionStorage.setItem('ls',sb);
                        BannerListClick(item,index)
                    }else {
                        setTimeout(() => {
                                // console.log(2)
                                sessionStorage.removeItem('ls');
                            },
                            250
                        );
                    }
                }else {
                    // console.log(3)
                    sessionStorage.setItem('ls',sb);
                    BannerListClick(item,index)
                }
            },);
        });
    };



    UNSAFE_componentWillUpdate = () => {
        if (this.swiper) {
            this.swiper.destroy();
        }
    };
    //主页的信息标题盒子更多按钮
    HomeGameTitlesClick(){
        const {history} = this.props;
        history.push('/game');
    };
    HomeNewTitlesClick(){
        const {history} = this.props;
        history.push('/news');
    };
    HomeTestTitlesClick(){
        const {history} = this.props;
        history.push('/download');
    };



    //关于首页赛事活动的点击事件
    HomeGameClick=(item,index) =>{
        let JumpYearInfo = {
            id:item.id,
            match_name:item.match_name,
            spec:item.ctime.substring(0,4),
            type:'review',
        };
        sessionStorage.setItem('Jump',JSON.stringify(JumpYearInfo));
        const {history} = this.props;
        history.push('/game');
        // get({url:"index/check_match_end/"+item.id}).then(res => {
        //     if(res.errno === '200'){
        //         sessionStorage.setItem('JumpYearId',item.id);
        //         const {history} = this.props;
        //         history.push('/game');
        //     }else {
        //         get({url:'user/basic_info'}).then((res) => {
        //             if(res.errno === '200'){
        //                 let User = res.data[0];
        //                 if(User.type === 0){
        //                     if (item.enroll_type === 1){
        //                         this.setState({
        //                             PopUp:'提示弹窗',
        //                             MessageTip:'本赛事不接受个人报名  请认真阅读本赛事通知及规则。',
        //                         });
        //                     }else {
        //                         sessionStorage.setItem('gameId',item.id);
        //                         const {history} = this.props;
        //                         history.push('/game');
        //                     }
        //                 }else if (User.type === 1) {
        //                     if (item.enroll_type === 0){
        //                         this.setState({
        //                             PopUp:'提示弹窗',
        //                             MessageTip:'本赛事不接受团体报名  请认真阅读本赛事通知及规则。',
        //                         });
        //                     }else {
        //                         sessionStorage.setItem('gameId',item.id);
        //                         const {history} = this.props;
        //                         history.push('/game');
        //                     }
        //                 }
        //             }else {
        //                 sessionStorage.setItem('routesAddress','/game');
        //                 const {history} = this.props;
        //                 history.push('/login');
        //             }
        //         });
        //     }
        // });
    };

    //关于首页新闻公告的点击事件
    HomeNewClick=(item,index) =>{
        if (item.is_pdf && item.is_pdf === 1){
            window.open(item.content);
        }else {
            window.open('/newsInfo?id='+item.id);
        }
    };

    //关于首页的等级测试的点击事件
    HomeTestClick=(item,index) =>{
        let a = document.createElement('a');
        a.setAttribute('href',item.real_url);
        a.setAttribute('download',item.name);
        a.setAttribute('target','_blank');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.click();
        document.body.removeChild(a);
        //let NewsInfoId = index;
        //window.open(item,'download');
    };









    HomeDSB(){
        window.open('http://yg.asfcyy.com');
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        //console.log(this.state.BannerImageArr)
        return (
                <div className={'MainBox'} >
                    <Header/>
                    <div className={'Home'}>
                        <div className={'SloGonBgImage'}>
                            <div className={'SloGonBox'}> </div>
                            {/*<Search />*/}
                            <div id={'banner'}>
                                <div className={'swiper-container'}>
                                    <div className={'swiper-wrapper swiper-no-swiping'}>
                                        {
                                            this.state.BannerImageArr.map((item,index) => (
                                                <div className={'swiper-slide'} key={index} jump={item.jump_url} index={index} rank={item.rank}>
                                                    <img className={'BannerImage'} src={item.img_url} alt={''}/>
                                                    {/*
                                                        <div className={'swiper-slide-active-title'}>
                                                            <div className={'small-title'}>小标题</div>
                                                            <div className={'small-word'}>2019年全国模拟飞行锦标赛杭州赛区于昨日完成了半决赛的赛事</div>
                                                            <div className={'small-time'}>2019＼05＼20</div>
                                                        </div>
                                                    */}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className={'swiper-pagination-position'}><div className={'swiper-pagination'}> </div></div>
                                </div>
                                {
                                    this.state.BannerImageArr.length !==0 &&
                                    <div>
                                        <div className={'swiper-slide-prev-gray'}> </div>
                                        <div className={'swiper-slide-next-gray'}> </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {/*专题跳转字体*/}
                        <div className={'HomeDSB'}>
                            <span
                                style={{textDecoration:'underline',cursor:'pointer',}}
                                onClick={this.HomeDSB.bind(this)}
                            >全国青少年模拟飞行锦标赛专题</span>
                        </div>

                        <div className={'HomeGameBg'}>
                            <div className={'HomeGameTitImg'}> </div>
                            <div className={'HomeGameConBox'}>
                                <div className={'HomeGameLogo'}> </div>
                                <div className={'HomeTitlesBox HomeGamePosition'} style={{top:200,}}>
                                    <div className={'HomeTitleInfoBox'}>
                                        <div className={'HomeTitleInfo GameMargin'} style={{width:700,}} title={'2023 - 2024学年全国青少年模拟飞行锦标赛'}  onClick={this.HomeDSB.bind(this)}>
                                            <span className={'HomeTitleCon'} style={{textAlign:'left',fontSize:16,width:460,}}>2023 - 2024学年全国青少年模拟飞行锦标赛</span>
                                            <span className={'HomeTitleTime'} style={{width:80,}}>2024-03-12</span>
                                            <span className={'HomeTitleEllipsis'} style={{float:'right',width:130,}}>·········</span>
                                        </div>

                                        {/*<div className={'HomeTitleInfo GameMargin'} style={{width:700,}} title={'2024年“爱运动，爱飞行”模拟飞行进社区活动暨模拟飞行全民健身线上打榜赛'}  onClick={this.HomeGameClick.bind(this)}>*/}
                                        {/*    <span className={'HomeTitleCon'} style={{textAlign:'left',fontSize:16,width:560,}}>2024年“爱运动，爱飞行”模拟飞行进社区活动暨模拟飞行全民健身线上打榜赛</span>*/}
                                        {/*    <span className={'HomeTitleTime'} style={{width:80,}}>2024-04-25</span>*/}
                                        {/*    <span className={'HomeTitleEllipsis'} style={{float:'right',width:55,}}>···</span>*/}
                                        {/*</div>*/}

                                        {
                                            this.state.HomeGameArr.map((item,index) => (
                                                <div key={index}>
                                                    {
                                                        item.id === 41&&
                                                        <div className={'HomeTitleInfo GameMargin'} style={{width:700,}}  title={item.match_name}  onClick={() => this.HomeGameClick(item,index)}>
                                                            <span className={'HomeTitleCon'} style={{textAlign:'left',fontSize:16,width:560,}}>{item.match_name}</span>
                                                            <span className={'HomeTitleTime'} style={{width:80,}}>{item.match_time.substring(0,10)}</span>
                                                            <span className={'HomeTitleEllipsis'} style={{float:'right',width:55,}}>···</span>
                                                        </div>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {/*<button className={'HomeTitlesBtn'} onClick={this.HomeGameTitlesClick.bind(this)}>更多</button>*/}
                                </div>
                            </div>
                        </div>
                        <div className={'HomeNewBg'}>
                            <div className={'HomeNewTitImg'}> </div>
                            <div className={'HomeNewConBox'}>
                                <div className={'HomeNewLogo'}> </div>
                                <div className={'HomeNewDecorateImgLeft'}> </div>
                                <div className={'HomeNewDecorateImgRight'}> </div>
                                <div className={'HomeTitlesBox HomeNewPosition'}>
                                    <div className={'HomeTitleInfoBox'}>
                                        {
                                            this.state.HomeNewArr.map((item,index) => (
                                                <div className={'HomeTitleInfo NewMargin'} key={index} onClick={() => this.HomeNewClick(item,index)}>
                                                    <span className={'HomeTitleCon'} style={{textAlign:'left',width:430,}}>{item.title}</span>
                                                    <span className={'HomeTitleEllipsis'} style={{width:120,}}>·······</span>
                                                    <span className={'HomeTitleTime'}>{item.sort_time}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <button className={'HomeTitlesBtn'} onClick={this.HomeNewTitlesClick.bind(this)}>更多</button>
                                </div>
                            </div>
                        </div>
                        <div className={'HomeTestBg'}>
                            <div className={'HomeTestTitImg'}> </div>
                            <div className={'HomeTestConBox'}>
                                <div className={'HomeTestLogo'}> </div>
                                <div className={'HomeTestDecorateImg'}> </div>
                                <div className={'HomeTitlesBox HomeTestPosition'} style={{width:614,}}>
                                    <div className={'HomeTitleInfoBox'} style={{width:614,}}>
                                        {
                                            this.state.HomeDownArr.map((item,index) => (
                                                <div className={'HomeTitleInfo TestMargin'} style={{width:594,}} title={item.name.substring(0,item.name.lastIndexOf('.'))+' - 下载'} key={index}  onClick={() => this.HomeTestClick(item,index)}>
                                                    <span className={'HomeTitleCon'} style={{width:368,textAlign:'left',}}>
                                                        {item.name.substring(0,item.name.lastIndexOf('.'))} - 下载
                                                    </span>
                                                    <span className={'HomeTitleEllipsis'} style={{width:120,}}>·······</span>
                                                    <span className={'HomeTitleTime'} style={{width:106,}}>{item.ctime}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <button className={'HomeTitlesBtn'} style={{marginRight:12,}} onClick={this.HomeTestTitlesClick.bind(this)}>更多</button>
                                </div>
                            </div>
                        </div>
                        <div className={'PositionBottom'}> </div>
                    </div>
                    <Footer/>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default Home;
