import React, { Component } from 'react';
import './exam.css';
import BoundCoach from "./BoundCoach";
//import ExamStudentArr from "./ExamStudent";
//import ExamTeacherArr from "./ExamTeacher";
import PopUp from "../round/PopUp";
import {get, post,} from "../../ajax/tools";



class ExamAddPeople extends Component {

    constructor(props){
        super(props);
        this.state = {
            ApplyType:'',
            IdType:'',
            ExamRoomNameID:'',//考场id
            ExamRoomTimeID:'',//考场时间id
            UserId:'',//用户id
            IdNum:'',//用户id号
            realName:'',//用户真实姓名
            UserPhoto:'',//用户照片
            ExamAddPeopleRadioArr:[],//考阶的科目等阶数组定义变量
            ExamAddPeopleRadioIndex:'',//用于项目单选的定义变量
            ExamAddPeopleRadioId:'',//用于项目单选的Id
            ExamAddPeopleRadioPrice:'',//用于项目单选的费用
            ExamAddPeopleRadioName:'',//用于项目单选的项目


            VoucherNumber: 0,//代金券起始点
            VoucherInfo: {id:'',price: 0},//代金券的信息数据
            VoucherArray:[],//代金券的信息数组

            BoundCoachId:'',//绑定教练员的ID
            BoundCoachIdNum:'',//绑定教练员的ID号
        };
    }
    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        this.setState({
            ApplyType:history.ApplyType,
            IdType:history.IdType,
            IdNum:history.IdNum,
            ExamRoomNameID:history.ExamRoomNameID,
            ExamRoomTimeID:history.ExamRoomTimeID,
        });
    };
    componentDidMount=function() {
        const history = this.props;
        get({url:'reuse/get_personal_info/'+history.IdNum}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    IdNum:res.data[0].yy_num,
                    realName:res.data[0].real_name,
                    UserPhoto:res.data[0].image,
                    UserId:res.data[0].id,
                });
            }else {
                this.props.ExamAddPeopleBack('Cancel');
            }
        });

        if(history.IdType === '教员'){
            get({url:'exam/get_exam_room_kind/'+history.ExamRoomNameID+'/1'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamAddPeopleRadioArr:res.data,
                    });
                }else {
                    this.setState({
                        ExamAddPeopleRadioArr:[],
                    });
                }
            });
        }else if(history.IdType === '学员'){
            get({url:'exam/get_exam_room_kind/'+history.ExamRoomNameID+'/0'}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        ExamAddPeopleRadioArr:res.data,
                    });
                }else {
                    this.setState({
                        ExamAddPeopleRadioArr:[],
                    });
                }
            });
        }
        if(history.ExamItemID){
            this.setState({
                ExamAddPeopleRadioId:history.ExamItemID,
            });
        }
        if(history.coachIdNum && history.coachIdNum !== ''){
            this.setState({
                BoundCoachIdNum:history.coachIdNum,
            });
        }
        this.setState({
            ApplyType:history.ApplyType,
            IdType:history.IdType,
            IdNum:history.IdNum,
            ExamRoomNameID:history.ExamRoomNameID,
            ExamRoomTimeID:history.ExamRoomTimeID,
        });
    };



    //选择项目
    ExamAddPeopleRadio=(item,index) => {
        this.setState({
            ExamAddPeopleRadioIndex:index,
            ExamAddPeopleRadioId:item.id,
            ExamAddPeopleRadioPrice:item.price,
            ExamAddPeopleRadioName:item.name,
        });
        post({
            url:'exam/cash_coupon',
            data:{
                id: this.state.UserId,
                kind_id: item.id,
            },
        }).then((res) => {
            if(res.errno === '200'){
                let VoucherNumber = this.state.VoucherNumber;
                let VoucherArray = res.data;
                if(VoucherArray.length !== 0){
                    this.setState({
                        VoucherArray:VoucherArray,
                        VoucherInfo:VoucherArray[VoucherNumber],
                    });
                }else {
                    this.setState({
                        VoucherArray:[],
                    });
                }
            }else {
                this.setState({
                    VoucherArray:[],
                });
            }
        });
    };


    //点击代金券左侧切换
    ExamVoucherLeftBtn(){
        let VoucherArray = this.state.VoucherArray;
        let VoucherNumber = this.state.VoucherNumber;
        let NewVoucherNumber = VoucherNumber-1;
        if(NewVoucherNumber>=0){
            this.setState({
                VoucherNumber:NewVoucherNumber,
                VoucherInfo:VoucherArray[NewVoucherNumber],
            });
        }
    };
    //点击代金券右侧切换
    ExamVoucherRightBtn(){
        let VoucherArray = this.state.VoucherArray;
        let VoucherTotal = this.state.VoucherArray.length;
        let VoucherNumber = this.state.VoucherNumber;
        let NewVoucherNumber = VoucherNumber+1;
        if(NewVoucherNumber<VoucherTotal){
            this.setState({
                VoucherNumber:NewVoucherNumber,
                VoucherInfo:VoucherArray[NewVoucherNumber],
            });
        }
    };


    //取消添加申请按钮
    ExamAddPeopleNotBack=()=>{
        this.setState({
            PopUp:'取消添加申请弹窗',
            MessageTip:'是否取消添加该信息？',
        });
    };
    //确认添加申请按钮
    ExamAddPeopleAddBack=()=>{
        this.setState({
            PopUp:'确认添加申请弹窗',
            MessageTip:'是否确认添加该信息？',
        });
    };
    //取消添加申请弹窗点击的逻辑处理
    ChildBackAddCancel=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.props.ExamAddPeopleBack({BackType:'Cancel',});
            this.setState({
                PopUp:'',
            });
        }
    };
    //确认添加申请弹窗点击的逻辑处理
    ChildBackAddOk=(event)=>{
        let IdType = this.state.IdType;
        let UserId = this.state.UserId;
        let ExamAddPeopleRadioPrice = this.state.ExamAddPeopleRadioPrice;
        let ExamAddPeopleRadioId = this.state.ExamAddPeopleRadioId;
        let ExamAddPeopleRadioName = this.state.ExamAddPeopleRadioName;
        let ExamRoomTimeID = this.state.ExamRoomTimeID;
        let ExamRoomNameID = this.state.ExamRoomNameID;
        let BoundCoachId = this.state.BoundCoachId;
        let VoucherPrice = this.state.VoucherInfo.price;
        let VoucherId = this.state.VoucherInfo.id;
        let realName = this.state.realName;
        let IdNum = this.state.IdNum;
        let ApplyType = this.state.ApplyType;
        let BoundCoachIdNum = this.state.BoundCoachIdNum;
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(ExamAddPeopleRadioId !== ''){
                if(IdType === '教员'){
                    if(ApplyType === '团体测试添加' || ApplyType === '团体测试编辑'){
                        post({
                            url:'exam/check_stu_order',
                            data:{
                                sub_id: UserId,
                                exam_kind_id: ExamAddPeopleRadioId,
                            },
                        }).then((res) => {
                            if(res.errno === '200'){
                                const BackInfo = {
                                    BackType:'Ok',
                                    ApplyType:ApplyType,
                                    UserIdNum:IdNum,
                                    UserName:realName,
                                    ExamRoomNameID:ExamRoomNameID,
                                    BoundCoachIdNum:BoundCoachIdNum,
                                    type: IdType === '教员' ? '1' : (IdType === '学员' ? '0' : ''),
                                    sub_id: UserId,
                                    price: ExamAddPeopleRadioPrice,
                                    exam_kind_id: ExamAddPeopleRadioId,
                                    kind_name: ExamAddPeopleRadioName,
                                    exam_time_id: ExamRoomTimeID,
                                    coach_id: BoundCoachId,
                                    cash_price: VoucherPrice,
                                    cash_id: VoucherId,
                                };
                                this.props.ExamAddPeopleBack(BackInfo);
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'此人员已申请过该项目的测试！',
                                });
                            }
                        });
                    }else if(ApplyType === '个人测试添加' || ApplyType === '个人测试编辑'){
                        post({
                            url:'exam/check_stu_order',
                            data:{
                                sub_id: UserId,
                                exam_kind_id: ExamAddPeopleRadioId,
                            },
                        }).then((res) => {
                            if(res.errno === '200'){
                                const BackInfo = {
                                    BackType:'Ok',
                                    ApplyType:ApplyType,
                                    UserIdNum:IdNum,
                                    UserName:realName,
                                    ExamRoomNameID:ExamRoomNameID,
                                    BoundCoachIdNum:BoundCoachIdNum,
                                    type: IdType === '教员' ? '1' : (IdType === '学员' ? '0' : ''),
                                    sub_id: UserId,
                                    price: ExamAddPeopleRadioPrice,
                                    exam_kind_id: ExamAddPeopleRadioId,
                                    kind_name: ExamAddPeopleRadioName,
                                    exam_time_id: ExamRoomTimeID,
                                    coach_id: BoundCoachId,
                                    cash_price: VoucherPrice,
                                    cash_id: VoucherId,
                                };
                                this.props.ExamAddPeopleBack(BackInfo);
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'此人员已申请过该项目的测试！',
                                });
                            }
                        });
                    }
                }else if(IdType === '学员'){
                    if(BoundCoachIdNum !== ''){
                        post({
                            url:'exam/check_stu_order',
                            data:{
                                sub_id: UserId,
                                exam_kind_id: ExamAddPeopleRadioId,
                            },
                        }).then((res) => {
                            if(res.errno === '200'){
                                post({
                                    url:'exam/check_stu_level',
                                    data:{
                                        sub_id: UserId,
                                        exam_kind_id: ExamAddPeopleRadioId,
                                    },
                                }).then((res) => {
                                    if(res.errno === '200'){
                                        post({
                                            url:'exam/check_stu_next_level',
                                            data:{
                                                sub_id: UserId,
                                                exam_kind_id: ExamAddPeopleRadioId,
                                            },
                                        }).then((res) => {
                                            if(res.errno === '200'){
                                                const BackInfo = {
                                                    BackType:'Ok',
                                                    ApplyType:ApplyType,
                                                    UserIdNum:IdNum,
                                                    UserName:realName,
                                                    ExamRoomNameID:ExamRoomNameID,
                                                    BoundCoachIdNum:BoundCoachIdNum,
                                                    type: IdType === '教员' ? '1' : (IdType === '学员' ? '0' : ''),
                                                    sub_id: UserId,
                                                    price: ExamAddPeopleRadioPrice,
                                                    exam_kind_id: ExamAddPeopleRadioId,
                                                    kind_name: ExamAddPeopleRadioName,
                                                    exam_time_id: ExamRoomTimeID,
                                                    coach_id: BoundCoachId,
                                                    cash_price: VoucherPrice,
                                                    cash_id: VoucherId,
                                                };
                                                this.props.ExamAddPeopleBack(BackInfo);
                                            }else {
                                                this.setState({
                                                    PopUp:'提示弹窗',
                                                    MessageTip:'请先考取上一技术水平等级！',
                                                });
                                            }
                                        });
                                    }else {
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:'此人员已获取该技术水平等级,无法申请测试！',
                                        });
                                    }
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'此人员已申请过该项目的测试！',
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'请绑定教练员！',
                        });
                    }
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'请选择考核项目！',
                });
            }
        }
    };



    //绑定教练员的返回逻辑处理
    BoundCoachBack(event){
        this.setState({
            BoundCoachId:event.CoachId,
            BoundCoachIdNum:event.CoachIdNum,
        });
    };


    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };



    render() {
        return (
            <div className={'ExamMainBoxes'} style={{width:764,}}>
                <div className={'ExamAddPeopleBox'}>
                    <div className={'ExamAddPeopleConTit'}>添加测试人员信息</div>
                    <div className={'ExamAddPeopleConBox'}>
                        <div className={'ExamAddPeopleInfoBox'}>
                            <div className={'ExamAddPeopleInfo'}>
                                <div className={'ExamAddPeopleItemTit'}>测试人员</div>
                                <div className={'ExamAddPeInfoBox'}>
                                    <div className={'ExamAddPeInfoPhotoBox'}>
                                        <img alt={''}
                                             src={this.state.UserPhoto}
                                        />
                                    </div>
                                    <div className={'ExamAddPeInfoWordBox'}>
                                        <div className={'ExamAddPeInfoWord'}>
                                            <div className={'ExamAddPeInfoWordTit'}>姓名：</div>
                                            <div className={'ExamAddPeInfoWordCon'}>{this.state.realName}</div>
                                        </div>
                                        <div className={'ExamAddPeInfoWord'}>
                                            <div className={'ExamAddPeInfoWordTit'}>ID：</div>
                                            <div className={'ExamAddPeInfoWordCon'}>{this.state.IdNum}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.IdType === '学员' &&
                                <div>
                                    <BoundCoach
                                        BoundCoachBack={this.BoundCoachBack.bind(this)}
                                        IdNum={this.state.IdNum}
                                        BoundCoachIdNum={this.state.BoundCoachIdNum}
                                    />
                                    <div style={{float:'right',width:1,height:193,backgroundColor:'#3F3F3F',}}> </div>
                                </div>
                            }
                        </div>
                        <div className={'ExamAddPeopleItemTit'}>选择项目<span style={{color:'#868686',}}>(仅选择一项)</span></div>
                        <div className={'ExamAddPeopleSeparate'} style={{marginTop:6,}}> </div>
                        <div className={'ExamAddPeopleSelectSubjectBox'}>
                            <div className={'ExAddPeSeHeaderBox'}>
                                <div className={'ExAddPeSeHeaderList'} style={{width:400,}}>项目名称</div>
                                <div className={'ExAddPeSeHeaderList'} style={{width:342,}}>项目价格</div>
                            </div>
                            <div className={'ExAddPeSeContentBox'}>
                                {
                                    this.state.ExamAddPeopleRadioArr.map((item,index) =>
                                        <div className={'ExAddPeSeContentListBox'} key={index}>
                                            <div className={'ExAddPeSeHeaderList'} style={{width:400,}}>
                                                <div className={'ExamAddPeopleRadioIco'}
                                                     onClick={() => this.ExamAddPeopleRadio(item,index)}
                                                     style={{backgroundColor:this.state.ExamAddPeopleRadioId === item.id ? '#00B7EE' :''}}
                                                > </div>
                                                <div style={{minWidth:210,textAlign:'left',}}>{item.name}</div>
                                            </div>
                                            <div className={'ExAddPeSeHeaderList'} style={{width:342,}}>￥{item.price}.00</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className={'ExamVoucherTitleBox'}>
                            <div className={'VoucherTitleIco'}> </div>
                            <div className={'VoucherTitleWord'}>代金券</div>
                        </div>
                        {
                            this.state.VoucherArray.length === 0 &&
                            <div className={'ExamVoucherContentBox'}>
                                <div className={'ExamVoucherBox ExamVoucherNotWord'}>(T _ T)无可用代金券</div>
                            </div>
                        }
                        {
                            this.state.VoucherArray.length !== 0 &&
                            <div className={'ExamVoucherContentBox'}>
                                <div className={'ExamVoucherBox ExamVoucherBgImg'}>
                                    <div className={'VoucherInfoMoney'}
                                         style={{fontSize:this.state.VoucherInfo.price > 999 ? 60 : ''}}
                                    >{this.state.VoucherInfo.price}</div>
                                    <div className={'VoucherTitPosition'}>代金券</div>
                                    <div className={'Voucher￥Position'}>￥</div>
                                    <div className={'VoucherRuleBox'}>
                                        <div className={'VoucherRuleList clearfix'}>
                                            <div className={'VoucherRuleTitle'}>适用等级</div>
                                            <div className={'VoucherRuleContent'}>{this.state.ExamAddPeopleRadioName}</div>
                                        </div>
                                        <div className={'VoucherRuleList clearfix'}>
                                            <div className={'VoucherRuleTitle'}>有效期至</div>
                                            <div className={'VoucherRuleContent'}>{this.state.VoucherInfo.end_time}</div>
                                        </div>
                                    </div>
                                    <div className={'LeftBtn ExamVoucherBtn'} onClick={this.ExamVoucherLeftBtn.bind(this)}> </div>
                                    <div className={'RightBtn ExamVoucherBtn'} onClick={this.ExamVoucherRightBtn.bind(this)}> </div>
                                </div>
                            </div>
                        }
                        <div className={'ExamVoucherTitleBox'}>
                            <div className={'ExAddPeTotalMoney'}>
                                {this.state.ExamAddPeopleRadioPrice === '' ? '0.00' : (this.state.ExamAddPeopleRadioPrice-this.state.VoucherInfo.price+'.00')}
                            </div>
                            <div className={'ExAddPe￥Money'}>￥</div>
                            <div className={'ExAddPeTotalMoney ExAddPeTotalWord'}>合计：</div>
                            <div className={'ExAddPeTotalMoney'} style={{fontSize:15,marginRight:60,}}>
                                {this.state.VoucherInfo.price === 0  ? 0 : ('-'+this.state.VoucherInfo.price)}
                            </div>
                            <div className={'ExAddPeTotalMoney ExAddPeTotalWord'} style={{fontSize:14,}}>已减：</div>
                        </div>
                    </div>
                </div>
                <div className={'ExamAddPeopleBtnBox'} style={{marginBottom:38,}}>
                    <button className={'ExamAddPeopleBtn'} onClick={this.ExamAddPeopleAddBack.bind(this)}>添加</button>
                    <button className={'ExamAddPeopleBtn ExamCancelBtn'} onClick={this.ExamAddPeopleNotBack.bind(this)}>取消</button>
                </div>
                {
                    this.state.PopUp === '取消添加申请弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackAddCancel.bind(this)}
                        type={'失败'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '确认添加申请弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackAddOk.bind(this)}
                        type={'成功'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default ExamAddPeople;
