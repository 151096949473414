import React, { Component } from 'react';
import './member.css';
import '../round/round.css';
import PopUp from "../round/PopUp";
import {get} from "../../ajax/tools";

class GrDiploma extends Component {

    constructor(props){
        super(props);
        this.state = {
            GrDiplomaArr:[],
        };
    }
    componentDidMount=function() {
        get({url:'user/get_honor_diploma'}).then((res) => {
            if(res.errno === '200' && res.data.length !== 0){
                this.setState({
                    GrDiplomaArr:res.data,
                });
            }
        });
    };


    AllClick = () => {

    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    ImgClick=(item,index) =>{
        window.open(item.image);
    };


    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

    render() {
        return (
                <div className={'MemberPackageBox clearfix scrollBar'} style={{height:window.innerHeight-180,}}>
                    <div>
                        {
                            this.state.GrDiplomaArr.map((item,index) =>
                                <div className={'MemberPeBenefitsBox clearfix'} style={{height:300,border:0,cursor:'unset',}} key={index}>
                                    <div key={index} style={{width:400,height:240,border:'1px solid #aaaaaa',cursor:'pointer',}}>
                                        <img
                                            style={{display:'block',width:'100%',height:'100%',cursor:'pointer',}}
                                            src={item.image}
                                            alt={''}
                                            onClick={() => this.ImgClick(item,index)}
                                        />
                                    </div>
                                    <div className={'DiplomaName SLH'}>{item.name}</div>
                                    <div className={'DiplomaName SLH'}>
                                        <span className={'ClickDetails'} style={{cursor:'pointer',}} onClick={() => this.ImgClick(item,index)}>点击查看大图</span>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default GrDiploma;
