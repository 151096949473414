import React, { Component } from 'react';
import './member.css';
import './vip.css';
import {get, post} from "../../ajax/tools";
// import Header from "../handf/Header";
import PopUp from "../round/PopUp";
import Triangle from "../round/Triangle";
import {API_URL} from "../../ajax/config";
import Trick from "./Trick";
class Vip extends Component {
    constructor(props){
        super(props);
        this.state = {
            User:{},
            NavArr:[
                {id:'1',nav:'VIP服务',route:'/vip',},
                {id:'2',nav:'会员中心',route:'/member',},
            ],
            TheNav:'VIP服务',
            VipType: '',
            VipPayPage: '',
            VipPayStep: '',

            orderId:'',

            TheTopNav:'',
            TheTopId:'',
            TopNavArr:[
                {id:'1',nav:'VIP权益',route:'/vip_a',},
                {id:'2',nav:'VIP福利',route:'/vip_b',},
                {id:'3',nav:'VIP活动',route:'/vip_c',},
            ],

            QYArr:[],
            FLArr:[],
            HDArr:[],
            VipListArr:[],

            vipNum:'',
            vipPageStep:'index',

            vipQNumArr:[],
            vipFNumArr:[],



            backNum:'',

            vipOrderInfo:{},

            PageType:'',
            phone:'',
            name:'',
            address:'',

            VipOrderId:'',
            VipConPayStep:'',

            vipNumArr:[],

            FLId:'',

            DBKey:'',
		}

	}
    componentDidMount=function() {
        get({url:'user/basic_info'}).then((res) => {
            if(res.errno === '200'){
                let a = res.data[0];
                this.setState({
                    User:a,
                    VipType: a.is_vip,
                });
                if (sessionStorage.getItem('vip')){
                    sessionStorage.removeItem('vip');
                    if (a.is_vip === 0){
                        this.setState({
                            VipPayPage:'pay',
                        });
                        get({url:'/user/get_nopay_order/8'}).then(res=> {
                            if(res.errno === '200'){
                                this.setState({
                                    orderId:res.order_id,
                                });
                                this.PayWeChat = setInterval(() => {
                                    get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                                        if (res.state=== 1) {
                                            clearInterval(this.PayWeChat);
                                            this.setState({
                                                VipPayStep:'sb'
                                            });
                                            get({url:'user/basic_info'}).then((res) => {
                                                if(res.errno === '200'){
                                                    let a = res.data[0];
                                                    this.setState({
                                                        User:a,
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }, 2000);
                            }else {
                                post({
                                    url:'user/VIP_order',
                                    data:{
                                        order_remark:'VIP缴费',
                                    },
                                }).then((res) => {
                                    if (res.errno === '200') {
                                        this.setState({
                                            orderId:res.order_id,
                                        });
                                        this.PayWeChat = setInterval(() => {
                                            get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                                                if (res.state=== 1) {
                                                    clearInterval(this.PayWeChat);
                                                    this.setState({
                                                        VipPayStep:'sb'
                                                    });
                                                    get({url:'user/basic_info'}).then((res) => {
                                                        if(res.errno === '200'){
                                                            let a = res.data[0];
                                                            this.setState({
                                                                User:a,
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }, 2000);
                                    } else {
                                        this.setState({
                                            PopUp:'提示弹窗',
                                            MessageTip:res.errmsg+'！',
                                        });
                                    }
                                });
                            }
                        });
                    }else {
                        this.setState({
                            VipPayPage:'sb',
                        });
                        get({url:'user/show_VIP_goods'}).then((res) => {
                            if(res.errno === '200'){
                                let b = res.data;
                                this.setState({
                                    VipListArr:b,
                                });
                                if (b.length !== 0){
                                    let QYCrr = [];
                                    let FLCrr = [];
                                    let HDCrr = [];
                                    for(let i=0;i<b.length;i++){
                                        if(b[i].type === 1){
                                            QYCrr.push(b[i]);
                                        }else if(b[i].type === 2){
                                            FLCrr.push(b[i]);
                                        }else if(b[i].type === 3){
                                            HDCrr.push(b[i]);
                                        }
                                    }
                                    if (QYCrr.length > 3){
                                        let QYBrr = [];
                                        for(let i=0;i<QYCrr.length;i++){
                                            if(i < 3){
                                                QYBrr.push(QYCrr[i]);
                                            }
                                        }
                                        this.setState({
                                            QYArr:QYBrr,
                                        });
                                    }else {
                                        this.setState({
                                            QYArr:QYCrr,
                                        });
                                    }
                                    if (FLCrr.length > 3){
                                        let FLBrr = [];
                                        for(let i=0;i<FLCrr.length;i++){
                                            if(i < 3){
                                                FLBrr.push(FLCrr[i]);
                                            }
                                        }
                                        this.setState({
                                            FLArr:FLBrr,
                                        });
                                    }else {
                                        this.setState({
                                            FLArr:FLCrr,
                                        });
                                    }
                                    if (HDCrr.length > 3){
                                        let HDBrr = [];
                                        for(let i=0;i<HDCrr.length;i++){
                                            if(i < 3){
                                                HDBrr.push(HDCrr[i]);
                                            }
                                        }
                                        this.setState({
                                            HDArr:HDBrr,
                                        });
                                    }else {
                                        this.setState({
                                            HDArr:HDCrr,
                                        });
                                    }
                                }else {
                                    this.setState({
                                        QYArr:[],
                                        FLArr:[],
                                        HDArr:[],
                                    });
                                }
                            }else {
                                // this.setState({
                                //     PopUp:'提示弹窗',
                                //     MessageTip:res.errmsg,
                                // });
                            }
                        });
                    }
                }else {
                    this.setState({
                        VipPayPage:'sb',
                    });
                    get({url:'user/show_VIP_goods'}).then((res) => {
                        if(res.errno === '200'){
                            let b = res.data;
                            this.setState({
                                VipListArr:b,
                            });
                            if (b.length !== 0){
                                let QYCrr = [];
                                let FLCrr = [];
                                let HDCrr = [];
                                for(let i=0;i<b.length;i++){
                                    if(b[i].type === 1){
                                        QYCrr.push(b[i]);
                                    }else if(b[i].type === 2){
                                        FLCrr.push(b[i]);
                                    }else if(b[i].type === 3){
                                        HDCrr.push(b[i]);
                                    }
                                }
                                if (QYCrr.length > 3){
                                    let QYBrr = [];
                                    for(let i=0;i<QYCrr.length;i++){
                                        if(i < 3){
                                            QYBrr.push(QYCrr[i]);
                                        }
                                    }
                                    this.setState({
                                        QYArr:QYBrr,
                                    });
                                }else {
                                    this.setState({
                                        QYArr:QYCrr,
                                    });
                                }
                                if (FLCrr.length > 3){
                                    let FLBrr = [];
                                    for(let i=0;i<FLCrr.length;i++){
                                        if(i < 3){
                                            FLBrr.push(FLCrr[i]);
                                        }
                                    }
                                    this.setState({
                                        FLArr:FLBrr,
                                    });
                                }else {
                                    this.setState({
                                        FLArr:FLCrr,
                                    });
                                }
                                if (HDCrr.length > 3){
                                    let HDBrr = [];
                                    for(let i=0;i<HDCrr.length;i++){
                                        if(i < 3){
                                            HDBrr.push(HDCrr[i]);
                                        }
                                    }
                                    this.setState({
                                        HDArr:HDBrr,
                                    });
                                }else {
                                    this.setState({
                                        HDArr:HDCrr,
                                    });
                                }
                            }else {
                                this.setState({
                                    QYArr:[],
                                    FLArr:[],
                                    HDArr:[],
                                });
                            }
                        }else {
                            // this.setState({
                            //     PopUp:'提示弹窗',
                            //     MessageTip:res.errmsg,
                            // });
                        }
                    });
                }
                // 查看权益次数
                get({url:'user/query_vip_equity_num'}).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            vipNum:res.num,
                        });
                    }
                });
                // 查看已领取权益
                get({url:'user/query_receive_vip_equity'}).then((res) => {
                    if(res.errno === '200'){
                        let s = res.data;
                        this.setState({
                            vipNumArr:s,
                        });
                        let QYVrr = [];
                        let FLVrr = [];
                        for(let i=0;i<s.length;i++){
                            if(s[i].type === 1){
                                QYVrr.push(s[i]);
                            }else if(s[i].type === 2){
                                FLVrr.push(s[i]);
                            }
                        }
                        this.setState({
                            vipFNumArr:FLVrr,
                            vipQNumArr:QYVrr,
                        });
                    }
                });
            }else {
                const {history} = this.props;
                history.push('/login');
            }
        });
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllClick = () => {
        this.setState({
        });
        let tokenAuth = JSON.parse(sessionStorage.getItem('tokenAuth'));
        if (!tokenAuth){
            if (!tokenAuth.KeyToken){
                window.location.href='/login';
            }
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.PayWeChat);
        clearInterval(this.VipPayWeChat);
        this.setState = ()=>{
        };
    };


    //退出
    DropOut(e){
        e.nativeEvent.stopImmediatePropagation();
        const {history} = this.props;
        history.push('/login');
    };
    //密码修改
    ChangePassword(){
        const {history} = this.props;
        history.push('/forget');
    };



    NavClick=(item,index)=>{
        const {history} = this.props;
        history.push(item.route);
    };





    // 退出续费
    OutXFVip(){
        clearInterval(this.PayWeChat);
        this.setState({
            VipPayPage:'sb',
        });
    };
    OutNoVip(){
        window.location.reload();
    };
    OutKTVip(){
        clearInterval(this.PayWeChat);
        this.setState({
            VipPayPage:'sb',
        });
        get({url:'user/show_VIP_goods'}).then((res) => {
            if(res.errno === '200'){
                let b = res.data;
                this.setState({
                    VipListArr:b,
                });
                if (b.length !== 0){
                    let QYCrr = [];
                    let FLCrr = [];
                    let HDCrr = [];
                    for(let i=0;i<b.length;i++){
                        if(b[i].type === 1){
                            QYCrr.push(b[i]);
                        }else if(b[i].type === 2){
                            FLCrr.push(b[i]);
                        }else if(b[i].type === 3){
                            HDCrr.push(b[i]);
                        }
                    }
                    if (QYCrr.length > 3){
                        let QYBrr = [];
                        for(let i=0;i<QYCrr.length;i++){
                            if(i < 3){
                                QYBrr.push(QYCrr[i]);
                            }
                        }
                        this.setState({
                            QYArr:QYBrr,
                        });
                    }else {
                        this.setState({
                            QYArr:QYCrr,
                        });
                    }
                    if (FLCrr.length > 3){
                        let FLBrr = [];
                        for(let i=0;i<FLCrr.length;i++){
                            if(i < 3){
                                FLBrr.push(FLCrr[i]);
                            }
                        }
                        this.setState({
                            FLArr:FLBrr,
                        });
                    }else {
                        this.setState({
                            FLArr:FLCrr,
                        });
                    }
                    if (HDCrr.length > 3){
                        let HDBrr = [];
                        for(let i=0;i<HDCrr.length;i++){
                            if(i < 3){
                                HDBrr.push(HDCrr[i]);
                            }
                        }
                        this.setState({
                            HDArr:HDBrr,
                        });
                    }else {
                        this.setState({
                            HDArr:HDCrr,
                        });
                    }
                }else {
                    this.setState({
                        QYArr:[],
                        FLArr:[],
                        HDArr:[],
                    });
                }
            }else {
                // this.setState({
                //     PopUp:'提示弹窗',
                //     MessageTip:res.errmsg,
                // });
            }
        });
        // 查看权益次数
        get({url:'user/query_vip_equity_num'}).then((res) => {
            if(res.errno === '200'){
                this.setState({
                    vipNum:res.num,
                });
            }
        });
        // 查看已领取权益
        get({url:'user/query_receive_vip_equity'}).then((res) => {
            if(res.errno === '200'){
                let s = res.data;
                this.setState({
                    vipNumArr:s,
                });
                let QYVrr = [];
                let FLVrr = [];
                for(let i=0;i<s.length;i++){
                    if(s[i].type === 1){
                        QYVrr.push(s[i]);
                    }else if(s[i].type === 2){
                        FLVrr.push(s[i]);
                    }
                }
                this.setState({
                    vipFNumArr:FLVrr,
                    vipQNumArr:QYVrr,
                });
            }
        });
    };






    // 续费
    XFVIP(){
        this.setState({
            VipPayPage:'pay',
        });
        get({url:'/user/get_nopay_order/8'}).then(res=> {
            if(res.errno === '200'){
                this.setState({
                    orderId:res.order_id,
                });
                this.PayWeChat = setInterval(() => {
                    get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                        if (res.state=== 1) {
                            clearInterval(this.PayWeChat);
                            this.setState({
                                VipPayStep:'sb'
                            });
                            get({url:'user/basic_info'}).then((res) => {
                                if(res.errno === '200'){
                                    let a = res.data[0];
                                    this.setState({
                                        User:a,
                                    });
                                }
                            });
                        }
                    });
                }, 2000);
            }else {
                post({
                    url:'user/VIP_order',
                    data:{
                        order_remark:'VIP缴费',
                    },
                }).then((res) => {
                    if (res.errno === '200') {
                        this.setState({
                            orderId:res.order_id,
                        });
                        this.PayWeChat = setInterval(() => {
                            get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                                if (res.state=== 1) {
                                    clearInterval(this.PayWeChat);
                                    this.setState({
                                        VipPayStep:'sb'
                                    });
                                    get({url:'user/basic_info'}).then((res) => {
                                        if(res.errno === '200'){
                                            let a = res.data[0];
                                            this.setState({
                                                User:a,
                                            });
                                        }
                                    });
                                }
                            });
                        }, 2000);
                    } else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                        });
                    }
                });
            }
        });
    };




    // 点击更多
    VipTopNav=(item,index) =>{
        this.setState({
            TheTopNav:'> '+item.nav,
            vipPageStep:'more',
        });
        this.props.ChildBackNavClick(item.nav);
    };

    // 具体内容权益
    Content2=(item2,index2) =>{
        this.setState({
            vipPageStep:'content',
            vipOrderInfo:item2,
            TheTopNav:'> VIP权益',
        });
        this.props.ChildBackNavClick('VIP权益');
    };
    Content6=(item6,index6) =>{
        this.setState({
            vipPageStep:'content',
            vipOrderInfo:item6,
            TheTopNav:'> VIP福利',
        });
        this.props.ChildBackNavClick('VIP福利');
    };

    // 具体内容福利
    Content3=(item3,index3) =>{
        this.setState({
            vipPageStep:'cjdsb',
            FLId:item3.id,
            TheTopNav:'> '+item3.title,
        });
        this.props.ChildBackNavClick(item3.title);
    };


    //更多点击具体内容
    Content4=(item4,index4) =>{
        if (item4.type === 1){
            this.setState({
                backNum:'sb',
                vipPageStep:'content',
                vipOrderInfo:item4,
                TheTopNav:'> VIP权益',
            });
            this.props.ChildBackNavClick('VIP权益');
        }else if (item4.type === 2){
            this.setState({
                backNum:'sb',
                vipPageStep:'content',
                vipOrderInfo:item4,
                TheTopNav:'> VIP福利',
            });
            this.props.ChildBackNavClick('VIP福利');
        }else {
            this.setState({
                backNum:'dsb',
                vipPageStep:'cjdsb',
                FLId:item4.id,
                TheTopNav:'> '+item4.title,
            });
            this.props.ChildBackNavClick(item4.title);
        }
    };

    // 具体内容的领取按钮
    VipContentBtn(){
        let vipOrderInfo = this.state.vipOrderInfo;
        post({
            url:'user/get_nopay_order_vip',
            data:{
                tveg_id:vipOrderInfo.id,
                order_type: '9',
            },
        }).then((res) => {
            if (res.errno === '200') {
                if (vipOrderInfo.type === 2){
                    this.setState({
                        VipOrderId:res.order_id,
                        PageType:'sb3',
                    });
                    this.VipPayWeChat = setInterval(() => {
                        get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                            if (res.state=== 1) {
                                clearInterval(this.VipPayWeChat);
                                this.setState({
                                    VipConPayStep:'sb'
                                });
                            }
                        });
                    }, 2000);
                }else if (vipOrderInfo.type === 1){
                    this.setState({
                        VipOrderId:res.order_id,
                        PageType:'sb2',
                        DBKey:'sb',
                    });
                }
            } else {
                this.setState({
                    PageType:'sb1',
                });
            }
        });
    };

    // 领取信息填写
    address(event){
        // event.target.value = event.target.value.replace(/[^\d]/g, '')
        this.setState({
            address: event.target.value,
        });
    };
    name(event){
        // event.target.value = event.target.value.replace(/[^\d]/g, '')
        this.setState({
            name: event.target.value,
        });
    };
    phone(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '')
        this.setState({
            phone: event.target.value,
        });
    };
    PageTypeContentBtn(){
        let phone = this.state.phone;
        let name = this.state.name;
        let address = this.state.address;
        let vipOrderInfo = this.state.vipOrderInfo;
        let VipType = this.state.VipType;
        if (phone !== ''&&name !== ''&&address !== ''){
            if (vipOrderInfo.type === 2){
                post({
                    url:'user/VIP_goods_order',
                    data:{
                        consignee: name,
                        province: '',
                        city: '',
                        county: '',
                        address: address,
                        mobile: phone,
                        is_vip: VipType,
                        tveg_id: vipOrderInfo.id,
                        order_remark:vipOrderInfo.title+'购买',
                    },
                }).then((res) => {
                    if (res.errno === '200') {
                        this.setState({
                            VipOrderId:res.order_id,
                            PageType:'sb3',
                        });
                        this.VipPayWeChat = setInterval(() => {
                            get({url:"/user/get_order_state/"+res.order_id}).then(res => {
                                if (res.state=== 1) {
                                    clearInterval(this.VipPayWeChat);
                                    this.setState({
                                        VipConPayStep:'sb'
                                    });
                                }
                            });
                        }, 2000);
                    } else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:res.errmsg+'！',
                        });
                    }

                });
            }else if (vipOrderInfo.type === 1){
                this.setState({
                    PageType:'sb2',
                });
            }
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请检查信息完整性！',
            });
        }
    };

    // 兑换 走对公转账逻辑
    PageTypeContentOkBtn(){
        let phone = this.state.phone;
        let name = this.state.name;
        let address = this.state.address;
        let vipOrderInfo = this.state.vipOrderInfo;
        let VipOrderId = this.state.VipOrderId;
        let DBKey = this.state.DBKey;
        if (DBKey !== ''){
            get({url:"/payment/re_no_fee/"+VipOrderId}).then(res => {
                if (res.errno === '200') {
                    this.setState({
                        PageType:'sb4',
                        DBKey:'',
                    });
                } else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg+'！',
                    });
                }
            });
        }else {
            if (phone !== ''&&name !== ''&&address !== ''){
                post({
                    url:'user/VIP_goods_order',
                    data:{
                        consignee: name,
                        province: '',
                        city: '',
                        county: '',
                        address: address,
                        mobile: phone,
                        is_vip: 1,
                        tveg_id: vipOrderInfo.id,
                        order_remark:vipOrderInfo.title+'兑换',
                    },
                }).then((res) => {
                    if (res.errno === '200') {
                        this.setState({
                            VipOrderId:res.order_id,
                        });
                        get({url:"/payment/re_no_fee/"+res.order_id}).then(res => {
                            if (res.errno === '200') {
                                this.setState({
                                    PageType:'sb4',
                                });
                            } else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:res.errmsg+'！',
                                });
                            }
                        });
                    } else {
                        this.setState({
                            PopUp:'提示弹窗',
                            MessageTip:'兑换系出错，清刷新网页后重试！',
                        });
                    }

                });
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'邮寄信息填写有错误！',
                    PageType:'sb1',
                });
            }
        }
    };
    // 兑换退回
    PageTypeContentErrBtn(){
        this.setState({
            PageType:'sb1',
        });
    };


    // 点击领取权益次数
    VipANum=(item,index) =>{
        if (this.state.vipNumArr.length !== 0){
            this.setState({
                vipPageStep:'num',
                TheTopNav:'> 权益兑换记录',
            });
            this.props.ChildBackNavClick('权益兑换记录');
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'您暂无VIP权益兑换记录！',
            });
        }
    };


    // 点击购买福利次数
    VipBNum=(item,index) =>{
        if (this.state.vipNumArr.length !== 0){
            this.setState({
                vipPageStep:'num',
                TheTopNav:'> 福利购买记录',
            });
            this.props.ChildBackNavClick('福利购买记录');
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'您暂无VIP福利购买记录！',
            });
        }
    };



    // 返回VIP主页
    VipBack(){
        this.setState({
            backNum:'',
            TheTopNav:'',
            vipPageStep:'index',
        });
        this.props.ChildBackNavClick('');
    };
    // 返回V
    VipBack2(){
        let backNum = this.state.backNum;
        if(backNum === 'sb'){
            this.setState({
                backNum:'sb',
                vipPageStep:'more',
                vipOrderInfo:{},
            });
        }else if(backNum === 'dsb'){
            this.setState({
                backNum:'dsb',
                TheTopNav:'> VIP福利',
                vipPageStep:'more',
                vipOrderInfo:{},
            });
            this.props.ChildBackNavClick('VIP福利');
        }else{
            this.setState({
                backNum:'',
                TheTopNav:'',
                vipPageStep:'index',
                vipOrderInfo:{},
            });
            this.props.ChildBackNavClick('');
        }
    };

    // 弹窗退出
    OutPageType(){
        this.setState({
            PageType:'',
            phone:'',
            name:'',
            address:'',
        });
    };
    OutPageType2(){
        clearInterval(this.VipPayWeChat);
        let VipConPayStep = this.state.VipConPayStep;
        if (VipConPayStep !== ''){
            window.location.reload();
        }else {
            this.setState({
                PageType:'',
                phone:'',
                name:'',
                address:'',
                VipOrderId:'',
            });
        }
    };
    OutPageType3(){
        window.location.reload();
    };


    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };

  render() {
        return (
            <div>
                <div className={'MemberMainBoxRight'} style={{height:window.innerHeight-180}}>
                    <div className={'MemberPackageBox scrollBar'} style={{height:window.innerHeight-180,position:'relative',}}>
                        {
                            this.state.VipPayPage === 'pay'&&
                            <div className={'NoVipBox'}>
                                <div className={'NoVipTitBox clearfix'}>
                                    {
                                        this.state.VipType === 1&&
                                        <div className={'css-icon icon-close NoVipClose'} onClick={this.OutXFVip.bind(this)}> </div>
                                    }
                                    {
                                        this.state.VipType === 0 && this.state.VipPayStep !== ''&&
                                        <div className={'css-icon icon-close NoVipClose'} onClick={this.OutNoVip.bind(this)}> </div>
                                    }
                                    {
                                        this.state.VipType === 0 && this.state.VipPayStep === ''&&
                                        <div className={'css-icon icon-close NoVipClose'} onClick={this.OutKTVip.bind(this)}> </div>
                                    }
                                    <div className={'NoVipPeImg'}>
                                        {
                                            this.state.User.image&&
                                            <img
                                                src={this.state.User.image}
                                                alt={''}
                                            />
                                        }
                                    </div>
                                    <div className={'NoVipPeInfoBOx'}>
                                        <div>{this.state.User.real_name}</div>
                                        <div>{this.state.User.yy_num}</div>
                                    </div>
                                    <div className={'BaseVipBox clearfix'} style={{left:'unset',right:0,minWidth:'180px',}}>
                                        <div className={'BaseVipIco VipIcoWr'}> </div>
                                        <div className={'NoVipPeInfoBOx'} style={{width:180,height:50,lineHeight:'25px',fontSize:16,fontWeight:'bold',margin:'20px 0 0 20px',letterSpacing:'0.15em',}}>
                                            <div>开通VIP后可使</div>
                                            <div>用所有VIP功能</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={'NoVipMain clearfix'}>
                                    <div className={'NoVipPayBox'}>
                                        <div className={'NoVipPayTit'}>365天</div>
                                        <div className={'NoVipPayBg'}>
                                            <div className={'NoVipPayBg2'}>
                                                <div className={'NoVipPay'}>
                                                    ￥100
                                                </div>
                                                <div className={'NoVipPeInfoBOx'} style={{float:'unset',width:108,height:50,lineHeight:'25px',fontSize:14,fontWeight:'bold',margin:'0 auto',letterSpacing:'0.15em',}}>
                                                    <div>开通VIP后可使</div>
                                                    <div>用所有VIP功能</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.VipPayStep === ''&&
                                        <div className={'NoVipCodeBox'}>
                                            <div className={'NoVipCodeTitBox clearfix'}>
                                                <div className={'SMZF'}>扫码支付</div>
                                                <div className={'XZF'}>需支付:<span style={{fontSize:24,color:'#0488CA',}}>￥100</span></div>
                                            </div>
                                            <div className={'NoVipPayCode'}>
                                                {
                                                    this.state.orderId !== '' &&
                                                    <img
                                                        style={{display:'block',width:140,height:140,}}
                                                        alt={''}
                                                        src={API_URL+"/payment/WXpay_goods_order/"+this.state.orderId}
                                                    />
                                                }
                                            </div>
                                            <div style={{display:'table',margin:'0 auto',}}>
                                                <div className={'WeChatPayIco'}> </div>
                                                <div className={'WeChatPayWord'}>微信支付</div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.VipPayStep !== ''&&
                                        <div className={'NoVipCodeBox'}>
                                            <div style={{display:'table',margin:'80px auto',}}>
                                                <div className={'WeChatPayIco'} style={{width:70,height:64,marginRight:20,}}> </div>
                                                <div className={'WeChatPayWord'} style={{height:64,lineHeight:'64px',fontSize:40,letterSpacing:'0.2em',}}>支付成功</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={'NoVipTip'}>如有任何疑问，请联系我们</div>
                            </div>
                        }


                        {
                            this.state.vipPageStep === 'index'&& this.state.VipPayPage === 'sb'&& this.state.QYArr.length+this.state.HDArr.length+this.state.FLArr.length === 0&&
                            <div className={'MemberPackageBox scrollBar'} style={{height:window.innerHeight-180,position:'relative',}}>
                                <div style={{padding:'160px 0',}}>
                                    <div className={'TrainDataMain'}>
                                        <div className={'keyNumLogo'}> </div>
                                        <div className={'keyNumTitle'}>暂无相关数据，请耐心等待或联系我们。</div>
                                    </div>
                                </div>
                            </div>
                        }




                        {
                            this.state.vipPageStep === 'index'&& this.state.VipPayPage === 'sb'&& this.state.QYArr.length+this.state.HDArr.length+this.state.FLArr.length !== 0&&
                            <div className={'MemberPackageBox scrollBar'} style={{height:window.innerHeight-180,position:'relative',}}>
                                {
                                    this.state.VipType !== 0&&
                                    <div className={'VipMainTitBox clearfix'}>
                                        <div className={'XFWord'} onClick={this.XFVIP.bind(this)}>续费</div>
                                        <div className={'XFIco'} onClick={this.XFVIP.bind(this)}> </div>
                                    </div>
                                }
                                {
                                    this.state.VipType === 0&&
                                    <div className={'VipMainTitBox clearfix'}>
                                        <div className={'XFWord'} onClick={this.XFVIP.bind(this)}>开通VIP</div>
                                        <div className={'XFIco'} onClick={this.XFVIP.bind(this)}> </div>
                                    </div>
                                }
                                <div className={'VipIndexMain'} style={{minHeight:window.innerHeight-268,}}>
                                    {
                                        this.state.TopNavArr.map((item,index) =>
                                            <div key={index}>
                                                {
                                                    item.id === '1'&& this.state.QYArr.length !== 0&&
                                                    <div className={'VipIndexList'} >
                                                        <div className={'VipIndexListTit clearfix'}>
                                                            <div className={'VipIndexListTitName'}>
                                                                {item.nav}
                                                                {
                                                                    item.nav === 'VIP权益'&&this.state.vipNum !== ''&&
                                                                    <div className={'VipIndexListTitNum'} style={{textDecoration:'unset',cursor:'auto',color:'#05AE86',height:20,lineHeight:'20px',marginTop:10,fontSize:15,}}>权益剩余兑换次数：{this.state.vipNum}</div>
                                                                }
                                                            </div>
                                                            <div className={'VipIndexListTitMore'} onClick={() => this.VipTopNav(item,index)}>更多></div>
                                                        </div>
                                                        {
                                                            item.id === '1'&& this.state.VipType !== 0&&this.state.vipQNumArr.length !== 0&&
                                                            <div className={'VipIndexListTit2 clearfix'}>
                                                                <div className={'VipIndexListTitNum'} onClick={() => this.VipANum(item,index)}>兑换记录：{this.state.vipQNumArr.length}</div>
                                                            </div>
                                                        }
                                                        {
                                                            item.id === '1'&&
                                                            <div className={'VipMainConBox clearfix'}>
                                                                {
                                                                    this.state.QYArr.map((item2,index2) =>
                                                                        <div className={'VipMainConList'} key={index2} onClick={() => this.Content2(item2,index2)}>
                                                                            <div className={'VipMainConImg'}>
                                                                                <img
                                                                                    style={{display:'block',width:'100%',height:'100%',}}
                                                                                    alt={''}
                                                                                    src={item2.img_url}
                                                                                />
                                                                            </div>
                                                                            <div className={'VipNameInfoBox clearfix'}>
                                                                                <div className={'VipNameInfo'} style={{marginTop:8,}}>{item2.title}</div>
                                                                                {
                                                                                    item2.dh_num ===0 && this.state.VipType !== 0&&
                                                                                    <div className={'VipIcoInfo clearfix'}>
                                                                                        <div className={'VipIcoInfoWord'} style={{color:'#CEBC17',}}>可兑换</div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    item2.dh_num !==0 && this.state.VipType !== 0&&
                                                                                    <div className={'VipIcoInfo clearfix'}>
                                                                                        <div className={'VipIcoInfoWord'} style={{color:'#666',}}>已兑换
                                                                                            {
                                                                                                item2.dh_num > 1 &&
                                                                                                <div>{item2.dh_num}次</div>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {/*{*/}
                                                                                {/*    this.state.VipType !== 0&& item2.dh_num ===0 && this.state.vipNum === 0&&*/}
                                                                                {/*    <div className={'VipIcoInfoWord'} style={{color:'#666',fontSize:13,marginRight:4,}}>兑换次数已用尽</div>*/}
                                                                                {/*}*/}
                                                                                {
                                                                                    this.state.VipType === 0&&
                                                                                    <div className={'VipIcoInfoWord'} style={{color:'#666',fontSize:13,marginRight:4,}}>非VIP不能兑换</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    item.id === '2'&& this.state.FLArr.length !== 0&&
                                                    <div className={'VipIndexList'} >
                                                        <div className={'VipIndexListTit clearfix'}>
                                                            <div className={'VipIndexListTitName'}>
                                                                {item.nav}
                                                                {
                                                                    item.nav === 'VIP权益'&&this.state.vipNum !== ''&&
                                                                    <div className={'VipIndexListTitNum'} style={{textDecoration:'unset',cursor:'auto',color:'#05AE86',height:20,lineHeight:'20px',marginTop:10,fontSize:15,}}>权益剩余兑换次数：{this.state.vipNum}</div>
                                                                }
                                                            </div>
                                                            <div className={'VipIndexListTitMore'} onClick={() => this.VipTopNav(item,index)}>更多></div>
                                                        </div>
                                                        {
                                                            item.id === '2'&& this.state.VipType !== 0&& this.state.vipFNumArr.length !== 0&&
                                                            <div className={'VipIndexListTit2 clearfix'}>
                                                                <div className={'VipIndexListTitNum'} onClick={() => this.VipBNum(item,index)}>购买记录：{this.state.vipFNumArr.length}</div>
                                                            </div>
                                                        }
                                                        {
                                                            item.id === '2'&&
                                                            <div className={'VipMainConBox clearfix'}>
                                                                {
                                                                    this.state.FLArr.map((item6,index6) =>
                                                                        <div className={'VipMainConList'} key={index6} onClick={() => this.Content6(item6,item6)}>
                                                                            <div className={'VipMainConImg'}>
                                                                                <img
                                                                                    style={{display:'block',width:'100%',height:'100%',}}
                                                                                    alt={''}
                                                                                    src={item6.img_url}
                                                                                />
                                                                            </div>
                                                                            <div className={'VipNameInfoBox clearfix'}>
                                                                                <div className={'VipNameInfo'} style={{marginTop:8,}}>{item6.title}</div>
                                                                                <div className={'VipIcoInfo clearfix'}>
                                                                                    <div className={'VipIcoInfoWord'}>￥{item6.vip_price}</div>
                                                                                    <div className={'XFIco'} style={{height:16,width:20,margin:'2px 0 0 0',}}> </div>
                                                                                    <div className={'VipIcoInfoWord'} style={{color:'#666',marginRight:16,fontSize:12,}}>￥{item6.price}</div>
                                                                                    <div className={'VipIcoInfoWord'} style={{color:'#666',fontSize:12,}}>非VIP</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    item.id === '3'&& this.state.HDArr.length !== 0&&
                                                    <div className={'VipIndexList'} >
                                                        <div className={'VipIndexListTit clearfix'}>
                                                            <div className={'VipIndexListTitName'}>
                                                                {item.nav}
                                                                {
                                                                    item.nav === 'VIP权益'&&this.state.vipNum !== ''&&
                                                                    <div className={'VipIndexListTitNum'} style={{textDecoration:'unset',cursor:'auto',color:'#05AE86',height:20,lineHeight:'20px',marginTop:10,fontSize:15,}}>权益剩余兑换次数：{this.state.vipNum}</div>
                                                                }
                                                            </div>
                                                            <div className={'VipIndexListTitMore'} onClick={() => this.VipTopNav(item,index)}>更多></div>
                                                        </div>
                                                        {
                                                            item.id === '3'&&
                                                            <div className={'VipMainConBox clearfix'}>
                                                                {
                                                                    this.state.HDArr.map((item3,index3) =>
                                                                        <div className={'VipMainConList'} key={index3} onClick={() => this.Content3(item3,index3)}>
                                                                            <div className={'VipMainConImg'}>
                                                                                <img
                                                                                    style={{display:'block',width:'100%',height:'100%',}}
                                                                                    alt={''}
                                                                                    src={item3.img_url}
                                                                                />
                                                                            </div>
                                                                            <div className={'VipNameInfoBox clearfix'}>
                                                                                <div className={'VipNameInfo'} style={{marginTop:8,height:60,}}>{item3.title}</div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.vipPageStep === 'more'&& this.state.VipPayPage === 'sb'&&
                            <div className={'MemberPackageBox scrollBar'} style={{height:window.innerHeight-180,position:'relative',}}>
                                <div className={'VipMainTitBox clearfix'}>
                                    <div className={'SubjectDownIco'} style={{float:'left',width:38,height:48,position:'relative',}} onClick={this.VipBack.bind(this)}>
                                        <Triangle Direction={'left'} Color={'#0488CA'} Width={'10px'} Height={'10px'} Top={'14px'} Right={'8px'}/>
                                    </div>
                                    <div className={'VipBackWord'} onClick={this.VipBack.bind(this)}>返回</div>
                                </div>
                                <div className={'VipIndexMain'} style={{height:window.innerHeight-268,}}>
                                    {
                                        this.state.VipListArr.map((item4,index4) =>
                                            <div key={index4}  onClick={() => this.Content4(item4,index4)}>
                                                {
                                                    item4.type === 1&& this.state.TheTopNav === '> VIP权益'&&
                                                    <div className={'VipMainConList'}>
                                                        <div className={'VipMainConImg'}>
                                                            <img
                                                                style={{display:'block',width:'100%',height:'100%',}}
                                                                alt={''}
                                                                src={item4.img_url}
                                                            />

                                                        </div>
                                                        <div className={'VipNameInfoBox clearfix'}>
                                                            <div className={'VipNameInfo'} style={{marginTop:8,}}>{item4.title}</div>
                                                            {
                                                                item4.dh_num ===0 && this.state.VipType !== 0&&
                                                                <div className={'VipIcoInfo clearfix'}>
                                                                    <div className={'VipIcoInfoWord'} style={{color:'#CEBC17',}}>可兑换</div>
                                                                </div>
                                                            }
                                                            {
                                                                item4.dh_num !==0 && this.state.VipType !== 0&&
                                                                <div className={'VipIcoInfo clearfix'}>
                                                                    <div className={'VipIcoInfoWord'} style={{color:'#666',}}>已兑换
                                                                        {
                                                                            item4.dh_num > 1 &&
                                                                            <div>{item4.dh_num}次</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.VipType === 0&&
                                                                <div className={'VipIcoInfoWord'} style={{color:'#666',fontSize:13,marginRight:4,}}>非VIP不能兑换</div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item4.type === 2&& this.state.TheTopNav === '> VIP福利'&&
                                                    <div className={'VipMainConList'}>
                                                        <div className={'VipMainConImg'}>
                                                            <img
                                                                style={{display:'block',width:'100%',height:'100%',}}
                                                                alt={''}
                                                                src={item4.img_url}
                                                            />
                                                        </div>
                                                        <div className={'VipNameInfoBox clearfix'}>
                                                            <div className={'VipNameInfo'} style={{marginTop:8,}}>{item4.title}</div>
                                                            <div className={'VipIcoInfo clearfix'}>
                                                                <div className={'VipIcoInfoWord'}>￥{item4.vip_price}</div>
                                                                <div className={'XFIco'} style={{height:16,width:20,margin:'2px 0 0 0',}}> </div>
                                                                <div className={'VipIcoInfoWord'} style={{color:'#666',marginRight:16,fontSize:12,}}>￥{item4.price}</div>
                                                                <div className={'VipIcoInfoWord'} style={{color:'#666',fontSize:12,}}>非VIP</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item4.type === 3&& this.state.TheTopNav === '> VIP活动'&&
                                                    <div className={'VipMainConList'}  onClick={() => this.Content4(item4,index4)}>
                                                        <div className={'VipMainConImg'}>
                                                            <img
                                                                style={{display:'block',width:'100%',height:'100%',}}
                                                                alt={''}
                                                                src={item4.img_url}
                                                            />
                                                        </div>
                                                        <div className={'VipNameInfoBox clearfix'}>
                                                            <div className={'VipNameInfo'} style={{marginTop:8,height:60,}}>{item4.title}</div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.vipPageStep === 'content' && this.state.VipPayPage === 'sb'&&
                            <div className={'MemberPackageBox scrollBar'} style={{height:window.innerHeight-180,position:'relative',}}>
                                <div className={'VipMainTitBox clearfix'}>
                                    <div className={'SubjectDownIco'}
                                         style={{float: 'left', width: 38, height: 48, position: 'relative',}}
                                         onClick={this.VipBack2.bind(this)}>
                                        <Triangle Direction={'left'} Color={'#0488CA'} Width={'10px'}
                                                  Height={'10px'} Top={'14px'} Right={'8px'}/>
                                    </div>
                                    <div className={'VipBackWord'} onClick={this.VipBack2.bind(this)}>返回</div>
                                </div>
                                <div className={'VipIndexMain'} style={{height: window.innerHeight - 316,}}>
                                    <div className={'VipContentImg'}>
                                        <img
                                            style={{display:'block',width:'auto',height:'100%',margin:'0 auto',}}
                                            alt={''}
                                            src={this.state.vipOrderInfo.img_url}
                                        />
                                    </div>
                                    <div className={'VipContentTitle'} style={{marginBottom:40,}}>{this.state.vipOrderInfo.title}</div>
                                    <div className={'VipConBigTitleBox clearfix'}>
                                        <div className={'VipConBigTitleBorder'}> </div>
                                        <div className={'VipConBigTitleBg'}>VIP权益介绍</div>
                                    </div>
                                    <div className={'VipContent'}>{this.state.vipOrderInfo.content}</div>
                                    <div className={'VipConBigTitleBox clearfix'}>
                                        <div className={'VipConBigTitleBorder'}> </div>
                                        <div className={'VipConBigTitleBg'}>VIP权益时间</div>
                                    </div>
                                    <div className={'VipContent'}>{this.state.vipOrderInfo.start_time} —— {this.state.vipOrderInfo.end_time}</div>
                                </div>
                                <div style={{width:'90%',height:62,margin:'10px auto',}}>
                                    {
                                        this.state.vipOrderInfo.type === 1&& this.state.VipType !== 0&& this.state.vipOrderInfo.dh_num === 0&& this.state.vipNum !== 0&&
                                        <div className={'VipContentBtn'} style={{backgroundColor:'#CEBC17',}} onClick={this.VipContentBtn.bind(this)}>立即兑换</div>
                                    }
                                    {
                                        this.state.vipOrderInfo.type === 1&& this.state.VipType !== 0&& this.state.vipOrderInfo.dh_num === 0&& this.state.vipNum === 0&&
                                        <div className={'VipContentBtn'} style={{backgroundColor:'#fff',color:'#666',}}>已无VIP兑换次数</div>
                                    }
                                    {
                                        this.state.vipOrderInfo.type === 1&& this.state.VipType !== 0&& this.state.vipOrderInfo.dh_num !== 0&&
                                        <div className={'VipContentBtn'} style={{backgroundColor:'#666',}}>已兑换
                                            {
                                                this.state.vipOrderInfo.dh_num >1 &&
                                                <div>{this.state.vipOrderInfo.dh_num}次</div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.vipOrderInfo.type === 1&& this.state.VipType === 0&&
                                        <div className={'VipContentBtn'} style={{backgroundColor:'#fff',color:'#666',}}>非VIP不能兑换</div>
                                    }
                                    {
                                        this.state.vipOrderInfo.type === 2&& this.state.VipType !== 0&&
                                        <div className={'VipContentBtn'} style={{backgroundColor:'#CEBC17',}} onClick={this.VipContentBtn.bind(this)}>￥{this.state.vipOrderInfo.vip_price}</div>
                                    }
                                    {
                                        this.state.vipOrderInfo.type === 2&& this.state.VipType === 0&&
                                        <div className={'VipContentBtn'} style={{backgroundColor:'#0488CA',}} onClick={this.VipContentBtn.bind(this)}>￥{this.state.vipOrderInfo.price}</div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.vipPageStep === 'num' && this.state.VipPayPage === 'sb'&&
                            <div className={'MemberPackageBox scrollBar'} style={{height:window.innerHeight-180,position:'relative',}}>
                                <div className={'VipMainTitBox clearfix'}>
                                    <div className={'SubjectDownIco'}
                                         style={{float: 'left', width: 38, height: 48, position: 'relative',}}
                                         onClick={this.VipBack.bind(this)}>
                                        <Triangle Direction={'left'} Color={'#0488CA'} Width={'10px'}
                                                  Height={'10px'} Top={'14px'} Right={'8px'}/>
                                    </div>
                                    <div className={'VipBackWord'} onClick={this.VipBack.bind(this)}>返回</div>
                                </div>
                                <div className={'VipQYNumTitle'}>
                                    <div style={{display:'table',margin:'0 auto',}}>
                                        <div className={'VipQYNumTitleIco'} style={{width:60,height:60,marginRight:20,}}> </div>
                                        {
                                            this.state.TheTopNav === '> 权益兑换记录'&&
                                            <div style={{letterSpacing:'0.2em',float:'left',}}>兑换记录</div>
                                        }
                                        {
                                            this.state.TheTopNav === '> 福利购买记录'&&
                                            <div style={{letterSpacing:'0.2em',float:'left',}}>购买记录</div>
                                        }
                                    </div>
                                </div>
                                <div className={'VipIndexMain'} style={{height: window.innerHeight - 268,}}>
                                    {
                                        this.state.vipNumArr.map((item5,index5) =>
                                            <div>
                                                {
                                                    this.state.TheTopNav === '> 权益兑换记录'&& item5.type === 1&&
                                                    <div className={'VipQYNumList clearfix'} key={index5}>
                                                        <div className={'VipQYNumListImg'}>
                                                            <img
                                                                style={{display:'block',width:'auto',height:'100%',margin:'0 auto',}}
                                                                alt={''}
                                                                src={item5.img_url}
                                                            />
                                                        </div>
                                                        <div className={'VipQYNumListCon'}>
                                                            <div className={'VipQYNumListConLi Ellipsis'}>{item5.title}</div>
                                                            <div className={'VipQYNumListConLi Ellipsis'}>兑换日期：{item5.pay_time.substring(0,10)}</div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.TheTopNav === '> 福利购买记录'&& item5.type === 2&&
                                                    <div className={'VipQYNumList clearfix'} key={index5}>
                                                        <div className={'VipQYNumListImg'}>
                                                            <img
                                                                style={{display:'block',width:'auto',height:'100%',margin:'0 auto',}}
                                                                alt={''}
                                                                src={item5.img_url}
                                                            />
                                                        </div>
                                                        <div className={'VipQYNumListCon'}>
                                                            <div className={'VipQYNumListConLi Ellipsis'}>{item5.title}</div>
                                                            <div className={'VipQYNumListConLi Ellipsis'}>兑换日期：{item5.pay_time.substring(0,10)}</div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.vipPageStep === 'cjdsb' && this.state.FLId === 3 && this.state.VipPayPage === 'sb'&&
                            <div>
                                <div className={'VipMainTitBox clearfix'}>
                                    <div className={'SubjectDownIco'}
                                         style={{float: 'left', width: 38, height: 48, position: 'relative',}}
                                         onClick={this.VipBack2.bind(this)}>
                                        <Triangle Direction={'left'} Color={'#0488CA'} Width={'10px'}
                                                  Height={'10px'} Top={'14px'} Right={'8px'}/>
                                    </div>
                                    <div className={'VipBackWord'} onClick={this.VipBack2.bind(this)}>返回</div>
                                </div>
                                <div className={'VipIndexMain'} style={{height:window.innerHeight-268,}}>
                                    <Trick/>
                                </div>
                            </div>
                        }
                    </div>
                </div>




                {
                    this.state.PageType === 'sb1'&&
                    <div className={'PopUpBoxBg'}>
                        <div className={'NoVipBox'} style={{boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5',height:380,}}>
                            <div className={'NoVipTitBox clearfix'} style={{height:40,width:'99%',margin:'3px auto',}}>
                                <div className={'css-icon icon-close NoVipClose'} onClick={this.OutPageType.bind(this)} style={{backgroundColor:'unset',color:'#fff',top:3,right:3,}}> </div>
                                <div className={'PageTypeTitleWord'}>填写权益信息</div>
                            </div>
                            <div className={'PageTypeContent'}>
                                <div className={'PageTypeContentList clearfix'}>
                                    <div className={'PageTypeContentListTit'}>收件人</div>
                                    <div className={'PageTypeContentListInput'}>
                                        <input type={'text'}
                                               name={'SearchInput'}
                                               value={this.state.name}
                                               onChange={this.name.bind(this)}
                                               placeholder={''}
                                               autoComplete={'off'}
                                        />
                                    </div>
                                </div>
                                <div className={'PageTypeContentList clearfix'}>
                                    <div className={'PageTypeContentListTit'}>联系方式</div>
                                    <div className={'PageTypeContentListInput'}>
                                        <input type={'text'}
                                               name={'SearchInput'}
                                               value={this.state.phone}
                                               onChange={this.phone.bind(this)}
                                               placeholder={''}
                                               autoComplete={'off'}
                                        />
                                    </div>
                                </div>
                                <div className={'PageTypeContentList clearfix'}>
                                    <div className={'PageTypeContentListTit'}>邮寄地址</div>
                                    <div className={'PageTypeContentListInput'} style={{width:440,height:64,}}>
                                        <textarea
                                            style={{maxHeight:'64px',minHeight:'64px',minWidth:'440px',maxWidth:'440px',}}
                                            value={this.state.address}
                                            placeholder={''}
                                            onChange={this.address.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={'PageTypeContentBtn'} onClick={this.PageTypeContentBtn.bind(this)}>确认信息并生成订单</div>
                        </div>
                    </div>
                }
                {
                    this.state.PageType === 'sb2' &&
                    <div className={'PopUpBoxBg'}>
                        <div className={'NoVipBox'} style={{boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5', height: 380,}}>
                            <div className={'NoVipTitBox clearfix'}
                                 style={{height: 40, width: '99%', margin: '3px auto',}}>
                                <div className={'css-icon icon-close NoVipClose'} onClick={this.OutPageType.bind(this)} style={{backgroundColor: 'unset', color: '#fff', top: 3, right: 3,}}> </div>
                                <div className={'PageTypeTitleWord'}>确认兑换</div>
                            </div>
                            <div className={'PageTypeContent'}>
                                <div className={'PageTypeContentTit'}>{this.state.vipOrderInfo.title}</div>
                                <div className={'PageTypeContentCon'}>是否确认消耗一次兑换机会，兑换 <span style={{color:'#0488ca',}}>“{this.state.vipOrderInfo.title}“</span>？</div>
                                <div style={{display:'table',margin:'0 auto',}}>
                                    <div className={'PageTypeContentOkBtn'} onClick={this.PageTypeContentOkBtn.bind(this)}>确认兑换</div>
                                    <div className={'PageTypeContentOkBtn'} onClick={this.PageTypeContentErrBtn.bind(this)} style={{backgroundColor:'#828282',}}>我再想想</div>
                                </div>
                            </div>
                            <div className={'NoVipTip'} style={{textAlign:'center',}}>本次领取消耗一次领取机会，请确认后再领取。</div>
                        </div>
                    </div>
                }
                {
                    this.state.PageType === 'sb4' &&
                    <div className={'PopUpBoxBg'}>
                        <div className={'NoVipBox'} style={{boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5', height: 380,}}>
                            <div className={'NoVipTitBox clearfix'}
                                 style={{height: 40, width: '99%', margin: '3px auto',}}>
                                <div className={'css-icon icon-close NoVipClose'} onClick={this.OutPageType3.bind(this)} style={{backgroundColor: 'unset', color: '#fff', top: 3, right: 3,}}> </div>
                                <div className={'PageTypeTitleWord'}>兑换成功</div>
                            </div>
                            <div className={'PageTypeContent'}>
                                <div className={'ReSuccessBox'} style={{width:260,margin:'80px auto',}}>
                                    <div className={'ReSuccessIco'}> </div>
                                    <div className={'WeChatPayWord'} style={{height:64,lineHeight:'64px',fontSize:40,letterSpacing:'0.2em',}}>兑换成功</div>
                                </div>
                            </div>
                            <div className={'NoVipTip'} style={{textAlign:'center',}}>您已兑换成功，如有任何疑问，请联系我们</div>
                        </div>
                    </div>
                }
                {
                    this.state.PageType === 'sb3' &&
                    <div className={'PopUpBoxBg'}>
                        <div className={'NoVipBox'} style={{boxShadow: '-2.37px 2.37px 4.75px 0px #C5C5C5', height: 380,}}>
                            <div className={'NoVipTitBox clearfix'}
                                 style={{height: 40, width: '99%', margin: '3px auto',}}>
                                <div className={'css-icon icon-close NoVipClose'} onClick={this.OutPageType2.bind(this)} style={{backgroundColor: 'unset', color: '#fff', top: 3, right: 3,}}> </div>
                                <div className={'PageTypeTitleWord'}>支付页面</div>
                            </div>
                            <div className={'PageTypeContent'} style={{height:246,border:0,boxShadow:'unset',}}>
                                <div className={'NoVipPayBox'}>
                                    <div className={'NoVipPayTit'}>请支付</div>
                                    <div className={'NoVipPayBg'}>
                                        {
                                            this.state.VipType !== 0&&
                                            <div className={'NoVipPayBg2'}>
                                                <div className={'NoVipPay'}>
                                                    ￥{this.state.vipOrderInfo.vip_price}
                                                </div>
                                                <div className={'NoVipPeInfoBOx'} style={{float:'unset',width:82,height:50,lineHeight:'25px',fontSize:14,fontWeight:'bold',margin:'0 auto',letterSpacing:'0.15em',}}>
                                                    <div>金额已享受</div>
                                                    <div>V I P 权益</div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.VipType === 0&&
                                            <div className={'NoVipPayBg2'}>
                                                <div className={'NoVipPay'}>
                                                    ￥{this.state.vipOrderInfo.price}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {
                                    this.state.VipConPayStep === ''&&
                                    <div className={'NoVipCodeBox'}>
                                        <div className={'NoVipCodeTitBox clearfix'}>
                                            <div className={'SMZF'}>扫码支付</div>
                                        </div>
                                        <div className={'NoVipPayCode'}>
                                            {
                                                this.state.VipOrderId !== '' &&
                                                <img
                                                    style={{display:'block',width:140,height:140,}}
                                                    alt={''}
                                                    src={API_URL+"/payment/WXpay_goods_order/"+this.state.VipOrderId}
                                                />
                                            }
                                        </div>
                                        <div style={{display:'table',margin:'0 auto',}}>
                                            <div className={'WeChatPayIco'}> </div>
                                            <div className={'WeChatPayWord'}>微信支付</div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.VipConPayStep !== ''&&
                                    <div className={'NoVipCodeBox'}>
                                        <div style={{display:'table',margin:'80px auto',}}>
                                            <div className={'WeChatPayIco'} style={{width:70,height:64,marginRight:20,}}> </div>
                                            <div className={'WeChatPayWord'} style={{height:64,lineHeight:'64px',fontSize:40,letterSpacing:'0.2em',}}>支付成功</div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={'NoVipTip'} style={{textAlign:'center',}}>如有任何疑问，请联系我们</div>
                        </div>
                    </div>
                }
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>

        );
    }

}

export default Vip;

