import React, { Component } from 'react';
import PopUp from "../round/PopUp";
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import './exam.css';
import '../inquire/inquire.css';
import {get} from "../../ajax/tools";

class ExamMoreList extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight : '',
            ExamMoreType:'',
            ExamMoreListArr:[],
            ExamMoreInfo:{},
            ExamMoreInfos:{},
            ExamMoreInfoKind:[],

            ExamMoreId:'',
        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }
        let examMoreId = this.props.match.params.examMoreId;
        if (examMoreId === 'P3sz9Yzi'){
            this.setState({
                ExamMoreType:'list',
            });
            get({url:"index/exam_room_show"}).then(res => {
                if(res.errno === '200'){
                    this.setState({
                        ExamMoreListArr:res.data,
                    });
                }else {
                    this.setState({
                        ExamMoreListArr:[],
                    });
                }
            });
        } else {
            this.setState({
                ExamMoreType:'info',
                ExamMoreId:examMoreId,
            });
            get({url:"index/exam_room_show"}).then(res => {
                if(res.errno === '200'){
                    for(let i=0;i<res.data.length;i++){
                        let Id = res.data[i].id;
                        if(Id === parseInt(examMoreId,0)){
                            this.setState({
                                ExamMoreInfos:res.data[i],
                            });
                            get({url:"index/exam_room_detail/"+examMoreId+'/'+res.data[i].ti_id}).then(res => {
                                if(res.errno === '200'){
                                    this.setState({
                                        ExamMoreInfo:res.data[0],
                                        ExamMoreInfoKind:res.data[0].exam_kind,
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'提示弹窗',
                                        MessageTip:'未知错误！',
                                    });
                                }
                            });
                        }
                    }
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'未知错误！',
                    });
                }
            });
        }
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };


    AllClick = () => {
        this.setState({
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };




    ExamMoreClick=(item,index) =>{
        this.setState({
            ExamMoreType:'info',
            ExamMoreId:index,
        });
        let examMoreId = item.id;
        get({url:"index/exam_room_show"}).then(res => {
            if(res.errno === '200'){
                for(let i=0;i<res.data.length;i++){
                    let Id = res.data[i].id;
                    if(Id === parseInt(examMoreId,0)){
                        this.setState({
                            ExamMoreInfos:res.data[i],
                        });
                        get({url:"index/exam_room_detail/"+examMoreId+'/'+res.data[i].ti_id}).then(res => {
                            if(res.errno === '200'){
                                this.setState({
                                    ExamMoreInfo:res.data[0],
                                    ExamMoreInfoKind:res.data[0].exam_kind,
                                });
                            }else {
                                this.setState({
                                    PopUp:'提示弹窗',
                                    MessageTip:'未知错误！',
                                });
                            }
                        });
                    }
                }
            }else {
                this.setState({
                    PopUp:'提示弹窗',
                    MessageTip:'未知错误！',
                });
            }
        });
    };


    ExamMoreBack = () => {
        let examMoreId = this.props.match.params.examMoreId;
        if (examMoreId === 'P3sz9Yzi'){
            this.setState({
                ExamMoreType:'list',
                ExamMoreInfo:{},
                ExamMoreInfos:{},
                ExamMoreInfoKind:[],
            });
        } else {
            const {history} = this.props;
            history.push('/home');
        }
    };


    ExamMoreBtn = () => {
        const {history} = this.props;
        history.push('/exam');
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
            <div className={'MainBox'} style={{height:this.state.winHeight,}}>
                <Header/>
                <div className={'MainContent'} style={{width:1000,}}>
                    <div className={'MainTopBox clearfix'}>
                        <div className={'MainConTitle'}>
                            <div className={'MainConTitZH'}>能力测试</div>
                            <div className={'MainConTitEN'}>Ability&nbsp;&nbsp;test</div>
                        </div>
                        <div className={'MainConTitBorder'}> </div>
                        <div className={'MainActiveListTitle'}>examination room</div>
                    </div>
                    {
                        this.state.ExamMoreType === 'list' &&
                        <div className={'ContentMainBox clearfix'} style={{backgroundColor:'#FFFFFF',paddingBottom:10,paddingTop:10,}}>
                            {
                                this.state.ExamMoreListArr.map((item,index) => (
                                    <div className={'ExamMoreListBox'} key={index}  onClick={() => this.ExamMoreClick(item,index)}>
                                        <div className={'ExamMoreList'} style={{width:600,textAlign:'left',textIndent:'2em',}}>
                                            {item.city+item.county+item.time}等阶测试{item.name}考场
                                        </div>
                                        <div className={'ExamMoreList'} style={{width:200,}}>············</div>
                                        <div className={'ExamMoreList'} style={{width:200,}}>{item.ctime}</div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {
                        this.state.ExamMoreType === 'info' &&
                        <div className={'ContentMainBox clearfix'} style={{backgroundColor:'#FFFFFF',position:'relative',}}>
                            <div className={'CounselorTitle'}>
                                <div className={'DetailsBackBox clearfix'} style={{marginTop:20,}} onClick={this.ExamMoreBack.bind(this)}>
                                    <div className={'DetailsBackIco'}> </div>
                                    <div className={'DetailsBackWord'}>返回</div>
                                </div>
                            </div>
                            <div className={'ExamMoreRoomInfoTitle'}>
                                {this.state.ExamMoreInfos.city+this.state.ExamMoreInfos.county+this.state.ExamMoreInfos.time}等阶测试{this.state.ExamMoreInfos.name}
                                考场信息
                            </div>
                            <div className={'TaAndScDetailsLeftBox'} style={{width:456,}}>
                                <div className={'TaAndScDetailsCfBox'} style={{width:420,height:280,}}>
                                    <img alt={''}
                                         src={this.state.ExamMoreInfo.image}
                                    />
                                </div>
                            </div>
                            <div className={'ExamMoreRoomInfoBox'}>
                                <div className={'ExamMoreRoomInfoListBox clearfix'}>
                                    <div className={'ExamMoreRoomInfoListTitle'}>考场地址：</div>
                                    <div className={'ExamMoreRoomInfoListContent'}>{this.state.ExamMoreInfos.address}</div>
                                </div>
                                <div className={'ExamMoreRoomInfoListBox clearfix'}>
                                    <div className={'ExamMoreRoomInfoListTitle'}>考场时间：</div>
                                    <div className={'ExamMoreRoomInfoListContent'}>{this.state.ExamMoreInfos.time}</div>
                                </div>
                                <div className={'ExamMoreRoomInfoListBox clearfix'}>
                                    <div className={'ExamMoreRoomInfoListTitle'}>考核人数：</div>
                                    <div className={'ExamMoreRoomInfoListContent'}>{this.state.ExamMoreInfo.exam_per_num}人</div>
                                </div>
                                <div className={'ExamMoreRoomInfoListBox clearfix'}>
                                    <div className={'ExamMoreRoomInfoListTitle'}>考核项目：</div>
                                    <div className={'ExamMoreRoomInfoListContent'}>
                                        {
                                            this.state.ExamMoreInfoKind.map((item,index) => (
                                                <span key={index} style={{float:'left',}}>
                                                        {item.name}、
                                                </span>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className={'ExamMoreRoomInfoListBox clearfix'}>
                                    <div className={'ExamMoreRoomInfoListTitle'}>联系方式：</div>
                                    <div className={'ExamMoreRoomInfoListContent'}>{this.state.ExamMoreInfos.phone}</div>
                                </div>
                            </div>
                            <button className={'ExamStateBtn'}
                                    style={{position:'absolute',bottom:100,right:160,}}
                                    onClick={this.ExamMoreBtn.bind(this)}
                            >
                                申请等阶测试
                            </button>
                        </div>
                    }
                    <div className={'PositionBottom'}> </div>
                </div>
                <Footer/>
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default ExamMoreList;
