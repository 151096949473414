import React, { Component } from 'react';
import eCharts from 'echarts/lib/echarts';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/title';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/legend';
import './member.css';

class AnAlyECharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsAnAlyArr:[],
            SelectInfo:{},
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {
        let IsAnAlyArr = nextProps.IsAnAlyArr;
        let SelectInfo = nextProps.SelectInfo;
        this.setState({
            IsAnAlyArr:IsAnAlyArr,
            SelectInfo:SelectInfo,
        });
    };

    componentDidMount=function() {
        let history = this.props;
        let IsAnAlyArr = history.IsAnAlyArr;
        let SelectInfo = history.SelectInfo;
        this.setState({
            IsAnAlyArr:IsAnAlyArr,
            SelectInfo:SelectInfo,
        });
        setTimeout(()=>{
                for(let i=0;i<IsAnAlyArr.length;i++){
                    let myLineChartName = IsAnAlyArr[i].name;
                    let myLineChartArr = IsAnAlyArr[i].data;
                    const myLineCharNumX = [];
                    const crashNum = [];
                    for(let k=0;k<myLineChartArr.length;k++){
                        myLineCharNumX.push('第'+(k+1)+'次');
                        crashNum.push(myLineChartArr[k].percentage);
                    }
                    // 基于准备好的dom，初始化echarts实例
                    let myLineChart = eCharts.init(document.getElementById('FiveEChartsMain'+i),<span> </span>);
                    let LineInfoArr = ['得分率%',];
                    myLineChart.setOption({
                        title: {
                            text: myLineChartName+'折线图',
                            left:'left',
                            subtext: SelectInfo.StartTime+' ~ '+SelectInfo.EndTime,
                            subtextStyle:{
                                color:'#ff0000',
                            },
                        },
                        legend: {
                            data: LineInfoArr,
                            left:'right',
                            selectedMode:false,
                            textStyle:{
                                color:'#000000',
                                fontFamily:'bold',
                                fontSize:14,
                            },
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        tooltip: {
                            trigger: 'axis'
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            data: myLineCharNumX,
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: '{value} %'
                            }
                        },
                        series: [
                            {
                                name: '得分率%',
                                type: 'line',
                                color: '#ff0000',
                                data: crashNum,
                            },
                        ]
                    });
                }
            },
            100
        );
    };




    AllClick = () => {
        this.setState({

        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };





    render() {
        return (
            <div className={'EChartsBox'}>
                {
                    this.state.IsAnAlyArr.map((item,index) =>
                        <div id={'FiveEChartsMain'+index} className={'FiveEChartsMain'} key={index}> </div>
                    )
                }
            </div>
        );
    }
}

export default AnAlyECharts;
