import React, { Component } from 'react';
import './approve.css';
import DevicePage from "../../style/file/DevicePage.zip";
import TrainingCenter from "../../style/file/TrainingCenter.zip";
import AvSchool from "../../style/file/AvSchool.zip";
import DepotBase from "../../style/file/DepotBase.zip";
import Upload from "../round/Upload";
import PopUp from "../round/PopUp";
import {post} from "../../ajax/tools";


class NewApprove extends Component {

    constructor(props){
        super(props);
        this.state = {
            NewApproveStep: 1,
            NewApproveStepName:'填写申请表',
            NewApproveIdt:'',
            DownloadBag:'',

            FileList: [],
            Uploading: false,
            UploadBtnState:'NotShow',

            RecordPageId:'',

            FileName2:'',
            FileName4:'',
        };
    }


    UNSAFE_componentWillReceiveProps= (nextProps) => {

    };

    componentDidMount=function() {
        const history = this.props;
        if(history.NewApproveStep === 1){
            this.setState({
                NewApproveStepName:'填写申请表',
            });
        }else if(history.NewApproveStep === 2){
            this.setState({
                NewApproveStepName:'人工审核',
            });
        }else if(history.NewApproveStep === 3){
            this.setState({
                NewApproveStepName:'审核材料',
            });
        }else if(history.NewApproveStep === 4){
            this.setState({
                NewApproveStepName:'现场审核',
            });
        }else if(history.NewApproveStep === 5){
            this.setState({
                NewApproveStepName:'设备检测',
            });
        }else if(history.NewApproveStep === 6){
            this.setState({
                NewApproveStepName:'专家组审核',
            });
        }else if(history.NewApproveStep === 7){
            this.setState({
                NewApproveStepName:'专家组审核',
            });
        }else if(history.NewApproveStep === 8){
            this.setState({
                NewApproveStepName:'审核完成',
            });
        }
        if(history.NewApproveIdt === '设备'){
            this.setState({
                DownloadBag:DevicePage,
            });
        }else if(history.NewApproveIdt === '训练中心'){
            this.setState({
                DownloadBag:TrainingCenter,
            });
        }else if(history.NewApproveIdt === '青少年航校'){
            this.setState({
                DownloadBag:AvSchool,
            });
        }else if(history.NewApproveIdt === '实训基地'){
            this.setState({
                DownloadBag:DepotBase,
            });
        }
        this.setState({
            NewApproveStep:history.NewApproveStep,
            NewApproveIdt:history.NewApproveIdt,
            RecordPageId:history.RecordPageId,
        });
    };


    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };



    NewAppStepNextBtn=()=>{
        const formData = new FormData();
        let IdtType = this.state.NewApproveIdt;
        let IdtTypeId = null;
        switch(IdtType){
            case '设备':
                IdtTypeId = 1;
                break;
            case '训练中心':
                IdtTypeId = 2;
                break;
            case '青少年航校':
                IdtTypeId = 3;
                break;
            case '实训基地':
                IdtTypeId = 4;
                break;
            default:
                IdtTypeId = null;
        }
        if(this.state.FileList.length !== 0){
            this.setState({Uploading:true,});
            this.state.FileList.forEach((file) => {
                formData.append('file', file);
            });
            post({
                url:'agency/apply_suborg_form_one/'+IdtTypeId,
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        FileList: [],
                        Uploading: false,
                        UploadBtnState:'NotShow',
                        NewApproveStep:2,
                        NewApproveStepName:'人工审核',
                    });
                    this.props.NewApproveNextBtn('NewApproveNext2');
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'上传失败，请稍后重试！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'文件已损坏，请重新上传！',
            });
        }
    };

    NewAppStepNextBtn2=()=>{
        let RecordPageId = this.state.RecordPageId;
        const formData = new FormData();
        if(this.state.FileList.length !== 0){
            this.setState({Uploading:true,});
            this.state.FileList.forEach((file) => {
                formData.append('file', file);
            });
            post({
                url:'agency/apply_suborg_form_two/'+RecordPageId,
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        FileList: [],
                        Uploading: false,
                        FileName2:'',
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'文件识别错误，请重试！',
            });
        }
    };


    NewAppStepNextBtn4=()=>{
        let RecordPageId = this.state.RecordPageId;
        const formData = new FormData();
        if(this.state.FileList.length !== 0){
            this.setState({Uploading:true,});
            this.state.FileList.forEach((file) => {
                formData.append('file', file);
            });
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'正在校验压缩文件...',
            });
            post({
                url:'agency/apply_suborg_four/'+RecordPageId,
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        FileList: [],
                        Uploading: false,
                        FileName4:'',
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'文件识别错误，请重试！',
            });
        }
    };



    NewAppStepNextBtn3=()=>{
        let RecordPageId = this.state.RecordPageId;
        const formData = new FormData();
        if(this.state.FileList.length !== 0){
            this.setState({Uploading:true,});
            this.state.FileList.forEach((file) => {
                formData.append('file', file);
            });
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'正在校验压缩文件...',
            });
            post({
                url:'agency/apply_suborg_thr/'+RecordPageId,
                data:formData,
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        FileList: [],
                        Uploading: false,
                        UploadBtnState:'NotShow',
                        NewApproveStep:4,
                        NewApproveStepName:'现场审核',
                        PopUp:'',
                    });
                    this.props.NewApproveNextBtn('NewApproveNext4');
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'上传失败，请重试！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'文件识别错误，请重试！',
            });
        }
    };




    //查看已认证的信息列表
    BackTableInfo=()=>{
        this.props.BackTableInfo(this.state.NewApproveIdt);
    };



    FileRemove2(){
        this.setState({
            FileList:[],
            Uploading: false,
            FileName2:'',
        });
    };

    FileRemove4(){
        this.setState({
            FileList:[],
            Uploading: false,
            FileName4:'',
        });
    };




    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        const props = {
            UploadState:this.state.UploadBtnState,
            RemoveUpload:(file) => {
                let FileList = this.state.FileList;
                let index = FileList.indexOf(file);
                let NewFileList = FileList.slice();
                NewFileList.splice(index, 1);
                this.setState({
                    FileList:NewFileList,
                    Uploading: false,
                    UploadBtnState:'NotShow',
                });
            },
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = props.BeforeUpload;
                let NewFileList = [];
                if(file.type === 'application/pdf'){
                    if(file.size > 20*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过20MB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileList:NewFileList,
                            Uploading: false,
                            UploadBtnState:'Show',
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '请上传'+this.state.NewApproveIdt+'认证服务申请书的pdf格式文件';
                }
            },
        };



        const propsThree = {
            BeforeUpload:(file) => {
                // console.log(file);
                let Warn = propsThree.BeforeUpload;
                let NewFileList = [];
                if(file.type === 'application/pdf'){
                    if(file.size > 20*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过20MB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileList:NewFileList,
                            Uploading: false,
                            FileName2:file.name,
                            // UploadBtnState:'Show',
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '请上传'+this.state.NewApproveIdt+'认证服务申请书的pdf格式文件';
                }
            },
        };

        const propsFour = {
            BeforeUpload:(file) => {
                // console.log(file);
                let Warn = propsFour.BeforeUpload;
                let NewFileList = [];
                if(file.type === 'application/x-zip-compressed' || file.type === '' || file.type === 'application/zip'){
                    if(file.size > 50*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过50MB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileList:NewFileList,
                            Uploading: false,
                            FileName4:file.name,
                            // UploadBtnState:'Show',
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '请上传zip、rar、7z格式!';
                }
            },
        };


        const propsTwo = {
            UploadState:this.state.UploadBtnState,
            RemoveUpload:(file) => {
                let FileList = this.state.FileList;
                let index = FileList.indexOf(file);
                let NewFileList = FileList.slice();
                NewFileList.splice(index, 1);
                this.setState({
                    FileList:NewFileList,
                    Uploading: false,
                    UploadBtnState:'NotShow',
                });
            },
            BeforeUpload:(file) => {
                //console.log(file);
                let Warn = propsTwo.BeforeUpload;
                let NewFileList = [];
                if(file.type === 'application/x-zip-compressed' || file.type === '' || file.type === 'application/zip'){
                    if(file.size > 50*1024*1024){
                        Warn['WarnMessage'] = '文件大小不能超过50MB!';
                    }else {
                        Warn['WarnMessage'] = '';
                        NewFileList.push(file);
                        this.setState({
                            FileList:NewFileList,
                            Uploading: false,
                            UploadBtnState:'Show',
                        });
                    }
                } else {
                    Warn['WarnMessage'] = '请上传zip、rar、7z格式!';
                }
            },
        };
        const NewAppWorkersInfo =
            <div>
                <div className={'NewAppWorkersInfo'} style={{minWidth:528,}}>韩昱老师 ：13671194098</div>
                <div className={'NewAppWorkersInfo'} style={{minWidth:528,}}>杨旭老师 ：15603712839</div>
            </div>;
        return (
            <div className={'scrollBar'} style={{height:window.innerHeight-270,}}>
                <div className={'ApproveMainBox '}>
                    <div className={'ApproveMainStepTitle'}>{this.state.NewApproveStep === 8 ? 7 : this.state.NewApproveStep === 7 ? 6 : this.state.NewApproveStep}.{this.state.NewApproveStepName}</div>
                    <div className={'ApproveMainStepBox clearfix NewAppStep'+(this.state.NewApproveStep === 8 ? 7 : this.state.NewApproveStep === 7 ? 6 : this.state.NewApproveStep)}>
                        <div className={'NewAppStepListBox'} style={this.state.NewApproveStep >= 1 ? {color:'#FFFFFF',} : {}}>1</div>
                        <div className={'NewAppStepListBox'} style={this.state.NewApproveStep >= 2 ? {color:'#FFFFFF',} : {}}>2</div>
                        <div className={'NewAppStepListBox'} style={this.state.NewApproveStep >= 3 ? {color:'#FFFFFF',} : {}}>3</div>
                        <div className={'NewAppStepListBox'} style={this.state.NewApproveStep >= 4 ? {color:'#FFFFFF',} : {}}>4</div>
                        <div className={'NewAppStepListBox'} style={this.state.NewApproveStep >= 5 ? {color:'#FFFFFF',} : {}}>5</div>
                        <div className={'NewAppStepListBox'} style={this.state.NewApproveStep >= 6 ? {color:'#FFFFFF',} : {}}>6</div>
                        <div className={'NewAppStepListBox'} style={this.state.NewApproveStep >= 8 ? {color:'#FFFFFF',} : {}}>7</div>
                    </div>
                    {
                        this.state.NewApproveStep === 1 &&
                        <div className={'NewApproveStep1ConBox'}>
                            <div className={'NewAppStepDownloadBox'}>
                                认证新的{this.state.NewApproveIdt}所需要的文件压缩包
                                <a className={'NewAppStepDownload clearfix'} href={this.state.DownloadBag} download={this.state.DownloadBag}> </a>
                            </div>
                            <div className={'NewAppStepDownloadWord'}>点击下载文件压缩包</div>
                            <div className={'NewApproveUploadBox'}>
                                <Upload {...props}/>
                                <div className={'ActiveUploadFileBtn'}
                                     style={this.state.UploadBtnState === 'Show' ? {backgroundColor:'#D2D2D2',} : {}}
                                >上传申请书</div>
                            </div>
                            <div className={'UploadFileExplain'}>
                                请将
                                <span style={{color:'#009944',}}>模拟飞行{this.state.NewApproveIdt}认证服务申请书</span>
                                填写
                                <span style={{color:'#009944',}}>盖章</span>
                                后扫描存储为
                                <span style={{color:'#009944',}}>PDF</span>
                                文件上传
                            </div>
                            <button className={'NewAppStepNext'} style={{marginTop:60.}}
                                    onClick={this.state.Uploading === true ? null : this.NewAppStepNextBtn.bind(this)}
                            >下一步</button>
                        </div>
                    }
                    {
                        this.state.NewApproveStep === 2 &&
                        <div className={'ManualReviewBox'}>
                            <div className={'ManualReviewIco'}> </div>
                            <div className={'ManualReviewWordBox'}>
                                <div className={'ManualReviewWord'} style={{textAlign:'left',}}>人工审核中...</div>
                                <div className={'ManualReviewWord'} style={{textAlign:'right',}}>请您耐心等待</div>
                            </div>
                            <div className={'ManualReviewStep'}><div className={'ManualReviewStepHave'}> </div></div>
                            {
                                this.state.RecordPageId !== '' &&
                                <div className={'NewApproveUpDataBox clearfix'} style={{marginBottom:6,}}>
                                    <div className={'NewApproveUpDataWord'}>*如审核不通过，经与我们沟通后，上传新的申请表。</div>
                                    <div className={'NewApproveUpDataIco css-icon icon-upward'} style={{color:'#fff',}}><Upload {...propsThree}/></div>
                                </div>
                            }
                            {
                                this.state.FileName2 !== '' && this.state.RecordPageId !== '' &&
                                <div>
                                    <div className={'NewApproveUpDataBox clearfix'} style={{margin:'0 auto',}}>
                                        <div className={'NewApproveFileName'}>{this.state.FileName2}</div>
                                        <div className={'FileRemove'} onClick={this.FileRemove2.bind(this)}> </div>
                                    </div>
                                    <div className={'NewApproveUpDataBtn'} onClick={this.NewAppStepNextBtn2.bind(this)}>上传</div>
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.NewApproveStep === 3 &&
                        <div className={'NewApproveStep1ConBox'} style={{marginTop:120,}}>
                            <div className={'NewApproveUploadBox'}>
                                <Upload {...propsTwo}/>
                                <div className={'ActiveUploadFileBtn'}
                                     style={this.state.UploadBtnState === 'Show' ? {backgroundColor:'#D2D2D2',} : {}}
                                >上传压缩文件</div>
                            </div>
                            {
                                this.state.NewApproveIdt === '设备'&&
                                <div className={'UploadFileExplain'}>
                                    请将
                                    <span style={{color:'#009944',}}>模拟飞行设备认证服务申请书、模拟飞行设备生产企业及设备过检申请表</span>
                                    等材料
                                    <span style={{color:'#009944',}}>进行压缩后</span>
                                    上传压缩文件以审核
                                </div>
                            }
                            {
                                this.state.NewApproveIdt === '训练中心'&&
                                <div className={'UploadFileExplain'}>
                                    请将
                                    <span style={{color:'#009944',}}>模拟飞行训练机构认证服务申请书、中国航空协会模拟飞行训练机构年审申请表、模拟飞行训练机构承诺书</span>
                                    等材料
                                    <span style={{color:'#009944',}}>进行压缩后</span>
                                    上传压缩文件以审核
                                </div>
                            }
                            {
                                this.state.NewApproveIdt === '青少年航校'&&
                                <div className={'UploadFileExplain'}>
                                    请将
                                    <span style={{color:'#009944',}}>模拟飞行青少年航校认证服务申请书、青少年航校相关资质文件</span>
                                    等材料
                                    <span style={{color:'#009944',}}>进行压缩后</span>
                                    上传压缩文件以审核
                                </div>
                            }
                            {
                                this.state.NewApproveIdt === '实训基地'&&
                                <div className={'UploadFileExplain'}>
                                    请将
                                    <span style={{color:'#009944',}}>模拟飞行实训基地认证服务申请书、实训基地相关资质文件</span>
                                    等材料
                                    <span style={{color:'#009944',}}>进行压缩后</span>
                                    上传压缩文件以审核
                                </div>
                            }
                            <button className={'NewAppStepNext'} style={{marginTop:60.}}
                                    onClick={this.state.Uploading === true ? null : this.NewAppStepNextBtn3.bind(this)}
                            >下一步</button>
                        </div>
                    }
                    {
                        this.state.NewApproveStep === 4 &&
                        <div className={'NewApproveStepExplanationBox'}>
                            <div className={'NewAppExplanationTit'}>请您联系工作人员，以安排审核时间等相关事宜</div>
                            {NewAppWorkersInfo}
                            {
                                this.state.RecordPageId !== '' &&
                                <div className={'NewApproveUpDataBox clearfix'} style={{marginBottom:6,}}>
                                    <div className={'NewApproveUpDataWord'} style={{color:'#0068B7',fontSize:16,}}>*如审核不通过，经与我们沟通后，上传新的相关材料压缩包。</div>
                                    <div className={'NewApproveUpDataIco css-icon icon-upward'} style={{color:'#fff',backgroundColor:'#0068B7',}}><Upload {...propsFour}/></div>
                                </div>
                            }
                            {
                                this.state.FileName4 !== '' && this.state.RecordPageId !== '' &&
                                <div>
                                    <div className={'NewApproveUpDataBox clearfix'} style={{margin:'0 auto',}}>
                                        <div className={'NewApproveFileName'}>{this.state.FileName4}</div>
                                        <div className={'FileRemove'} onClick={this.FileRemove4.bind(this)}> </div>
                                    </div>
                                    <div className={'NewApproveUpDataBtn'} style={{backgroundColor:'#0068B7',}} onClick={this.NewAppStepNextBtn4.bind(this)}>上传</div>
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.NewApproveStep === 5 &&
                        <div className={'NewApproveStepExplanationBox'}>
                            <div className={'NewAppExplanationTit'}>请您联系工作人员，以安排审核时间等相关事宜</div>
                            {NewAppWorkersInfo}
                        </div>
                    }
                    {
                        this.state.NewApproveStep === 6 &&
                        <div className={'NewApproveStepExplanationBox'}>
                            <div className={'NewAppExplanationTit'}>请您联系工作人员，以安排专家组审核等相关事宜</div>
                            {NewAppWorkersInfo}
                        </div>
                    }
                    {
                        this.state.NewApproveStep === 7 &&
                        <div className={'NewApproveStepExplanationBox'}>
                            <div className={'NewAppExplanationTit'}>请您联系工作人员，以安排专家组审核等相关事宜</div>
                            {NewAppWorkersInfo}
                        </div>
                    }
                    {
                        this.state.NewApproveStep === 8 &&
                        <div className={'NewApproveStepEndBox'}>
                            <div className={'NewAppStepEndTitBox clearfix'}>
                                <div className={'NewAppStepEndTitIco'}> </div>
                                <div className={'NewAppStepEndTitWord'}>恭喜您，审核完成</div>
                            </div>
                            <div className={'BackTableInfo'}  onClick={this.BackTableInfo.bind(this)} style={{marginTop:80,}}>查看已认证{this.state.NewApproveIdt}>>></div>
                        </div>
                    }
                </div>
                {
                    this.state.PopUp === '提示弹窗' &&
                    <PopUp
                        ChildBackClick={this.ChildBackTip.bind(this)}
                        type={'提示'}
                        message={this.state.MessageTip}
                        OutButton={'Yes'}
                    />
                }
            </div>
        );
    }
}


export default NewApprove;
