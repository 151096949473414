import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import PopUp from "../round/PopUp";
import './know.css';


class know extends Component {

    constructor(props){
        super(props);
        this.state = {
            NowNavListZhName:'',
            NowNavListEnName:'',
            message:'错误',
        };
    }
    componentDidMount=function() {
        this.setState({
            //PopUp:'提示弹窗',
            MessageTip:'暂未开放',
        });
    };


    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                <div className={'MainBox'}>
                    <Header/>
                    <div className={'MainContent'}>
                        <div className={'MainTopBox clearfix'}>
                            <div className={'MainConTitle'}>
                                <div className={'MainConTitZH'}>{this.state.NowNavListZhName}知识之窗</div>
                                <div className={'MainConTitEN'}>Window&nbsp;&nbsp;of&nbsp;&nbsp;knowledge</div>
                            </div>
                            <div className={'MainConTitBorder'}> </div>
                            <div className={'MainActiveListTitle'}>{this.state.NowNavListEnName}knowledge</div>
                        </div>
                        <div className={'ContentMainBox clearfix'} style={{backgroundColor:'#fff',minHeight:window.innerHeight-194 > 460 ? window.innerHeight-194 :460,}}>
                            {/**/}
                            <div className={'NotYet'} style={{height:window.innerHeight-191,}}> </div>
                        </div>
                        {/*<div className={'PositionBottom'}> </div>*/}
                    </div>
                    <Footer/>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default know;
