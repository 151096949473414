import React, { Component } from 'react';
import './round.css';


class PopUp extends Component {

    constructor(props){
        super(props);
        this.state = {
            Type:'',
            TypeImage:'',
            Message:'',
            OutButton:'',
        };
    }

    UNSAFE_componentWillReceiveProps= (nextProps) => {
        const history = nextProps;
        if(history.type === '成功'){
            this.setState({
                TypeImage:'TypeOk',
            });
        }else if(history.type === '失败'){
            this.setState({
                TypeImage:'TypeFail',
            });
        }else if(history.type === '错误'){
            this.setState({
                TypeImage:'TypeError',
            });
        }else if(history.type === '提示'){
            this.setState({
                TypeImage:'TypeTip',
            });
        }
        this.setState({
            Type:history.type,
            Message:history.message,
            OutButton:history.OutButton,
        });
    };

    componentDidMount=function() {
        const history = this.props;
        if(history.type === '成功'){
            this.setState({
                TypeImage:'TypeOk',
            });
        }else if(history.type === '失败'){
            this.setState({
                TypeImage:'TypeFail',
            });
        }else if(history.type === '错误'){
            this.setState({
                TypeImage:'TypeError',
            });
        }else if(history.type === '提示'){
            this.setState({
                TypeImage:'TypeTip',
            });
        }
        this.setState({
            Type:history.type,
            Message:history.message,
            OutButton:history.OutButton,
        });
    };

    CrossOut=()=>{
        this.props.ChildBackClick('CrossOut');
    };
    Confirm=()=>{
        this.props.ChildBackClick('Confirm');
    };

    render() {
        return (
                <div className={'PopUpBoxBg'}>
                    {
                        this.state.Type === '成功' &&
                        <div className={'PopUpBox'}>
                            <div className={'PopUpTopImage '+this.state.TypeImage}> </div>
                            <div className={'PopUpConBox'}>
                                <div className={'PopUpContentBox'}>
                                    <div className={'ContentBox clearfix'}>
                                        <div className={'ContentWord'}>{this.state.Message}</div>
                                    </div>
                                </div>
                                <div className={'ButtonBox'}>
                                    {
                                        this.state.OutButton === 'No' &&
                                        <button className={'OneBtnOk'} style={{backgroundColor:'#00A0EA',}} onClick={this.Confirm.bind(this)}>确认</button>
                                    }
                                    {
                                        this.state.OutButton === 'Yes' &&
                                        <div>
                                            <button className={'TwoBtnOk'} style={{backgroundColor:'#00A0EA',}} onClick={this.Confirm.bind(this)}>确认</button>
                                            <button className={'TwoBtnOut'} onClick={this.CrossOut.bind(this)}>取消</button>
                                        </div>
                                    }
                                    {
                                        this.state.OutButton === 'PeTeamCreate' &&
                                        <div>
                                            <button className={'TwoBtnOk'} style={{backgroundColor:'#00A0EA',}} onClick={this.Confirm.bind(this)}>继续邀请</button>
                                            <button className={'TwoBtnOut'} onClick={this.CrossOut.bind(this)}>不用了</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.Type === '失败' &&
                        <div className={'PopUpBox'}>
                            <div className={'PopUpTopImage '+this.state.TypeImage}> </div>
                            <div className={'PopUpConBox'}>
                                <div className={'PopUpContentBox'}>
                                    <div className={'ContentBox clearfix'}>
                                        <div className={'ContentWord'}>{this.state.Message}</div>
                                    </div>
                                </div>
                                <div className={'ButtonBox'}>
                                    {
                                        this.state.OutButton === 'No' &&
                                        <button className={'OneBtnOk'} style={{backgroundColor:'#F54746',}} onClick={this.Confirm.bind(this)}>确认</button>
                                    }
                                    {
                                        this.state.OutButton === 'Yes' &&
                                        <div>
                                            <button className={'TwoBtnOk'} style={{backgroundColor:'#F54746',}} onClick={this.Confirm.bind(this)}>确认</button>
                                            <button className={'TwoBtnOut'} onClick={this.CrossOut.bind(this)}>取消</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.Type === '错误' &&
                        <div className={'PopUpBox'}>
                            <div className={'PopUpTopImage '+this.state.TypeImage}>
                                <div className={'CrossBtn'} onClick={this.CrossOut.bind(this)}> </div>
                            </div>
                            <div className={'PopUpConBox'}>
                                <div className={'PopUpContentBox'}>
                                    <div className={'ErrorConBox clearfix'} style={{marginTop:36,}}>
                                        <div className={'ErrorIco'}> </div>
                                        <div className={'ErrorWord'}>{this.state.Message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.Type === '提示' &&
                        <div className={'PopUpBox'}>
                            <div className={'PopUpTopImage '+this.state.TypeImage}>
                                <div className={'CrossBtn'} onClick={this.CrossOut.bind(this)}> </div>
                            </div>
                            <div className={'PopUpConBox'}>
                                <div className={'PopUpContentBox'}>
                                    <div className={'ContentBox clearfix'} style={{marginTop:36,}}>
                                        <div className={'ContentWord'} style={{color:'#F54746',textAlign:'center',lineHeight:'32px',}}>{this.state.Message}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
        );
    }
}


export default PopUp;