import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';

import Home from '../components/home/Home';


import New from '../components/news/New';
import NewsInfo from '../components/news/NewsInfo';


import Game from '../components/game/Game';
import GYInfo from '../components/game/GYInfo';


import Know from '../components/know/Know';




import Download from '../components/download/Download';


import School from '../components/school/school';



import Login from '../components/auth/Login';
import Forget from '../components/auth/Forget';
import Register from '../components/auth/Register';




// import Member from '../components/member/Member';
import MemberJump from '../components/member/MemberJump';
import MemberGr from '../components/member/MemberGr';
import MemberPer from '../components/member/MemberPer';
import OldRealName from '../components/member/OldRealName';



import Inquire from '../components/inquire/Inquire';


import Msg from '../components/Msg/Msg';



import Approve from '../components/approve/Approve';




import Exam from '../components/exam/Exam';
import ExamMoreList from '../components/exam/ExamMoreList';


import Ask from '../components/ask/Ask';
import ActiveAsk from '../components/ask/ActiveAsk';
import InternetAsk from '../components/ask/InternetAsk';


import Agreement from '../components/showfile/Agreement';
import Certificate from '../components/showfile/Certificate';
import Award from '../components/showfile/Award';
import AboutMyself from '../components/showfile/AboutMyself';
import FriendLink from '../components/showfile/FriendLink';
import AboutMine from '../components/showfile/AboutMine';
import AddMine from '../components/showfile/AddMine';

import JumpPage from '../components/jump/JumpPage';


import NotFound from "../components/NotFound/NotFound"

export default class AdminRouter extends Component {
    tokenAuth = (component) => {
        let tokenAuth = JSON.parse(sessionStorage.getItem('tokenAuth'));
        if (!tokenAuth) return <Redirect to={'/login'} />;
        if (!tokenAuth.KeyToken) return <Redirect to={'/login'} />;
        if (new Date().getTime() - tokenAuth.time > 0) {
            return <Redirect to={'/login'} />;
        }
        return component;
    };
    tokenJump = (component) => {
        return component;
    };
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route path="/" exact render={() => <Redirect to="/home" push />} />

                        {/*<Route path="/" exact render={() => <Redirect to="/" push />} />*/}

                        <Route path="/home" component={Home} />


                        <Route path="/news" component={New} />
                        <Route path="/newsInfo" component={NewsInfo} />




                        <Route path="/game" component={Game} />
                        <Route path="/GYInfo" component={GYInfo} />





                        <Route path="/know" component={Know} />




                        <Route path="/download" component={Download} />
                        <Route path="/avSchool" component={School} />




                        <Route path="/login" component={Login} />
                        <Route path="/forget" component={Forget} />
                        <Route path="/register" component={Register} />




                        <Route path="/member" component={(props) => this.tokenAuth(<MemberJump {...props} />)} />
                        <Route path="/real_name" component={(props) => this.tokenAuth(<OldRealName {...props} />)} />

                        <Route path="/member_peBase" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_pestu" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_peteam" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_peda" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_pezz" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_peyc" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_pevip" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_petea" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_pecou" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />
                        <Route path="/member_pesix" component={(props) => this.tokenAuth(<MemberPer {...props} />)} />

                        <Route path="/member_grBase" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_grGame" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_grTJF" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_gryc" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_grtr" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_grschool" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_grtrba" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_grdiv" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_grryq" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_grsix" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_service" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_gryc_service" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />
                        <Route path="/member_peyc_service" component={(props) => this.tokenAuth(<MemberGr {...props} />)} />





                        <Route path="/message" component={(props) => this.tokenAuth(<Msg {...props} />)} />





                        {/*信息查询*/}
                        <Route path="/inquire_s" component={(props) => this.tokenJump(<Inquire {...props} />)} />
                        <Route path="/inquire_d" component={(props) => this.tokenJump(<Inquire {...props} />)} />
                        <Route path="/inquire_t" component={(props) => this.tokenJump(<Inquire {...props} />)} />
                        <Route path="/inquire_x" component={(props) => this.tokenJump(<Inquire {...props} />)} />
                        <Route path="/inquire_j" component={(props) => this.tokenJump(<Inquire {...props} />)} />



                        {/*<Route path="/examMore" component={ExamMoreList} />*/}
                        {/*能力测试*/}
                        <Route path="/examMore/:examMoreId" component={ExamMoreList} />
                        <Route path="/exam_p" component={(props) => this.tokenAuth(<Exam {...props} />)} />
                        <Route path="/exam_g" component={(props) => this.tokenAuth(<Exam {...props} />)} />
                        <Route path="/exam_r" component={(props) => this.tokenAuth(<Exam {...props} />)} />


                        {/*<Route path="/approve" component={Approve} />*/}
                        {/*申请服务*/}
                        <Route path="/approve_s" component={(props) => this.tokenAuth(<Approve {...props} />)} />
                        <Route path="/approve_t" component={(props) => this.tokenAuth(<Approve {...props} />)} />
                        <Route path="/approve_d" component={(props) => this.tokenAuth(<Approve {...props} />)} />
                        <Route path="/approve_x" component={(props) => this.tokenAuth(<Approve {...props} />)} />






                        <Route path="/ask/:askId" component={Ask} />
                        <Route path="/activeAsk" component={ActiveAsk} />
                        <Route path="/internetAsk/:askId" component={InternetAsk} />


                        <Route path="/game_review_2022" component={JumpPage} />


                        <Route path="/agreement" component={Agreement} />
                        <Route path="/certificate/:diplomaCode" component={Certificate} />
                        <Route path="/award/:awardUrl" component={Award} />
                        <Route path="/aboutMyself" component={AboutMyself} />
                        <Route path="/friendLink" component={FriendLink} />
                        <Route path="/aboutMine" component={AboutMine} />
                        <Route path="/addMine" component={AddMine} />



                        <Route path="/NotFound" component={NotFound} />
                        <Redirect to="/NotFound" />
                    </Switch>
                </ScrollToTop>
            </Router>
        )
    }
}
