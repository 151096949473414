import React,{ Component } from 'react';
import 'babel-polyfill';
import './App.css';
import Routes from './routes';

class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            Stop:'',
        };
    }


    componentDidMount=function() {
        let OpenWeb = sessionStorage.getItem('OpenWeb');
        let pathname = window.location.pathname.substring(1);
        if (OpenWeb !== null){
            if (pathname === ''){
                sessionStorage.removeItem('OpenWeb');
                this.setState({
                    Stop:'Yes',
                });
            }else {
                this.setState({
                    Stop:'No',
                });
            }
        }else {
            sessionStorage.removeItem('OpenWeb');
            this.setState({
                Stop:'Yes',
            });
        }
    };
    componentWillUnmount=function() {
        sessionStorage.removeItem('OpenWeb');
    };
    BackTopClick=()=>{
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
        });
    };

    OpenWeb(){
        sessionStorage.setItem('OpenWeb','742693');
        this.setState({
            Stop:'No',
            //Stop:'Yes',
        });
    };




    render() {
        const auth = this.props;
        return (
            <div className={'App'}>
                {/*<div>*/}
                {/*    {*/}
                {/*        this.state.Stop === 'Yes'&&*/}
                {/*        <div className={'Maintain'}>*/}
                {/*            <div className={'OpenWebBox'} onClick={this.OpenWeb.bind(this)}> </div>*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*    {*/}
                {/*        this.state.Stop === 'No'&&*/}
                {/*        <Routes auth={auth}/>*/}
                {/*    }*/}
                {/*</div>*/}
                {/*<div className={'Maintain'}>*/}
                {/*    <div className={'OpenWebBox'}> </div>*/}
                {/*</div>*/}
                <Routes auth={auth}/>
                {/*<div className={'BackTop'} onClick={this.BackTopClick.bind(this)}> </div>*/}
            </div>
        );
    }
}

export default App;
