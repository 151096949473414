import React, { Component } from 'react';
//import {API_URL} from "../../ajax/config";
import md5 from 'md5'
import AuthHeader from "../handf/AuthHeader";
import ReStep from "../round/ReStep";
import Triangle from "../round/Triangle";
import PopUp from "../round/PopUp";
import ThreeLevelLinkage from "../round/ThreeLevelLinkage";
import Second from "../round/Second";
import './auth.css';
import {get,post} from "../../ajax/tools";
import Footer from "../handf/Footer";


class Register extends Component {

    constructor(props){
        super(props);
        this.state = {
            PopUp:'',//打开哪一个弹窗
            MessageTip:'',//提示提醒统一弹窗文字信息
            winHeight: '',
            RePeStyle:{backgroundColor:'#FFFFFF',color:'#000000',},
            ReComStyle:{},
            ReFormat:'个人身份证注册',//注册的形式（个人身份证，个人其他证件，团体注册）
            //ReFormat:'个人其他证件注册',//注册的形式（个人身份证，个人其他证件，团体注册）
            //ReFormat:'团体身份注册',//注册的形式（个人身份证，个人其他证件，团体注册）
            ReStepNum:1,//注册的步骤数
            //注册第一步
            RealName:'',//真实姓名或管理员姓名
            CardType:'证件类型',//证件类型
            IdCard:'',//身份证号
            Mobile:'',//手机号
            VrCode:'',//手机验证码
            Email:'',//邮箱
            Birthday:'',//生日
            CardTypeToggleOn: true, //证件类型下拉菜单
            AgreeToggleOn: true, //服务协议同意点击
            CardTypeDisplay: 'none',//证件类型下拉菜单
            AgreeDisplay: 'none',//服务协议同意点击
            IdProvince:'',//身份证获取省
            IdCity:'',//身份证获取省
            IdPrefecture:'',//身份证获取区
            IdArea:'',//身份证获取地址
            VrCodeErrmsg:'',//验证码获取信息
            //注册第二步
            UserName:'',//账号用户名
            ThreeLevelLinkage:'',//三级联动下拉菜单的显隐
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            Address:'',//详细地址
            ShProvince: '',//当时学校的三级联动时
            ShCity: '',
            ShArea: '',
            SchoolArr:[],//获取学校数组
            SchoolName:'所属学校名称',//详细学校
            SchoolId:'',//详细学校ID
            SchoolToggleOn: true,
            SchoolDisplay: 'none',
            OtherCardType:'',//其他证件类型
            OtherCard:'',//其他证件号
            ManStyle:'SexBoxIcoNot',//选择男性的默认样式
            WomanStyle:'SexBoxIcoNot',//选择女别的默认样式
            Sex:'',//性别
            Year:'',//选择出生年
            Month:'',//选择出生月
            Day:'',//选择出生日
            GroupNature:'',//团体性质
            GroupNatureToggleOn: true, //团体性质下拉菜单
            GroupNatureDisplay: 'none',//团体性质点击
            GroupNatureArr:['学校','企业','青少年活动中心','地方航空运动协会',],//团体性质选项
            GroupName:'',//团体全称
            UnifiedCode:'',//统一社会代码
            GroupReAddress:'',//团体注册地址
            LandLine:'',//团体联系方式可以是座机
            GroupEmail:'',//团体邮箱
            KeyId:'',//数据库返回的存储id键
            //注册第三步
            SetupPassword:'',//设置密码
            SetupPasswordRepeat:'',//重复设置的密码
            ReSuccessId:'',//注册成功分配的用户Id
        };
    }
    componentDidMount=function() {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeightwinHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }
        document.onclick=this.AllClick.bind(this);//下拉菜单监听隐藏
    };

    AllClick = () => {
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }
        this.setState({
            ThreeLevelLinkage: 'none',
            CardTypeToggleOn: true,
            CardTypeDisplay: 'none',
            GroupNatureToggleOn: true,
            GroupNatureDisplay: 'none',
            SchoolToggleOn: true,
            SchoolDisplay: 'none',
        });
    };

    componentWillUnmount = () => {
        this.setState = ()=>{
        };
    };


    //点击选择个人注册
    RePeStyleClick(){
        this.setState({
            RePeStyle:{backgroundColor:'#FFFFFF',color:'#000000',},
            ReComStyle:{},
            ReFormat:'个人身份证注册',
            ReStepNum:1,
            //切换注册类型需要清空
            AgreeToggleOn: true, //服务协议
            AgreeDisplay: 'none',//服务协议
            RealName:'',//真实姓名或管理员姓名
            CardType:'证件类型',//证件类型
            IdCard:'',//身份证号
            Mobile:'',//手机号
            VrCode:'',//手机验证码
            Email:'',//邮箱
            CardTypeToggleOn: true, //证件类型下拉菜单
            CardTypeDisplay: 'none',//证件类型下拉菜单
            //注册第二步
            UserName:'',//账号用户名
            ThreeLevelLinkage:'',//三级联动下拉菜单的显隐
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            Address:'',//详细地址
            ShProvince: '',//当时学校的三级联动时
            ShCity: '',
            ShArea: '',
            School:'',//详细学校
            OtherCardType:'',//其他证件类型
            OtherCard:'',//其他证件号
            ManStyle:'SexBoxIcoNot',//选择男性的默认样式
            WomanStyle:'SexBoxIcoNot',//选择女别的默认样式
            Sex:'',//性别
            Year:'',//选择出生年
            Month:'',//选择出生月
            Day:'',//选择出生日
            GroupNature:'',//团体性质
            GroupNatureToggleOn: true, //团体性质下拉菜单
            GroupNatureDisplay: 'none',//团体性质点击
            GroupName:'',//团体全称
            UnifiedCode:'',//统一社会代码
            GroupReAddress:'',//团体注册地址
            LandLine:'',//团体联系方式可以是座机
            GroupEmail:'',//团体邮箱
            //注册第三步
            SetupPassword:'',//设置密码
            SetupPasswordRepeat:'',//重复设置的密码
        });
    };
    //点击选择团体注册
    ReComStyleClick(){
        this.setState({
            RePeStyle:{},
            ReComStyle:{backgroundColor:'#FFFFFF',color:'#000000',},
            ReFormat:'团体身份注册',
            ReStepNum:1,
            //切换注册类型需要清空
            AgreeToggleOn: true, //服务协议
            AgreeDisplay: 'none',//服务协议
            RealName:'',//真实姓名或管理员姓名
            CardType:'证件类型',//证件类型
            IdCard:'',//身份证号
            Mobile:'',//手机号
            VrCode:'',//手机验证码
            Email:'',//邮箱
            CardTypeToggleOn: true, //证件类型下拉菜单
            CardTypeDisplay: 'none',//证件类型下拉菜单
            //注册第二步
            UserName:'',//账号用户名
            ThreeLevelLinkage:'',//三级联动下拉菜单的显隐
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            Address:'',//详细地址
            ShProvince: '',//当时学校的三级联动时
            ShCity: '',
            ShArea: '',
            School:'',//详细学校
            OtherCardType:'',//其他证件类型
            OtherCard:'',//其他证件号
            ManStyle:'SexBoxIcoNot',//选择男性的默认样式
            WomanStyle:'SexBoxIcoNot',//选择女别的默认样式
            Sex:'',//性别
            Year:'',//选择出生年
            Month:'',//选择出生月
            Day:'',//选择出生日
            GroupNature:'',//团体性质
            GroupNatureToggleOn: true, //团体性质下拉菜单
            GroupNatureDisplay: 'none',//团体性质点击
            GroupName:'',//团体全称
            UnifiedCode:'',//统一社会代码
            GroupReAddress:'',//团体注册地址
            LandLine:'',//团体联系方式可以是座机
            GroupEmail:'',//团体邮箱
            //注册第三步
            SetupPassword:'',//设置密码
            SetupPasswordRepeat:'',//重复设置的密码
        });
    };



    //服务协议同意点击
    AgreeClick(){
        this.setState(prevState => ({
            AgreeToggleOn: !prevState.AgreeToggleOn,
            AgreeDisplay:prevState.AgreeToggleOn ? 'block' : 'none',
        }));
    };
    //点击展开证件类型选择
    CardTypeClick(e){
        e.nativeEvent.stopImmediatePropagation();//证件类型下拉菜单监听隐藏
        this.setState(prevState => ({
            CardTypeToggleOn: !prevState.CardTypeToggleOn,
            CardTypeDisplay:prevState.CardTypeToggleOn ? 'block' : 'none',
        }));
    };
    //点击选择身份证时
    IdCardTypeClick(){
        this.setState({
            CardType:'身份证',
            ReFormat:'个人身份证注册',
            //清空缓存
            IdCard:'',//身份证号
            Mobile:'',//手机号
            VrCode:'',//手机验证码
            Email:'',//邮箱
            //注册第二步
            UserName:'',//账号用户名
            ThreeLevelLinkage:'',//三级联动下拉菜单的显隐
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            Address:'',//详细地址
            ShProvince: '',//当时学校的三级联动时
            ShCity: '',
            ShArea: '',
            School:'',//详细学校
            OtherCardType:'',//其他证件类型
            OtherCard:'',//其他证件号
            ManStyle:'SexBoxIcoNot',//选择男性的默认样式
            WomanStyle:'SexBoxIcoNot',//选择女别的默认样式
            Sex:'',//性别
            Year:'',//选择出生年
            Month:'',//选择出生月
            Day:'',//选择出生日
            GroupNature:'',//团体性质
            GroupNatureToggleOn: true, //团体性质下拉菜单
            GroupNatureDisplay: 'none',//团体性质点击
            GroupName:'',//团体全称
            UnifiedCode:'',//统一社会代码
            GroupReAddress:'',//团体注册地址
            LandLine:'',//团体联系方式可以是座机
            GroupEmail:'',//团体邮箱
            //注册第三步
            SetupPassword:'',//设置密码
            SetupPasswordRepeat:'',//重复设置的密码
        });
    };
    //点击选择其他证件时
    OtherCardTypeClick(){
        this.setState({
            CardType:'其它证件',
            ReFormat:'个人其他证件注册',
            PopUp:'选择以其他证件注册时',
            //清空缓存
            IdCard:'',//身份证号
            Mobile:'',//手机号
            VrCode:'',//手机验证码
            Email:'',//邮箱
            //注册第二步
            UserName:'',//账号用户名
            ThreeLevelLinkage:'',//三级联动下拉菜单的显隐
            AdProvince:'',//当时地址的三级联动时
            AdCity:'',
            AdArea:'',
            Address:'',//详细地址
            ShProvince: '',//当时学校的三级联动时
            ShCity: '',
            ShArea: '',
            School:'',//详细学校
            OtherCardType:'',//其他证件类型
            OtherCard:'',//其他证件号
            ManStyle:'SexBoxIcoNot',//选择男性的默认样式
            WomanStyle:'SexBoxIcoNot',//选择女别的默认样式
            Sex:'',//性别
            Year:'',//选择出生年
            Month:'',//选择出生月
            Day:'',//选择出生日
            GroupNature:'',//团体性质
            GroupNatureToggleOn: true, //团体性质下拉菜单
            GroupNatureDisplay: 'none',//团体性质点击
            GroupName:'',//团体全称
            UnifiedCode:'',//统一社会代码
            GroupReAddress:'',//团体注册地址
            LandLine:'',//团体联系方式可以是座机
            GroupEmail:'',//团体邮箱
            //注册第三步
            SetupPassword:'',//设置密码
            SetupPasswordRepeat:'',//重复设置的密码
        });
    };


    //点击获取所属学校
    SchoolClick(e){
        e.nativeEvent.stopImmediatePropagation();
        if(this.state.ShProvince !== '' && this.state.ShCity !== '' && this.state.ShArea !== '' ){
            post({
                url:'reuse/school',
                data:{
                    province:this.state.ShProvince,
                    city:this.state.ShCity,
                    county:this.state.ShArea,
                },
            }).then((res) => {
                if(res.errno === '200' && res.data.length !== 0){
                    const schools = [];
                    for(let i=0;i<res.data.length;i++){
                        const schoolsList = res.data[i];
                        schools.push(schoolsList)
                    }
                    this.setState(prevState => ({
                        SchoolToggleOn: !prevState.SchoolToggleOn,
                        SchoolDisplay:prevState.SchoolToggleOn ? 'block' : 'none',
                        ThreeLevelLinkage: 'none',
                        SchoolArr:schools,
                    }));
                }else  if(res.errno === '200' && res.data.length === 0){
                    this.setState({
                        PopUp:'日常提示同一弹窗',
                        MessageTip:'无学校信息，无需选择！',
                        ThreeLevelLinkage: 'none',
                    });
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:'网络错误，请稍后再试！',
                        ThreeLevelLinkage: 'none',
                    });
                }
            });
        }else {
            this.setState({
                SchoolToggleOn: true,
                SchoolDisplay: 'none',
            });
        }
    };
    schoolArrClick=(event,index) => {
        this.setState({
            SchoolName:event.org_name,
            SchoolId:event.user_id,
        });
    };


    //点击团体性质时
    GroupNatureClick(e){
        e.nativeEvent.stopImmediatePropagation();
        this.setState(prevState => ({
            GroupNatureToggleOn: !prevState.GroupNatureToggleOn,
            GroupNatureDisplay:prevState.GroupNatureToggleOn ? 'block' : 'none',
        }));
    };
    //选择团体的性质点击
    GroupNatureArrClick=(event,index) => {
        this.setState({
            GroupNature:event,
        });
    };


    //点击获取验证码
    ReVrCodeBtnClick(){
        post({
            url:'passport/prefix_sms_check',
            data:{
                mobile:this.state.Mobile,
            },
        }).then((res) => {
            if(res.errno === '4002'){
                post({
                    url:'passport/sms_code',
                    data:{
                        mobile:this.state.Mobile,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            VrCodeErrmsg:res.errmsg,
                        });
                    }else {
                        this.setState({
                            PopUp:'错误提示同一弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else if(res.errno === '200'){
                this.setState({
                    PopUp:'短信验证码重复使用提示',
                    MessageTip:'您的此手机号的验证码还未过期，是否重新获取？',
                });
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:res.errmsg,
                });
            }
        });
    };
    SecondBackChange=(event)=>{
        if(event === 'Stop'){
            this.setState({
                VrCodeErrmsg:'',
            });
        }
    };


    //点击选择性别男
    SexManClick(){
        this.setState({
            ManStyle:'SexBoxIcoHave',//选择男性的默认样式
            WomanStyle:'SexBoxIcoNot',//选择女别的默认样式
            Sex:'男',//选择性别
        });
    };
    //点击选择性别女
    SexWomanClick(){
        this.setState({
            ManStyle:'SexBoxIcoNot',//选择男性的默认样式
            WomanStyle:'SexBoxIcoHave',//选择女别的默认样式
            Sex:'女',//选择性别
        });
    };




    //真实姓名或管理员姓名
    RealNameChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            RealName: event.target.value,
        });
    };
    //用户名昵称
    UserNameChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            UserName: event.target.value,
        });
    };
    //身份证号
    IdCardChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            IdCard: event.target.value,
        });
    };
    //手机号
    MobileChange(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '').replace(/^0/g,'');
        this.setState({
            Mobile: event.target.value,
        });
    };
    //验证码
    VrCodeChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '');
        this.setState({
            VrCode: event.target.value,
        });
    };
    //邮箱
    EmailChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            Email: event.target.value,
        });
    };
    //详细地址
    AddressChange(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            Address: event.target.value,
        });
    };
    //其他证件类型
    OtherCardTypeChange(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            OtherCardType: event.target.value,
        });
    };
    //其他证件号
    OtherCardChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            OtherCard: event.target.value,
        });
    };
    //团体全称
    GroupNameChange(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            GroupName: event.target.value,
        });
    };
    //统一社会代码
    UnifiedCodeChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            UnifiedCode: event.target.value,
        });
    };
    //团体注册地址
    GroupReAddressChange(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            GroupReAddress: event.target.value,
        });
    };
    //团体联系方式可以是座机
    LandLineChange(event){
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        this.setState({
            LandLine: event.target.value,
        });
    };
    //团体邮箱
    GroupEmailChange(event){
        event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            GroupEmail: event.target.value,
        });
    };

    //设置密码
    SetupPasswordChange(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            SetupPassword: event.target.value,
        });
    };
    //确认密码
    SetupPasswordRepeatChange(event){
        //event.target.value = event.target.value.replace(/[\u4E00-\u9FA5]/g , '');
        this.setState({
            SetupPasswordRepeat: event.target.value,
        });
    };




    //三级联动的逻辑处理
    ChildLinkClick=(event)=>{
        if(event.Type === '地址'){
            if(event.Title === '省'){
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }else if(event.Title === '市'){
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }else if(event.Title === '区'){
                this.setState({
                    AdProvince: event.ValueP,
                    AdCity: event.ValueC,
                    AdArea: event.ValueA,
                });
            }
        }else if(event.Type === '学校'){
            if(event.Title === '省'){
                this.setState({
                    ShProvince: event.ValueP,
                    ShCity: event.ValueC,
                    ShArea: event.ValueA,
                    SchoolName:'所属学校名称',
                    SchoolId:'',
                });
            }else if(event.Title === '市'){
                this.setState({
                    ShProvince: event.ValueP,
                    ShCity: event.ValueC,
                    ShArea: event.ValueA,
                    SchoolName:'所属学校名称',
                    SchoolId:'',
                });
            }else if(event.Title === '区'){
                this.setState({
                    ShProvince: event.ValueP,
                    ShCity: event.ValueC,
                    ShArea: event.ValueA,
                    SchoolName:'所属学校名称',
                    SchoolId:'',
                });
            }
        }else if(event.Type === '出生日期'){
            if(event.Title === '年'){
                this.setState({
                    Year: event.ValueY,
                    Month: event.ValueM,
                    Day: event.ValueD,
                });
            }else if(event.Title === '月'){
                this.setState({
                    Year: event.ValueY,
                    Month: event.ValueM,
                    Day: event.ValueD,
                });
            }else if(event.Title === '日'){
                this.setState({
                    Year: event.ValueY,
                    Month: event.ValueM,
                    Day: event.ValueD,
                });
            }
        }
    };




    ChildBackClick1=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.AgreeDisplay === 'block'){
                if(this.state.RealName !== ''){
                    this.setState({
                        ReStepNum:2,
                        PopUp:'',
                    });
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:'您有必要信息没有填写！',
                    });
                }
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:'请阅读服务协议并同意！',
                });
            }
        }
    }


    //弹窗点击的逻辑处理
    ChildBackClick=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            if(this.state.ReStepNum === 1 && this.state.ReFormat === '个人其他证件注册' && this.state.CardType ==='其它证件'){
                // if(this.state.AgreeDisplay === 'block'){
                //     if(this.state.RealName !== ''){
                //         this.setState({
                //             ReStepNum:2,
                //             PopUp:'',
                //         });
                //     }else {
                //         this.setState({
                //             PopUp:'错误提示同一弹窗',
                //             MessageTip:'您有必要信息没有填写！',
                //         });
                //     }
                // }else {
                //     this.setState({
                //         PopUp:'错误提示同一弹窗',
                //         MessageTip:'请阅读服务协议并同意！',
                //     });
                // }
                this.setState({
                    PopUp:'',
                });
            }else if(this.state.PopUp === '短信验证码重复使用提示'){
                post({
                    url:'passport/sms_code',
                    data:{
                        mobile:this.state.Mobile,
                    },
                }).then((res) => {
                    if(res.errno === '200'){
                        this.setState({
                            PopUp:'',
                            VrCodeErrmsg:res.errmsg,
                        });
                    }else {
                        this.setState({
                            PopUp:'错误提示同一弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'',
                });
            }
        }
    };




    //注册第一步的下一步按钮
    StepOneBtnClick(){
        if(this.state.AgreeDisplay === 'block'){
            if(this.state.ReFormat === '个人身份证注册'){
                if(this.state.RealName !== '' && this.state.IdCard !== ''){
                    this.setState({
                        PopUp:'日常提示同一弹窗',
                        MessageTip:'身份实名验证中，请稍后...',
                    });
                    get({url:"passport/idcard_check?idcard="+this.state.IdCard+"&name="+this.state.RealName}).then(res => {
                        if(res.status === '01'){
                            this.setState({
                                Sex:res.sex,
                                Birthday:res.birthday,
                                IdProvince:res.province,
                                IdCity:res.city,
                                IdPrefecture:res.prefecture,
                                IdArea:res.area,
                                ReStepNum:2,
                                PopUp:'',
                            });
                        }else{
                            this.setState({
                                PopUp:'错误提示同一弹窗',
                                MessageTip:res.msg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:'您有必要信息没有填写！',
                    });
                }
            }else if(this.state.ReFormat === '个人其他证件注册'){
                if(this.state.RealName !== ''){
                    this.setState({
                        PopUp:'选择以其他证件注册时下一步',
                    });
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:'您有必要信息没有填写！',
                    });
                }
            }else if(this.state.ReFormat === '团体身份注册'){
                if(this.state.RealName !== '' && this.state.Mobile !== '' && this.state.VrCode !== '' && this.state.Email !== ''){
                    post({
                        url:'passport/sms_check',
                        data:{
                            mobile:this.state.Mobile,
                            smscode:this.state.VrCode,
                        },
                    }).then((res) => {
                        if(res.errno === '200'){
                            post({
                                url:'/passport/check_mobile',
                                data:{
                                    mobile:this.state.Mobile,
                                },
                            }).then((res) => {
                                if(res.errno === '4002'){
                                    this.setState({
                                        ReStepNum:2,
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'错误提示同一弹窗',
                                        MessageTip:res.errmsg,
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'错误提示同一弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:'您有必要信息没有填写！',
                    });
                }
            }
        }else {
            this.setState({
                PopUp:'错误提示同一弹窗',
                MessageTip:'请阅读服务协议并同意！',
            });
        }
    }
    //注册第二步的下一步按钮
    StepTwoBtnClick(){
        if(this.state.ReFormat === '个人身份证注册'){
            if(this.state.UserName !== '' &&
                this.state.AdCity !== '' &&
                this.state.AdArea !== '' &&
                this.state.Address !== '' &&
                this.state.AdProvince !== '' &&
                this.state.Mobile !== '' &&
                this.state.VrCode !== ''){
                post({
                    url:'/passport/check_user',
                    data:{
                        username:this.state.UserName,
                    },
                }).then((res) => {
                    if(res.errno === '4002'){
                        post({
                            url:'passport/sms_check',
                            data:{
                                mobile:this.state.Mobile,
                                smscode:this.state.VrCode,
                            },
                        }).then((res) => {
                            if(res.errno === '200'){
                                this.setState({
                                    ReStepNum:3,
                                });
                            }else {
                                this.setState({
                                    PopUp:'错误提示同一弹窗',
                                    MessageTip:res.errmsg,
                                });
                            }
                        });
                    }else {
                        this.setState({
                            PopUp:'错误提示同一弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:'您有必要信息没有填写！',
                });
            }
        }else if(this.state.ReFormat === '个人其他证件注册'){
            if(this.state.UserName !== '' &&
                this.state.AdProvince !== '' &&
                this.state.AdCity !== '' &&
                this.state.AdArea !== '' &&
                this.state.Address !== '' &&
                this.state.Mobile !== '' &&
                this.state.VrCode !== '' &&
                this.state.OtherCardType !== '' &&
                this.state.OtherCard !== '' &&
                this.state.Sex !== ''&&
                this.state.Year !== ''&&
                this.state.Month !== ''&&
                this.state.Day !== ''){
                if (this.state.OtherCardType !== '身份证' ){
                    post({
                        url:'/passport/check_user',
                        data:{
                            username:this.state.UserName,
                        },
                    }).then((res) => {
                        if(res.errno === '4002'){
                            post({
                                url:'passport/sms_check',
                                data:{
                                    mobile:this.state.Mobile,
                                    smscode:this.state.VrCode,
                                },
                            }).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        ReStepNum:3,
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'错误提示同一弹窗',
                                        MessageTip:res.errmsg,
                                    });
                                }
                            });
                        }else {
                            this.setState({
                                PopUp:'错误提示同一弹窗',
                                MessageTip:res.errmsg,
                            });
                        }
                    });
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:'您选择的是其他证件注册，证件类型不能为身份证;请返回第一步选择身份证认证。',
                    });
                }
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:'您有必要信息没有填写！',
                });
            }
        }else if(this.state.ReFormat === '团体身份注册'){
            if(this.state.UserName !== '' &&
                this.state.AdProvince !== '' &&
                this.state.AdCity !== '' &&
                this.state.AdArea !== '' &&
                this.state.Address !== '' &&
                this.state.GroupName !== '' &&
                this.state.GroupNature !== '' &&
                this.state.GroupReAddress !== '' &&
                this.state.LandLine !== '' &&
                this.state.GroupEmail !== ''){
                post({
                    url:'/passport/check_user',
                    data:{
                        username:this.state.UserName,
                    },
                }).then((res) => {
                    if(res.errno === '4002'){
                        this.setState({
                            ReStepNum:3,
                        });
                    }else {
                        this.setState({
                            PopUp:'错误提示同一弹窗',
                            MessageTip:res.errmsg,
                        });
                    }
                });
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:'您有必要信息没有填写！',
                });
            }
        }
    };
    //注册第三步的下一步按钮
    StepThreeBtnClick= () => {
        let a = this.state.SetupPassword;
        let b = this.state.SetupPasswordRepeat;
        // let regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        let regex = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,32}$/;
        let sb = regex.test(a)
        if(a !== '' && b !== ''){
            if(a === b){
                if(a.length >= 8  && b.length >= 8){
                    if (sb === true){
                        const SetupPassword = md5(a);
                        let Birthday = this.state.Year+'-'+this.state.Month+'-'+this.state.Day;
                        if(this.state.ReFormat === '个人身份证注册' || this.state.ReFormat === '个人其他证件注册'){
                            post({
                                url:'passport/per_register',
                                data:{
                                    username:this.state.UserName,
                                    mobile:this.state.Mobile,
                                    real_name:this.state.RealName,
                                    email:this.state.Email,
                                    sex:this.state.Sex,
                                    province:this.state.AdProvince,
                                    city:this.state.AdCity,
                                    county:this.state.AdArea,
                                    address:this.state.Address,
                                    birth:this.state.Birthday === '' ? Birthday : this.state.Birthday,
                                    birth_province:this.state.IdProvince,
                                    birth_city:this.state.IdCity,
                                    birth_addr:this.state.IdArea,
                                    idcard:this.state.IdCard,
                                    school:this.state.SchoolName === '所属学校名称' ? '' : this.state.SchoolName,
                                    schoolID:this.state.SchoolId,
                                    type: this.state.ReFormat === '个人身份证注册' ? 1 :(this.state.ReFormat === '个人其他证件注册' ? 2 : 0),
                                    card_type:this.state.CardType,
                                    card_types:this.state.OtherCardType,
                                    other_card:this.state.OtherCard,
                                    password:SetupPassword,
                                },
                            }).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        ReSuccessId:res.num,
                                        ReStepNum:4,
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'错误提示同一弹窗',
                                        MessageTip:res.errmsg,
                                    });
                                }
                            });
                        }else if(this.state.ReFormat === '团体身份注册'){
                            post({
                                url:'passport/org_register',
                                data:{
                                    username:this.state.UserName,
                                    mobile:this.state.LandLine,
                                    org_name:this.state.GroupName,
                                    subtype:this.state.GroupNature,
                                    social_credit_code:this.state.UnifiedCode,
                                    org_email:this.state.GroupEmail,
                                    register_addr:this.state.GroupReAddress,
                                    office_addr:this.state.Address,
                                    office_pro:this.state.AdProvince,
                                    office_city:this.state.AdCity,
                                    office_county:this.state.AdArea,
                                    admin_name:this.state.RealName,
                                    admin_mobile:this.state.Mobile,
                                    admin_email:this.state.Email,
                                    password:SetupPassword,
                                },
                            }).then((res) => {
                                if(res.errno === '200'){
                                    this.setState({
                                        ReSuccessId:res.num,
                                        ReStepNum:4,
                                    });
                                }else {
                                    this.setState({
                                        PopUp:'错误提示同一弹窗',
                                        MessageTip:res.errmsg,
                                    });
                                }
                            });
                        }
                    }else {
                        this.setState({
                            PopUp:'错误提示同一弹窗',
                            MessageTip:'密码要求必须含有英文和数字！',
                        });
                    }
                }else {
                    this.setState({
                        PopUp:'错误提示同一弹窗',
                        MessageTip:'密码必须不少于八位！',
                    });
                }
            }else {
                this.setState({
                    PopUp:'错误提示同一弹窗',
                    MessageTip:'两次输入的密码必须一致！',
                });
            }
        }else {
            this.setState({
                PopUp:'错误提示同一弹窗',
                MessageTip:'您有必要信息没有填写！',
            });
        }
    };



    //注册第二步的上一步按钮
    StepTwoLastBtnClick(){
        this.setState({
            ReStepNum:1,
        });
    };
    //注册第三步的上一步按钮
    StepThreeLastBtnClick(){
        this.setState({
            ReStepNum:2,
        });
    };
    //注册成功去登录按钮
    StepSuccessBtnClick(){
        const {history} = this.props;
        history.push('/login');
    };


    render() {
        const OtherCardPopUpWod =
            <span style={{color:'#575757',}}>需要您在下一步填写<span style={{color:'#009944',}}>证件类型、证件号<span style={{color:'#ff0000',}}>（不为身份证、身份证号）</span></span>及<span style={{color:'#009944',}}>详细个人信息</span>等其它内容！</span>;
        const Empty =
            <div style={{height:36,}}> </div>;
        const MustTip =
            <div className={'InfoBoxOdd'} style={{marginBottom:0,}}>
                <div className={'MustTip'}>（* 为必填项）</div>
            </div>;
        const MustTips =
            <div className={'InfoBoxOdd'} style={{marginBottom:0,width:1200,marginTop:20,}}>
                <div className={'MustTip'}>（* 为必填项）</div>
            </div>;
        const AgreeBox =
            <div className={'InfoBoxOdd'} style={{marginBottom:38,width:434,}}>
                <div className={'AgreeCon'}>
                    同意
                    <a href={'/agreement?id=n50c4dpu8ca8n9sc'} target={'_blank'}>《中国航协模拟飞行服务平台服务协议》</a>
                    {/*
                    以及
                    <a href={'/agreement?id=f8t8f3muh8w2r7g8'} target={'_blank'}>《用户注册和隐私保护服务协议》</a>
                    */}
                </div>
                <div className={'AgreeBox'} onClick={this.AgreeClick.bind(this)}><div className={'AgreeBoxOk'} style={{display:this.state.AgreeDisplay}}> </div></div>
            </div>;
        const RealName =
            <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*姓名</div></div>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'RealName'}
                       value={this.state.RealName}
                       onChange={this.RealNameChange.bind(this)}
                       placeholder={'请输入真实姓名'}
                       autoComplete={'off'}
                />
            </div>;
        const UserName =
            <div className={'InfoBoxOdd'} style={{marginBottom:26,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*用户名</div></div>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'UserName'}
                       value={this.state.UserName}
                       onChange={this.UserNameChange.bind(this)}
                       placeholder={'请输入用户名'}
                       autoComplete={'off'}
                />
            </div>;
        const Mobile =
            <div className={'InfoBoxOdd'} style={{marginBottom:6,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*手机号</div></div>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Mobile'}
                       value={this.state.Mobile}
                       onChange={this.MobileChange.bind(this)}
                       placeholder={'请输入正确的手机号'}
                       autoComplete={'off'}
                />
            </div>;
        const VrCode =
            <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*验证码</div></div>
                <div className={'ReInfoInputOddBox'}>
                    <input className={'ReVrCodeInput'}
                           type={'text'}
                           name={'VrCode'}
                           value={this.state.VrCode}
                           onChange={this.VrCodeChange.bind(this)}
                           placeholder={'输入验证码'}
                           autoComplete={'off'}
                    />
                    {
                        this.state.VrCodeErrmsg === '' &&
                        <button className={'ReVrCodeBtn'} onClick={this.ReVrCodeBtnClick.bind(this)}>获取验证码</button>
                    }
                    {
                        this.state.VrCodeErrmsg !== '' &&
                        <button className={'ReVrCodeBtn'} style={{backgroundColor:'#D5D5D5',}}>
                            获取成功<Second SecondBackChange={this.SecondBackChange.bind(this)}/>
                        </button>
                    }
                </div>
            </div>;
        const Email =
            <div className={'InfoBoxOdd'} style={{marginBottom:24,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*邮箱</div></div>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Email'}
                       value={this.state.Email}
                       onChange={this.EmailChange.bind(this)}
                       placeholder={'请输入正确的邮箱'}
                       autoComplete={'off'}
                />
            </div>;
        const EmailNoMust =
            <div className={'InfoBoxOdd'} style={{marginBottom:44,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#818181',}}>邮箱</div></div>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Email'}
                       value={this.state.Email}
                       onChange={this.EmailChange.bind(this)}
                       placeholder={'请输入正确的邮箱'}
                       autoComplete={'off'}
                />
            </div>;
        const AddressThree =
            <div className={'InfoBoxOdd'} style={{marginBottom:6,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*通讯地址</div></div>
                <div className={'ReInfoInputOddBox'}>
                    <ThreeLevelLinkage
                        ChildLinkClick={this.ChildLinkClick.bind(this)}
                        type={'地址'}
                        Width={418}
                        ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                    />
                </div>
            </div>;
        const Address =
            <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'}> </div></div>
                <input className={'ReInfoInputOdd'}
                       type={'text'}
                       name={'Address'}
                       value={this.state.Address}
                       onChange={this.AddressChange.bind(this)}
                       placeholder={'请输入详细地址'}
                       autoComplete={'off'}
                />
            </div>;
        const SchoolThree =
            <div className={'InfoBoxOdd'} style={{marginBottom:6,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#818181',}}>学校/单位</div></div>
                <div className={'ReInfoInputOddBox'}>
                    <ThreeLevelLinkage
                        ChildLinkClick={this.ChildLinkClick.bind(this)}
                        type={'学校'}
                        Width={418}
                        ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                    />
                </div>
            </div>;
        const School =
            <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'}> </div></div>
                <div className={'ReInfoInputOddBox'}>
                    <div className={'SchoolLinkBox'} tabIndex={'2'} onClick={this.SchoolClick.bind(this)}>
                        <div className={'SchoolWordBox'}>{this.state.SchoolName}</div>
                        <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'17px'} Right={'10px'}/>
                        <div className={'SchoolLinkListBox'} style={{display:this.state.SchoolDisplay,}}>
                            {
                                this.state.SchoolArr.map((item,index) =>
                                    <div className={'SchoolLevelLinkList'} key={index} onClick={() => this.schoolArrClick(item,index)}>{item.org_name}</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>;
        const GroupNature =
            <div className={'InfoBoxOdd'} style={{marginBottom:52,}}>
                <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*团体性质</div></div>
                <div className={'ReInfoGroupOddBox'} style={{borderColor:'#1C7DC4',}} onClick={this.GroupNatureClick.bind(this)}>
                    <div className={'GroupNatureName'}>{this.state.GroupNature === '' ? '请选择团体性质' : this.state.GroupNature}</div>
                    <div className={'RightDownBox'}>
                        <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'16px'} Right={'21px'}/>
                    </div>
                    <div className={'GroupNatureBox'} style={{display:this.state.GroupNatureDisplay}}>
                        {
                            this.state.GroupNatureArr.map((item,index) =>
                                <div className={'GroupNatureList'} key={index} onClick={() => this.GroupNatureArrClick(item,index)}>{item}</div>
                            )
                        }
                    </div>
                </div>
            </div>;
        const StepOneBtn =
            <button className={'NextButton'} onClick={this.StepOneBtnClick.bind(this)}>下一步</button>;
        const StepTwoBtn =
            <button className={'NextButton'} onClick={this.StepTwoBtnClick.bind(this)}>下一步</button>;
        const StepTwoLastBtn =
            <button className={'LastButton'} onClick={this.StepTwoLastBtnClick.bind(this)}>上一步</button>;
        const StepThreeBtn =
            <button className={'NextButton'} onClick={this.StepThreeBtnClick.bind(this)}>下一步</button>;
        const StepThreeLastBtn =
            <button className={'LastButton'} onClick={this.StepThreeLastBtnClick.bind(this)}>上一步</button>;
        const StepSuccessBtn =
            <button className={'NextButton'} onClick={this.StepSuccessBtnClick.bind(this)}>立即登录</button>;
        const ReOnePe =
            <div className={'ReContentOdd'}>
                {RealName}
                <div className={'InfoBoxOdd'} style={{marginBottom:136,}}>
                    <div className={'InfoTitBox'}>
                        <div className={'InfoTit'} style={{float:'left',width:'auto',}}>*</div>
                        <div className={'CardType'}
                             style={{backgroundColor:this.state.CardType === '证件类型' ? '#6EC2FF' : '#ddd',}}
                             onClick={this.CardTypeClick.bind(this)}
                        >
                            <div className={'CardTypeShow'} style={{width:80,textAlign:'center',}}>{this.state.CardType}</div>
                            <Triangle Direction={'down'} Color={'#FFFFFF'} Width={'6.5px'} Height={'10px'} Top={'17px'} Right={'10px'}/>
                            <div className={'CardTypeConBox'} style={{display:this.state.CardTypeDisplay}}>
                                <div className={'IdCard'} onClick={this.IdCardTypeClick.bind(this)}>身份证</div>
                                <div className={'OtherCard'} onClick={this.OtherCardTypeClick.bind(this)}>其它证件</div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.CardType === '证件类型' &&
                        <div className={'ReInfoGroupOddBox'} style={{border:0,color:'#ff0000',textIndent:0,fontSize:14,}}>*请选择证件类型；选择其他证件时填写身份证号不予通过。</div>
                    }
                    {
                        this.state.CardType === '其它证件' &&
                        <div className={'ReInfoGroupOddBox'} style={{border:0,color:'#009944',textIndent:0,}}>*请同意服务协议后在下一步填写详细信息！</div>
                    }
                    {
                        this.state.CardType !== '其它证件' && this.state.CardType !== '证件类型' &&
                        <input className={'ReInfoInputOdd'}
                               type={'text'}
                               name={'RealName'}
                               value={this.state.IdCard}
                               onChange={this.IdCardChange.bind(this)}
                               placeholder={'请输入身份证号'}
                               autoComplete={'off'}
                               readOnly={this.state.CardType === '身份证' ? '' : 'readonly'}
                        />
                    }
                </div>
                {AgreeBox}
                {StepOneBtn}
                <div style={{width:'100%',height:32,}}> </div>
            </div>;
        const ReOneCom =
            <div className={'ReContentOdd'}>
                {RealName}
                {Mobile}
                {VrCode}
                {Email}
                {MustTip}
                {AgreeBox}
                {StepOneBtn}
            </div>;
        const ReTwoPeId =
            <div className={'ReContentOdd'}>
                {UserName}
                {Mobile}
                {VrCode}
                {AddressThree}
                {Address}
                {SchoolThree}
                {School}
                {EmailNoMust}
                {MustTip}
                {StepTwoBtn}
                {StepTwoLastBtn}
                {Empty}
            </div>;
        const ReTwoPeOther =
            <div>
                <div className={'ReContentComplex clearfix'}>
                    <div className={'ReContentOdd'} style={{float:'left',}}>
                        {UserName}
                        <div className={'InfoBoxOdd'} style={{marginBottom:6,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*证件类型</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'OtherCardType'}
                                   value={this.state.OtherCardType}
                                   onChange={this.OtherCardTypeChange.bind(this)}
                                   placeholder={'请输入正确的证件类型'}
                                   autoComplete={'off'}
                            />
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*证件号</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'OtherCard'}
                                   value={this.state.OtherCard}
                                   onChange={this.OtherCardChange.bind(this)}
                                   placeholder={'请输入正确的证件号'}
                                   autoComplete={'off'}
                            />
                        </div>
                        {AddressThree}
                        {Address}
                        {SchoolThree}
                        {School}
                    </div>
                    <div className={'ReContentOdd'} style={{float:'left',}}>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*性别</div></div>
                            <div className={'ReInfoInputOddBox'}>
                                <div className={'SexBox'} onClick={this.SexManClick.bind(this)}>
                                    <div className={this.state.ManStyle}> </div>
                                    <div className={'SexBoxWord'}>男</div>
                                </div>
                                <div className={'SexBox'} onClick={this.SexWomanClick.bind(this)}>
                                    <div className={this.state.WomanStyle}> </div>
                                    <div className={'SexBoxWord'}>女</div>
                                </div>
                            </div>
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:76,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*出生日期</div></div>
                            <div className={'ReInfoInputOddBox'}>
                                <ThreeLevelLinkage
                                    ChildLinkClick={this.ChildLinkClick.bind(this)}
                                    type={'出生日期'}
                                    Width={418}
                                    ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                />
                            </div>
                        </div>
                        {Mobile}
                        {VrCode}
                        {EmailNoMust}
                    </div>
                </div>
                {MustTips}
                {StepTwoBtn}
                {StepTwoLastBtn}
                {Empty}
            </div>;
        const ReTwoComGrayScale =
            <div>
                <div className={'ReContentComplex clearfix'}>
                    <div className={'ReContentOdd'} style={{float:'left',}}>
                        {GroupNature}
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#999999',}}>*用户名</div></div>
                            <div className={'ReInfoGroupOddBox'}> </div>
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:52,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#999999',}}>*团体全称</div></div>
                            <div className={'ReInfoGroupOddBox'}> </div>
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#999999',fontSize:14,}}>社会统一代码</div></div>
                            <div className={'ReInfoGroupOddBox'}> </div>
                        </div>
                    </div>
                    <div className={'ReContentOdd'} style={{float:'left',}}>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#999999',}}>*注册地址</div></div>
                            <div className={'ReInfoGroupOddBox'}> </div>
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:6,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#999999',}}>*办公地址</div></div>
                            <div className={'ReInfoInputOddBox'}>
                                <ThreeLevelLinkage
                                    ChildLinkClick={this.ChildLinkClick.bind(this)}
                                    type={'灰度'}
                                    Width={418}
                                    ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                />
                            </div>
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'}> </div></div>
                            <div className={'ReInfoGroupOddBox'}> </div>
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#999999',}}>*联系电话</div></div>
                            <div className={'ReInfoGroupOddBox'}> </div>
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',color:'#999999',}}>*团体邮箱</div></div>
                            <div className={'ReInfoGroupOddBox'}> </div>
                        </div>
                    </div>
                </div>
                {MustTips}
                {StepTwoBtn}
                {StepTwoLastBtn}
                {Empty}
            </div>;
        const ReTwoCom =
            <div>
                <div className={'ReContentComplex clearfix'}>
                    <div className={'ReContentOdd'} style={{float:'left',}}>
                        {GroupNature}
                        {UserName}
                        <div className={'InfoBoxOdd'} style={{marginBottom:52,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*团体全称</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'GroupName'}
                                   value={this.state.GroupName}
                                   onChange={this.GroupNameChange.bind(this)}
                                   placeholder={'请输入正确的团体全称'}
                                   autoComplete={'off'}
                            />
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',fontSize:14,color:'#818181',}}>社会统一代码</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'UnifiedCode'}
                                   value={this.state.UnifiedCode}
                                   onChange={this.UnifiedCodeChange.bind(this)}
                                   placeholder={'请输入正确的团体社会统一代码'}
                                   autoComplete={'off'}
                            />
                        </div>
                    </div>
                    <div className={'ReContentOdd'} style={{float:'left',}}>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*注册地址</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'GroupReAddress'}
                                   value={this.state.GroupReAddress}
                                   onChange={this.GroupReAddressChange.bind(this)}
                                   placeholder={'请输入正确的团体注册地址'}
                                   autoComplete={'off'}
                            />
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:6,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*办公地址</div></div>
                            <div className={'ReInfoInputOddBox'}>
                                <ThreeLevelLinkage
                                    ChildLinkClick={this.ChildLinkClick.bind(this)}
                                    type={'地址'}
                                    Width={418}
                                    ThreeLevelLinkage={this.state.ThreeLevelLinkage}
                                />
                            </div>
                        </div>
                        {Address}
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*联系电话</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'LandLine'}
                                   value={this.state.LandLine}
                                   onChange={this.LandLineChange.bind(this)}
                                   placeholder={'请输入正确的团体联系电话'}
                                   autoComplete={'off'}
                            />
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*团体邮箱</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'text'}
                                   name={'LandLine'}
                                   value={this.state.GroupEmail}
                                   onChange={this.GroupEmailChange.bind(this)}
                                   placeholder={'请输入正确的团体邮箱'}
                                   autoComplete={'off'}
                            />
                        </div>
                    </div>
                </div>
                {MustTips}
                {StepTwoBtn}
                {StepTwoLastBtn}
                {Empty}
            </div>;
        const SetupPassword =
            <div>
                <form>
                    <input className={'ForgetPassword'}
                           style={{display:'none',}}
                           type={'text'}
                           name={'Account'}
                           autoComplete={'off'}
                    />
                    <input className={'ForgetPassword'}
                           style={{display:'none',}}
                           type={'password'}
                           name={'Password'}
                           autoComplete={'new-password'}
                    />
                    <div className={'ReContentOdd'}>
                        <div className={'InfoBoxOdd'} style={{marginBottom:28,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*设置密码</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'password'}
                                   name={'SetupPassword'}
                                   value={this.state.SetupPassword}
                                   onChange={this.SetupPasswordChange.bind(this)}
                                   placeholder={'请设置密码'}
                                   autoComplete={'new-password'}
                                   minLength={'6'}
                            />
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:1,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}>*确认密码</div></div>
                            <input className={'ReInfoInputOdd'}
                                   type={'password'}
                                   name={'SetupPassword'}
                                   value={this.state.SetupPasswordRepeat}
                                   onChange={this.SetupPasswordRepeatChange.bind(this)}
                                   placeholder={'请确认密码'}
                                   autoComplete={'new-password'}
                                   minLength={'6'}
                            />
                        </div>
                        <div className={'InfoBoxOdd'} style={{marginBottom:160,}}>
                            <div className={'InfoTitBox'}><div className={'InfoTit'} style={{textAlign:'right',}}> </div></div>
                            <div className={'SetupPasswordWord'}>*密码要求必须含有英文和数字、不少于八位。</div>
                        </div>
                    </div>
                </form>
                {StepThreeBtn}
                {StepThreeLastBtn}
                <div style={{width:'100%',height:32,}}> </div>
            </div>;
        const ReSuccessBtn =
            <div>
                <div className={'ReSuccessBox'} style={{marginTop:180,marginBottom:24,}}>
                    <div className={'ReSuccessIco'}> </div>
                    <div className={'ReSuccessWord'}>恭喜您，注册成功！</div>
                </div>
                <div className={'ReSuccessId'} style={{marginBottom:100,}}>用户ID：<span style={{fontSize:24,color:'#FF0000'}}>{this.state.ReSuccessId}</span></div>
                {StepSuccessBtn}
            </div>;
        const ReToExamine =
            <div className={'ToExamineBox'}>
                <div className={'ToExamineIco'}> </div>
                <div className={'ToExamineWordBox'}>
                    <div className={'ToExamineWord'} style={{textAlign:'left',}}>人工审核中...</div>
                    <div className={'ToExamineWord'} style={{textAlign:'right',}}>请您耐心等待</div>
                </div>
                <div className={'ToExamineStep'}><div className={'ToExamineStepHave'}> </div></div>
            </div>;
        return (
                <div className={'AuthBox'} style={{height:this.state.winHeight,}}>
                    <AuthHeader/>
                    <div className={'AuthBg'}>
                        <div className={'RegisterPeAndCom'}>
                            <div className={'RegisterTypeTit'}>注册类型</div>
                            <div className={'RegisterTypeBg'}>
                                <div className={'RegisterTypeBox'}>
                                    <div className={'RegisterType'} style={this.state.RePeStyle} onClick={this.RePeStyleClick.bind(this)}>
                                        <div className={'RePeIco'}> </div>
                                        <div className={'ReWord'} style={this.state.RePeStyle}>个人注册</div>
                                    </div>
                                    <div className={'RegisterEmpty'}> </div>
                                    <div className={'RegisterType'} style={this.state.ReComStyle} onClick={this.ReComStyleClick.bind(this)}>
                                        <div className={'ReComIco'}> </div>
                                        <div className={'ReWord'} style={this.state.ReComStyle}>团体注册</div>
                                    </div>
                                </div>
                            </div>
                            <ReStep ReFormat={this.state.ReFormat} ReStepNum={this.state.ReStepNum}/>
                            {
                                this.state.ReStepNum === 1 &&
                                <div>
                                    {
                                        this.state.ReFormat === '个人身份证注册' &&
                                        ReOnePe
                                    }
                                    {
                                        this.state.ReFormat === '个人其他证件注册' &&
                                        ReOnePe
                                    }
                                    {
                                        this.state.ReFormat === '团体身份注册' &&
                                        ReOneCom
                                    }
                                </div>
                            }
                            {
                                this.state.ReStepNum === 2 &&
                                <div>
                                    {
                                        this.state.ReFormat === '个人身份证注册' &&
                                        ReTwoPeId
                                    }
                                    {
                                        this.state.ReFormat === '个人其他证件注册' &&
                                        ReTwoPeOther
                                    }
                                    {
                                        this.state.ReFormat === '团体身份注册' && this.state.GroupNature === '' &&
                                        ReTwoComGrayScale
                                    }
                                    {
                                        this.state.ReFormat === '团体身份注册' && this.state.GroupNature !== '' &&
                                        ReTwoCom
                                    }
                                </div>
                            }
                            {
                                this.state.ReStepNum === 3 &&
                                SetupPassword
                            }
                            {
                                this.state.ReStepNum === 4 &&
                                <div>
                                    {
                                        this.state.ReFormat === '个人身份证注册' &&
                                        ReSuccessBtn
                                    }
                                    {
                                        this.state.ReFormat === '个人其他证件注册' &&
                                        ReToExamine
                                    }
                                    {
                                        this.state.ReFormat === '团体身份注册' &&
                                        ReSuccessBtn
                                    }
                                </div>
                            }
                        </div>
                        {/*<div className={'PositionBottom'}> </div>*/}
                    </div>
                    <Footer/>
                    {
                        this.state.PopUp === '错误提示同一弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'错误'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                    {
                        this.state.PopUp === '日常提示同一弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'No'}
                        />
                    }
                    {
                        this.state.PopUp === '选择以其他证件注册时' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={OtherCardPopUpWod}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '选择以其他证件注册时下一步' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick1.bind(this)}
                            type={'成功'}
                            message={OtherCardPopUpWod}
                            OutButton={'Yes'}
                        />
                    }
                    {
                        this.state.PopUp === '短信验证码重复使用提示' &&
                        <PopUp
                            ChildBackClick={this.ChildBackClick.bind(this)}
                            type={'成功'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default Register;
