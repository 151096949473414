import React, { Component } from 'react';
import CheckboxIcoNot from "./memberimage/CheckboxIcoNot.png";
import CheckboxIcoHave from "./memberimage/CheckboxIcoHave.png";
import InfoPopUp from "../round/InfoPopUp";
import PopUp from "../round/PopUp";
import {get, post} from "../../ajax/tools";
import './member.css';
import '../round/round.css';


class GrCounselor extends Component {

    constructor(props){
        super(props);
        this.state = {
            CounselorInfo:{},
            CounselorInfoType:'',
            CounselorType:'search',

            CounselorId:'',
            CounselorSendNum:0,
            CounselorSendNumArr: [],
            CounselorInfoArr:[],
            CheckboxIcoToggleOn: true,

            CounselorListArr:[]

        };
    }
    componentDidMount=function() {

    };




    //点击按钮列表页面
    ListButton=()=>{
        get({url:"agency/assist_apply_info"}).then(res => {
            if(res.errno === '200'){
                this.setState({
                    CounselorListArr:res.data,
                    CounselorType:'list',
                });
            }else {
                this.setState({
                    CounselorListArr:[],
                    PopUp:'提示弹窗',
                    MessageTip:'您还没有推荐过辅导员！',
                });
            }
        });
    };

    //点击按钮返回认证信息列表他页面
    CounselorBackBox=()=>{
        this.setState({
            CounselorType:'search',
        });
    };

    //申请成功页面点击按钮返回认证信息列表他页面
    CounselorBackBoxes=()=>{
        this.setState({
            CounselorType:'search',
            CounselorId:'',
            CounselorSendNum:0,
            CounselorSendNumArr: [],
            CounselorInfoArr:[],
            CheckboxIcoToggleOn: true,
        });
    };






    //输入会员的ID
    CounselorIdChange(event){
        //event.target.value = event.target.value.replace(/[^\d]/g, '');
        this.setState({
            CounselorId: event.target.value,
        });
    };


    //点击搜索团队
    CounselorJoinInput=(event)=>{
        if(event !== ''){
            get({url:'reuse/get_personal_info/'+event}).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        CounselorInfoType:'have',
                        CounselorInfo:res.data[0],
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:res.errmsg,
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请输入ID号！',
            });
        }
    };






    //点击发送多个申请
    CounselorPleaseSend=(event)=>{
        let CounselorSendBrr = this.state.CounselorSendNumArr;
        //console.log(CounselorSendBrr)
        if (CounselorSendBrr.length !== 0){
            post({
                url:'agency/assist_apply',
                data:{
                    data:CounselorSendBrr,
                },
            }).then((res) => {
                if(res.errno === '200'){
                    this.setState({
                        CounselorType:'review',
                    });
                }else if(res.errno === '4003'){
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'推荐名单中有用户已经是辅导员！',
                    });
                }else {
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'提请推荐失败，请稍后重试！',
                    });
                }
            });
        }else {
            this.setState({
                PopUp:'提示弹窗',
                MessageTip:'请选择推荐的人员！',
            });
        }
    };





    //点击多选框全选
    CheckboxIcoAll=(event)=>{
        const CounselorInfoBrr=[];
        const CounselorSendNumArr=[];
        if(event.length !== 0){
            for(let i=0;i<event.length;i++){
                const CounselorList= event[i];
                if(this.state.CheckboxIcoToggleOn === false){
                    if(CounselorList.send === false){
                        CounselorList.Checkbox = false;
                        CounselorList.index = i;
                        CounselorSendNumArr.push(CounselorList);
                    }
                    CounselorInfoBrr.push(CounselorList);
                }else if(this.state.CheckboxIcoToggleOn === true){
                    if(CounselorList.send === false){
                        CounselorList.Checkbox = true;
                        CounselorList.index = i;
                        CounselorSendNumArr.push(CounselorList);
                    }
                    CounselorInfoBrr.push(CounselorList);
                }
            }
            this.setState(prevState => ({
                CheckboxIcoToggleOn: !prevState.CheckboxIcoToggleOn,
                CheckboxIcoBgImg:CounselorSendNumArr.length === 0 ? 'url('+CheckboxIcoNot+')' : (prevState.CheckboxIcoToggleOn ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),
                CounselorSendNum:prevState.CheckboxIcoToggleOn ? CounselorSendNumArr.length : 0,
                CounselorInfoArr:CounselorInfoBrr,
                CounselorSendNumArr:prevState.CheckboxIcoToggleOn ? CounselorSendNumArr : [],
            }));
        }
    };
    //点击单个多选框
    CheckboxIco=(item,index)=>{
        let CounselorInfoList = this.state.CounselorInfoArr;
        CounselorInfoList[index]['Checkbox'] = item.Checkbox !== true;
        const CounselorSendNumArr = [...this.state.CounselorSendNumArr];
        if(item.Checkbox === true){
            item.index = index;
            CounselorSendNumArr.push(item);
            this.setState({
                CounselorSendNumArr:CounselorSendNumArr,
                CounselorSendNum:CounselorSendNumArr.length,
            });
        }else if(item.Checkbox === false){
            for(let i=0;i<CounselorSendNumArr.length;i++){
                if(CounselorSendNumArr[i].id === item.id){
                    CounselorSendNumArr.splice(i,1);
                }
            }
            this.setState({
                CounselorSendNumArr:CounselorSendNumArr,
                CounselorSendNum:CounselorSendNumArr.length,
            });
        }
        const CounselorSendNoArr=[];
        for(let i=0;i<this.state.CounselorInfoArr.length;i++){
            const CounselorList= this.state.CounselorInfoArr[i];
            if(CounselorList.send === false){
                CounselorSendNoArr.push(CounselorList)
            }
        }
        this.setState({
            CounselorInfoArr:CounselorInfoList,
            CheckboxIcoToggleOn:CounselorSendNoArr.length !== CounselorSendNumArr.length,
        });
    };



    DeleteClick=(item,index)=>{
        let CounselorInfoList = this.state.CounselorInfoArr;
        let CounselorSendNumArr = this.state.CounselorSendNumArr;
        let CounselorInfoArrListIdNumArr = [];
        for(let i=0;i<CounselorInfoList.length;i++){
            if(CounselorInfoList[i].id === item.id){
                CounselorInfoList.splice(i,1);
            }
        }
        for(let i=0;i<CounselorSendNumArr.length;i++){
            let CounselorListId = CounselorSendNumArr[i].id;
            CounselorInfoArrListIdNumArr.push(CounselorListId);
        }
        if(CounselorInfoArrListIdNumArr.includes(item.id)){
            for(let i=0;i<CounselorSendNumArr.length;i++){
                if(CounselorSendNumArr[i].id === item.id){
                    CounselorSendNumArr.splice(i,1);
                }
            }
        }
        this.setState(prevState => ({
            CheckboxIcoToggleOn: CounselorInfoList.length === 0,
            CheckboxIcoBgImg:CounselorInfoList.length === 0 ? 'url('+CheckboxIcoNot+')' : this.state.CheckboxIcoBgImg,
            CounselorInfoArr:CounselorInfoList,
            CounselorSendNumArr:CounselorSendNumArr,
            CounselorSendNum:CounselorSendNumArr.length,
        }));
    };









    //查看人员详情弹窗
    InfoPopUpBack=(event)=>{
        if(event.type === 'CrossOut'){
            this.setState({
                CounselorInfoType:'not',
                CounselorId: '',
            });
        }else if(event.type === 'Confirm'){
            let CounselorSendNumArr = this.state.CounselorSendNumArr;
            let CounselorInfoArr = this.state.CounselorInfoArr;
            let CounselorInfo = this.state.CounselorInfo;
            let CounselorInfoArrListIdNumArr = [];
            let CounselorInfoCbArr=[];
            if(CounselorInfoArr.length !==0){
                for(let i=0;i<CounselorInfoArr.length;i++){
                    let CounselorListId = CounselorInfoArr[i].id;
                    CounselorInfoArrListIdNumArr.push(CounselorListId);
                }
                if(CounselorInfoArrListIdNumArr.includes(CounselorInfo.id)){
                    this.setState({
                        PopUp:'提示弹窗',
                        MessageTip:'您已经搜索添加过该人员！',
                    });
                }else {
                    let CounselorTheList = CounselorInfo;
                    CounselorTheList.Checkbox= false;
                    CounselorTheList.send= false;
                    CounselorInfoArr.push(CounselorTheList);
                }
                this.setState({
                    CounselorInfoArr:CounselorInfoArr,
                    CounselorInfoType:'not',
                    CounselorId: '',
                    CheckboxIcoToggleOn:CounselorInfoArr.length !== CounselorSendNumArr.length,
                });
            }else {
                let CounselorTheList = CounselorInfo;
                CounselorTheList.Checkbox = false;
                CounselorTheList.send= false;
                CounselorInfoCbArr.push(CounselorTheList);
                this.setState({
                    CounselorInfoArr:CounselorInfoCbArr,
                    CounselorInfoType:'not',
                    CounselorId: '',
                });
            }
        }
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }else if(event === 'Confirm'){
            this.setState({
                PopUp:'',
            });
        }
    };




    render() {
        let ButtonType = [
            {
                name:'添加',
                bgColor:'#6EC2FF',
                color:'#FFFFFF',
            },
        ];
        return (
                <div className={'MemberPackageBox'} style={{height:window.innerHeight-180,}}>
                    {
                        this.state.CounselorType === 'search'&&
                        <div>
                            <div className={'CounselorTitle'}>
                                 <div className={'CounselorListBtn'} onClick={this.ListButton.bind(this)}>推荐纪录</div>
                            </div>
                            <div style={{width:900,margin:'28px auto',}}>
                                <div className={'TeamIdInputBoxes clearfix'}>
                                    <div className={'TeamIdInputTit'}>输入推荐人员ID</div>
                                    <div className={'TeamIdInputBox clearfix'}>
                                        <input className={'TeamIdInput'}
                                               type={'text'}
                                               name={'CounselorId'}
                                               value={this.state.CounselorId}
                                               onChange={this.CounselorIdChange.bind(this)}
                                               placeholder={'请输入鹰扬ID或身份证号'}
                                               autoComplete={'off'}
                                        />
                                        <button className={'TeamIdInputBtn'} onClick={() => this.CounselorJoinInput(this.state.CounselorId)}>搜索</button>
                                    </div>
                                </div>
                                <button className={'TeamPleaseSendBtn'}
                                        style={this.state.CounselorSendNum === 0 ? {backgroundColor:'#d5d5d5',} : {backgroundColor:'#ff732e',}}
                                        onClick={this.state.CounselorSendNum === 0 ? null : this.CounselorPleaseSend.bind(this)}>确认推荐（{this.state.CounselorSendNum}）
                                </button>
                                <div className={'TeamListHeaderBox'}>
                                    <div className={'TeamHeaderList'} style={{width:40,}}>
                                        <div className={'TeamHeaderCheckbox'}
                                             onClick={() => this.CheckboxIcoAll(this.state.CounselorInfoArr)}>
                                            {
                                                this.state.CheckboxIcoToggleOn === false &&
                                                <div className={'TeamHeaderCheckboxActive'}> </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={'TeamHeaderList'} style={{width:100,}}>序号</div>
                                    <div className={'TeamHeaderList'} style={{width:240,}}>姓名</div>
                                    <div className={'TeamHeaderList'} style={{width:240,}}>联系方式</div>
                                    <div className={'TeamHeaderList'} style={{width:172,}}>是否教练员</div>
                                    <div className={'TeamHeaderList'} style={{width:100,}}>操作</div>
                                </div>
                                <div className={'TeamListConBox'} style={{height:window.innerHeight-420,}}>
                                    {
                                        this.state.CounselorInfoArr.map((item,index) =>
                                            <div className={'TeamListCon'} key={index}>
                                                <div className={'TeamConList'} style={{width:40,}}>
                                                    <div className={'CheckboxIco'}
                                                         style={{backgroundImage:item.send === true ? '' : (item.Checkbox === true ? 'url('+CheckboxIcoHave+')' : 'url('+CheckboxIcoNot+')'),
                                                             marginTop:7,
                                                         }}
                                                         onClick={item.send === true ? null : () => this.CheckboxIco(item,index)}>
                                                    </div>
                                                </div>
                                                <div className={'TeamConList'} style={{width:100,}}>{index+1}</div>
                                                <div className={'TeamConList'} style={{width:240,}}>{item.real_name}</div>
                                                <div className={'TeamConList'} style={{width:240,}}>{item.mobile}</div>
                                                <div className={'TeamConList'} style={{width:172,}}>{item.iscoach === 1 ? '是' : '否'}</div>
                                                <div className={'TeamConList'} style={{width:100,}}>
                                                    <span className={'DetailsViews'} style={{color:'#ff0000',}} onClick={() => this.DeleteClick(item,index)}>删除</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.CounselorType === 'review'&&
                        <div>
                            <div className={'CounselorTitle'}>
                                <div className={'DetailsBackBox clearfix'} style={{marginTop:20,}} onClick={this.CounselorBackBoxes.bind(this)}>
                                    <div className={'DetailsBackIco'}> </div>
                                    <div className={'DetailsBackWord'}>返回</div>
                                </div>
                            </div>
                            <div style={{width:900,margin:'28px auto',}}>
                                <div className={'ManualReviewBox'}>
                                    <div className={'ManualReviewIco'}> </div>
                                    <div className={'ManualReviewWordBox'}>
                                        <div className={'ManualReviewWord'} style={{textAlign:'left',}}>已提交审核...</div>
                                        <div className={'ManualReviewWord'} style={{textAlign:'right',}}>请您耐心等待</div>
                                    </div>
                                    <div className={'ManualReviewStep'}><div className={'ManualReviewStepHave'}> </div></div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.CounselorType === 'list'&&
                        <div>
                            <div className={'CounselorTitle'}>
                                <div className={'DetailsBackBox clearfix'} style={{marginTop:20,}} onClick={this.CounselorBackBox.bind(this)}>
                                    <div className={'DetailsBackIco'}> </div>
                                    <div className={'DetailsBackWord'}>返回</div>
                                </div>
                            </div>
                            <div style={{width:780,margin:'10px auto',}}>
                                <div className={'GrGameHeaderBox'} style={{width:780,}}>
                                    <div className={'GrGameHeaderInfo'} style={{width:80,}}>序号</div>
                                    <div className={'GrGameHeaderInfo'} style={{width:240,}}>姓名</div>
                                    <div className={'GrGameHeaderInfo'} style={{width:240,}}>推荐时间</div>
                                    <div className={'GrGameHeaderInfo'} style={{width:214,}}>审核状态</div>
                                </div>
                                <div className={'TeamListConBox'} style={{height:window.innerHeight-314,}}>
                                    {
                                        this.state.CounselorListArr.map((item,index) =>
                                            <div className={'TeamListCon'} key={index}>
                                                <div className={'TeamConList'} style={{width:80,}}>{index+1}</div>
                                                <div className={'TeamConList'} style={{width:240,}}>{item.real_name}</div>
                                                <div className={'TeamConList'} style={{width:240,}}>{item.creat_time}</div>
                                                <div className={'TeamConList'} style={{width:214,}}>
                                                    {
                                                        item.status === 1 &&
                                                        <span style={{color:'#1ABB00',}}>已通过</span>
                                                    }
                                                    {
                                                        item.status === 2 &&
                                                        <span style={{color:'#BB0000',}}>未通过</span>
                                                    }
                                                    {
                                                        item.status === 0 &&
                                                        <span style={{color:'#6EC2FF',}}>审核中···</span>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.CounselorInfoType === 'have'&&
                        <InfoPopUp
                            UserInfo={this.state.CounselorInfo}
                            InfoPopUpBack={this.InfoPopUpBack.bind(this)}
                            ButtonType={ButtonType}
                        />
                    }
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default GrCounselor;
