import React, { Component } from 'react';
import Header from "../handf/Header";
import Footer from "../handf/Footer";
import PopUp from "../round/PopUp";
import './school.css';
import {get} from "../../ajax/tools";
import Triangle from "../round/Triangle";


class school extends Component {

    constructor(props){
        super(props);
        this.state = {
            winHeight : '',
            NowNavListZhName:'航校风采',
            NowNavListEnName:'Aviation school style',
            DownloadNavArr:[
                {
                    ZhName:'航校风采',
                    EnName:'Aviation school style',
                },
            ],
            AvSchoolArr:[],
            AvSchoolStyleArr:[],
            AvSchoolList:{},
            AvSchoolType:'航校列表',
            AvSchoolShow:'六块',
            AvSchoolId:'',
        };
    }
    componentDidMount=function() {
        get({url:'index/aviat_school_scenery'}).then((res) => {
            if(res.errno === '200'){
                let HomeExamBrr = [];
                let Num = res.data.length > 6 ? 6 : res.data.length;
                for(let i=0;i<Num;i++){
                    let HomeExamArrList = res.data[i];
                    HomeExamBrr.push(HomeExamArrList);
                }
                this.setState({
                    AvSchoolStyleArr:HomeExamBrr,
                    AvSchoolArr:res.data,
                });
            }
        });
        const contentHeight = document.body.scrollHeight;//网页正文全文高度
        const winHeight = window.innerHeight;//可视窗口高度，不包括浏览器顶部工具栏
        const ua = navigator.userAgent.toLowerCase();//媒体查询当前设备
        const windows = ua.indexOf('windows');
        const macintosh = ua.indexOf('macintosh');
        const linux = ua.indexOf('linux');
        if (contentHeight < winHeight && windows === -1 && macintosh === -1) {
            this.setState({
                winHeight : winHeight,
            });
        }else if(windows === -1 && macintosh === -1 && linux === -1){
            this.setState({
                winHeight: '',
            });
        } else{
            this.setState({
                winHeight: '',
            });
        }
    };





    //点击左侧菜单
    AccountListClick=(item,index) =>{
        this.setState({
            NowNavListZhName:item.ZhName,
            NowNavListEnName:item.EnName,
        });
    };



    AvSchoolList=(item,index)=>{
        this.setState({
            AvSchoolList:item,
            AvSchoolId:item.id,
            AvSchoolType:'风采详情',
        });
    };
    AvSchoolStyle=(item,index)=>{
        this.setState({
            AvSchoolList:item,
            AvSchoolId:item.id,
            AvSchoolType:'风采详情',
        });
    };



    BackButton=()=>{
        this.setState({
            AvSchoolType:'航校列表',
        });
    };
    BackBtn(){
        this.setState({
            AvSchoolShow:'六块',
        });
    };



    //更多航校
    AvSchoolMore(){
        let AvSchoolStyleArr = this.state.AvSchoolStyleArr;
        if (AvSchoolStyleArr.length >6){
            this.setState({
                AvSchoolShow:'更多',
            });
        }else {
            const {history} = this.props;
            history.push('/inquire_s');
        }
    };



    //提示弹窗
    ChildBackTip=(event)=>{
        if(event === 'CrossOut'){
            this.setState({
                PopUp:'',
            });
        }
    };


    render() {
        return (
                // <div className={'MainBox'} style={this.state.AvSchoolType === '航校列表' ? {} : {backgroundColor:'rgba(0,0,0,0.7)',backgroundImage:'unset',}}>
                <div className={'MainBox'}>
                    <Header/>
                    <div className={'MainContent'}>
                        <div className={'MainTopBox clearfix'}>
                            <div className={'MainConTitle'}>
                                <div className={'MainConTitZH'}>{this.state.NowNavListZhName}</div>
                                <div className={'MainConTitEN'}>Aviation&nbsp;&nbsp;school&nbsp;&nbsp;style</div>
                            </div>
                            <div className={'MainConTitBorder'}> </div>
                            <div className={'MainActiveListTitle'}>{this.state.NowNavListEnName}</div>
                        </div>
                        {
                            this.state.AvSchoolType === '航校列表'&&
                            <div className={'ContentMainBox clearfix'} style={{height:window.innerHeight-191 > 460 ? window.innerHeight-191 :460,}}>
                                <div className={'MainLeftNavBox scrollBar'} style={{height:window.innerHeight-191 > 460 ? window.innerHeight-191 :460,}}>
                                    <ul className={'MainLeftNavListBox'}>
                                        {
                                            this.state.DownloadNavArr.map((item,index) =>
                                                <li className={item.ZhName === this.state.NowNavListZhName ? 'MainLeftNavActiveList' : 'MainLeftNavList'}
                                                    key={index}
                                                    onClick={() => this.AccountListClick(item,index)}
                                                >
                                                    {item.ZhName}
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                                <div className={'MainThisNavBox scrollBar'} style={{height:window.innerHeight-191,}}>
                                    {
                                        this.state.NowNavListZhName === '航校风采'&& this.state.AvSchoolShow === '六块'&&
                                        <div style={{minHeight:window.innerHeight-430 > 748 ? window.innerHeight-430 :748,}}>
                                            <div className={'AvSchoolMoreBox clearfix'}>
                                                <div className={'AvSchoolMore'} onClick={this.AvSchoolMore.bind(this)}>
                                                    <span>更多航校</span>
                                                    <Triangle Direction={'right'} Color={'#48aed9'}
                                                              Width={'10px'} Height={'4px'}
                                                              Top={'7px'} Right={'0'}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                this.state.AvSchoolStyleArr.length !== 0&&
                                                <div className={'AvSchoolStyleBox'}>
                                                    <div className={'AvSchoolStyleUpBox clearfix'}>
                                                        {
                                                            this.state.AvSchoolStyleArr.map((item,index) =>
                                                                <div className={'AvSchoolStyle'} key={index} onClick={() => this.AvSchoolStyle(item,index)}>
                                                                    <div className={'AvSchoolNameBox'}>
                                                                        <div className={'AvSchoolBorderLeft'}> </div>
                                                                        <div className={'AvSchoolBorderRight'}> </div>
                                                                        {item.title}
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {
                                        this.state.NowNavListZhName === '航校风采'&& this.state.AvSchoolShow === '更多'&&
                                        <div>
                                            <div className={'DetailsPackageTop'}>
                                                <div className={'DetailsBackBox clearfix'} onClick={this.BackBtn.bind(this)}>
                                                    <div className={'DetailsBackIco'}> </div>
                                                    <div className={'DetailsBackWord'}>返回</div>
                                                </div>
                                            </div>
                                            <div className={' scrollBar'} style={{height:window.innerHeight-340 > 360 ? window.innerHeight-340 :360,}}>
                                                {
                                                    this.state.AvSchoolArr.map((item,index) =>
                                                        <div className={'AvSchoolListBox'}
                                                             key={index}
                                                             onClick={() => this.AvSchoolList(item,index)}
                                                        >
                                                            {item.title}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.AvSchoolType === '风采详情'&&
                            <div className={'ContentMainBox clearfix'}>
                                <div className={'DetailsPackageBox'} style={{height:window.innerHeight-191,}}>
                                    <div className={'DetailsPackageTop'}>
                                        <div className={'DetailsBackBox clearfix'} onClick={this.BackButton.bind(this)}>
                                            <div className={'DetailsBackIco'}> </div>
                                            <div className={'DetailsBackWord'}>返回</div>
                                        </div>
                                    </div>
                                    <div className={'TaAndScDetailsMainBox clearfix scrollBarTwo'} style={{height:window.innerHeight-260 > 394 ? window.innerHeight-260 : 394,}}>
                                        <div dangerouslySetInnerHTML = {{__html: this.state.AvSchoolList.content}}/>
                                    </div>
                                </div>
                            </div>
                        }
                        {/*<div className={'PositionBottom'}> </div>*/}
                    </div>
                    <Footer/>
                    {
                        this.state.PopUp === '提示弹窗' &&
                        <PopUp
                            ChildBackClick={this.ChildBackTip.bind(this)}
                            type={'提示'}
                            message={this.state.MessageTip}
                            OutButton={'Yes'}
                        />
                    }
                </div>
        );
    }
}


export default school;
